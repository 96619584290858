import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Step1 from "../assets/images/deposit/step1.svg";
import Step2 from "../assets/images/deposit/step2.svg";
import Step3 from "../assets/images/deposit/step3.svg";
import Step4 from "../assets/images/deposit/step4.svg";
import Step5 from "../assets/images/deposit/step5.svg";
import Step6 from "../assets/images/deposit/step6.svg";
import Step7 from "../assets/images/deposit/step7.svg";
import Step8 from "../assets/images/deposit/step8.svg";
import CloseIcon from "../assets/images/close.svg";
import LeftArrowIcon from "../assets/images/left-arrow.svg";
import RightArrowIcon from "../assets/images/right-arrow.svg";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

export default function TellMeHow({ isOpen, onCancel }: Props) {
  const [currentSlide, setCurrentSlide] = useState(0);

  const nextSlide = () => {
    setCurrentSlide((prev) => (prev + 1) % images.length);
  };

  const prevSlide = () => {
    setCurrentSlide((prev) => (prev - 1 + images.length) % images.length);
  };

  const isPrevDisabled = () => {
    return currentSlide === 0;
  };

  const isNextDisabled = () => {
    return currentSlide === images.length - 1;
  };

  const images = [
    {
      img: Step1,
      title: "Step 1",
      text: "Login into the upland click on the Search icon",
    },
    {
      img: Step2,
      title: "Step 2",
      text: "Enter the dev-shop address closest to you",
    },
    {
      img: Step3,
      title: "Step 3",
      text: "Send your block explorer to the dev-shop property",
    },
    {
      img: Step4,
      title: "Step 4",
      text: "Click on the three dots",
    },
    {
      img: Step5,
      title: "Step 5",
      text: "Open your upland messages",
    },
    {
      img: Step6,
      title: "Step 6",
      text: "Accept the deposit amount",
    },
    {
      img: Step7,
      title: "Step 7",
      text: "Confirm accept request",
    },
    {
      img: Step8,
      title: "Step 8",
      text: "Click on the checkbox & then Click on verify",
    },
  ];
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <div className="fixed inset-0 bg-[#000000DE] transition-opacity" />

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-[#1C1C1C] text-left shadow-xl transition-all w-96 h-auto">
                <div className="flex items-center justify-between py-5 px-4 border-b border-[#979797] text-white uppercase font-ano font-bold text-[22px]">
                  <div></div>
                  <p>{images[currentSlide].title}</p>
                  <img
                    className="cursor-pointer"
                    src={CloseIcon}
                    onClick={onCancel}
                  />
                </div>

                <div className="pt-5 pb-10 px-16">
                  <div className="font-normal text-base text-white mb-10 h-12">
                    {images[currentSlide].text}
                  </div>
                  <div className="flex justify-center items-center">
                    {!isPrevDisabled() && (
                      <img
                        src={LeftArrowIcon}
                        alt="Previous"
                        className="cursor-pointer"
                        onClick={() => prevSlide()}
                      />
                    )}
                    <img
                      src={images[currentSlide].img}
                      alt={`Step ${currentSlide + 1}`}
                      className="mx-5 "
                    />

                    {!isNextDisabled() && (
                      <img
                        src={RightArrowIcon}
                        alt="Next"
                        className="cursor-pointer"
                        onClick={() => nextSlide()}
                      />
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
