import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";
import Header from "../components/Header";
import Footer from "../components/Footer";

import UpxIcon from "../assets/images/upx_icon.svg";
import { MainHeading } from "../common/components/MainHeading";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context";

export function Withdraw() {
  const { user, authToken, setUser, updateAuthToken } = useAuth();
  const navigate = useNavigate();
  const notify = useNotification();
  const [amount, setAmount] = useState("");
  const [showConfirmWithdraw, setShowConfirmWithdraw] = useState(false);
  const [showSuccessWithdraw, setShowSuccessWithdraw] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [uplandTax, setUplandTax] = useState<number>(0);
  const [upxplorerTax, setUpxplorerTax] = useState<number>(0);
  const [finalAmount, setFinalAmount] = useState<number>(0);

  const { sendRequest: continueWithdrawApi, isLoading: isLoadingWithdrawApi } =
    useHttp();

  const { sendRequest: getUserApi, isLoading: isLoadingGetUserApi } = useHttp();

  // Call calculateTaxesAndFinalAmount whenever the amount changes
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && validateAmount(amount)) {
      calculateTaxesAndFinalAmount(parsedAmount);
    }
  }, [amount]);

  // Call calculateTaxes whenever the amount changes
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && validateAmount(amount)) {
      calculateTaxes(parsedAmount);
    }
  }, [amount]);

  const formatNumber = (num: number): string => {
    return num.toFixed(2);
  };
  // const validateAmount = (amount: string) => {
  //   if (amount.trim() === "") {
  //     return false; // Return false if the amount is empty
  //   }
  //   const regex = /^\d+(\.\d{0,2})?$/; // Adjusted to require at least one digit before the decimal
  //   return regex.test(amount);
  // };

  const validateAmount = (amountValue: any) => {
    const stringValue = String(amountValue).trim(); // Convert to string and trim
    if (stringValue === "") {
      return false; // Return false if the amount is empty
    }
    const regex = /^\d+(\.\d{0,2})?$/;
    return regex.test(stringValue);
  };

  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    // const maxBalance = user?.walletUpxBalance
    //   ? parseFloat(user.walletUpxBalance)
    //   : 0;
    // const numericValue = parseFloat(value);
    setAmount(value); // This should always be a string
    setAmountError(""); // Clear any previous error messages
    // if (value === "" || /^\d*\.?\d*$/.test(value)) {
    //   if (!isNaN(numericValue) && numericValue > maxBalance) {
    //     setAmountError("Insufficient funds");
    //   } else {
    //     setAmount(value); // This should always be a string
    //     setAmountError(""); // Clear any previous error messages
    //   }
    // }
  };

  const handleMaxClick = () => {
    const maxBalanceString = user?.walletUpxBalance ?? "0";
    setAmount(maxBalanceString); // Ensure this is always a string
    setAmountError(""); // Reset any existing error
  };

  // Function to safely parse environment variables
  const safeParseFloat = (
    value: string | undefined,
    defaultValue: number
  ): number => {
    if (value === undefined) {
      return defaultValue;
    }
    const parsed = parseFloat(value);
    return isNaN(parsed) ? defaultValue : parsed; // Fallback to default if parsed value is NaN
  };

  // Function to calculate taxes
  const calculateTaxes = (amount: number) => {
    const uplandTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPLAND_TAX,
      0
    );
    const upxplorerTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPXLORER_TAX,
      0
    );

    const calculatedUplandTax = (uplandTaxPercentage / 100) * amount;
    const calculatedUpxplorerTax = (upxplorerTaxPercentage / 100) * amount;

    setUplandTax(calculatedUplandTax);
    setUpxplorerTax(calculatedUpxplorerTax);
  };

  const calculateTaxesAndFinalAmount = (amount: number) => {
    const uplandTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPLAND_TAX,
      0
    );
    const upxplorerTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPXLORER_TAX,
      0
    );

    const calculatedUplandTax = (uplandTaxPercentage / 100) * amount;
    const calculatedUpxplorerTax = (upxplorerTaxPercentage / 100) * amount;

    setUplandTax(calculatedUplandTax);
    setUpxplorerTax(calculatedUpxplorerTax);

    const totalTax = calculatedUplandTax + calculatedUpxplorerTax;
    const calculatedFinalAmount = amount - totalTax;

    // Round the final amount to remove unnecessary decimal zeros
    setFinalAmount(Math.round(calculatedFinalAmount * 100) / 100);
  };

  const continueWithdrawHandler = () => {
    if (!validateAmount(amount)) {
      setAmountError("Invalid Amount");
      return;
    }

    if (Number(amount) < 1000) {
      setAmountError("Amount must be greater than 1000");
      return;
    }

    const maxBalance = user?.walletUpxBalance
      ? parseFloat(user.walletUpxBalance)
      : 0;
    if (parseFloat(amount) > maxBalance) {
      setAmountError("Insufficient funds");
      return;
    }

    if (parseFloat(amount) <= 0) {
      setAmountError("Invalid amount");
      return;
    }

    setAmountError("");

    setShowConfirmWithdraw(true);
  };

  const confirmWithdrawHandler = () => {
    if (!validateAmount(amount)) {
      setAmountError("Invalid amount");
      return;
    }

    continueWithdrawApi(
      {
        url: "api/withdraw",
        method: "POST",
        payload: JSON.stringify({
          amount: amount,
          email: user?.email_address,
          type: "withdraw"
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");

          getUserApi(
            {
              url: "api/user",
              method: "GET",
              payload: {},
              headers: {
                "Content-Type": "application/json",
                Authorization: authToken || ""
              }
            },
            (data: any) => {
              if (data?.error) {
                notify(data?.error, "error");
                console.log("Message", data?.error);
                localStorage.removeItem("authToken");
                updateAuthToken("");
                setUser(null);
                navigate("/home");
                return;
              }
              setUser(data);
            }
          );

          setShowConfirmWithdraw(false);
          setShowSuccessWithdraw(false);

          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
          console.log("Message", data?.message);
          // setContainerId(data?.containerId);
        }
        setShowConfirmWithdraw(false);
        setShowSuccessWithdraw(true);
      }
    );
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <Header />
        <div className="px-5 flex-1">
          <div className="flex flex-col items-center justify-center h-full">
            <MainHeading title="Withdraw UPX" />
            {!showConfirmWithdraw && !showSuccessWithdraw ? (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  Enter the amount
                </p>
                <div className="lg:w-auto w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2 mb-1">
                      <p className="font-ano font-normal text-lg text-white">
                        UPXPLORER UPX
                      </p>

                      <img src={UpxIcon} className="" />
                    </div>
                    {amountError && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {amountError}
                      </div>
                    )}
                  </div>
                  <div className="relative">
                    <input
                      type="text"
                      className="bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 lg:w-[510px] w-full focus:border-[#3E7EFF] font-ano text-white placeholder:text-[#676767] font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                      placeholder="1000 UPX"
                      value={amount}
                      maxLength={10}
                      onChange={handleAmountChange}
                    />
                    <p
                      onClick={handleMaxClick}
                      className="absolute cursor-pointer lg:top-2.5 top-1.5 right-5 font-ano text-[#F9F41F] font-bold lg:text-2xl text-lg"
                    >
                      MAX
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between space-x-5">
                  <div
                    onClick={continueWithdrawHandler}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Continue
                  </div>
                </div>
              </div>
            ) : null}

            {showConfirmWithdraw && !showSuccessWithdraw ? (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  Confirm Withdraw
                </p>
                <div className="w-full px-12">
                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-normal text-white text-2xl">
                      Amount Requested
                    </p>
                    <p className="font-ano font-normal text-white text-2xl">
                      {amount} Upx
                    </p>
                  </div>

                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-normal text-white text-2xl">
                      UPLAND TAX
                    </p>
                    <p className="font-ano font-normal text-white text-2xl">
                      -{formatNumber(uplandTax)} UPX
                    </p>
                  </div>

                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-normal text-white text-2xl">
                      UPXPLORER TAX
                    </p>
                    <p className="font-ano font-normal text-white text-2xl">
                      -{formatNumber(upxplorerTax)} UPX
                    </p>
                  </div>

                  <div className="bg-[#676767] h-px my-6"></div>

                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-bold text-white text-2xl">
                      FINAL AMOUNT
                    </p>
                    <p className="font-ano font-normal text-[#F9F41F] text-2xl">
                      {formatNumber(finalAmount)} UPX
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between space-x-5">
                  <div
                    onClick={() => setShowConfirmWithdraw(false)}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#676767] border-[#676767] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Back
                  </div>

                  <div
                    onClick={confirmWithdrawHandler}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Continue
                  </div>
                </div>
              </div>
            ) : null}

            {showSuccessWithdraw && !showConfirmWithdraw && (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  THANK YOU FOR VERIFICATION
                </p>
                <p className="font-ano font-medium text-3xl text-[#1EA067] text-center mb-6">
                  Withdraw Successful
                </p>

                <div
                  onClick={() => navigate("/profile")}
                  className="flex items-center justify-between space-x-5"
                >
                  <div className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none">
                    View profile
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
