import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import { parseISO, differenceInCalendarDays } from "date-fns";
import { useNavigate } from "react-router-dom";
import ProgressBar from "../common/components/ProgressBar";

interface Contracts {
  property_type: string;
  spark_contract_id: number;
  max_stacked_spark: number;
  email_address: string;
  property_id: number;
  current_stacked_spark: number;
  total_upx_pool: string;
  build_id: number;
  status: string;
  upx_hr: string;
  remaining_upx_pool: string;
  created_at: string;
  city: string;
  price: string;
  eos_id: string;
  startTime: string;
  completeTime: string;
  address: string;
  updated_at: string;
}

export function SparkListing() {
  const { authToken, user } = useAuth();
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [contracts, setContracts] = useState<Contracts[]>([]);

  const { sendRequest: getContractsApi, isLoading: isLoadingContractsApi } =
    useHttp();

  useEffect(() => {
    console.log("authToken", authToken);
    if (authToken && user) getContractsHandler();
  }, [authToken, user?.email_address]);

  const getContractsHandler = () => {
    setError("");
    getContractsApi(
      {
        url: "api/contracts",
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address,
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || "",
        },
      },
      (data: any) => {
        if (data?.error) {
          setError(data.error);
          return;
        }

        setContracts(data.contracts || []);
      }
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // This will re-render your component every minute, updating the remaining time
      setContracts([...contracts]);
    }, 60000); // Update every minute

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [contracts]);

  const calculateRemainingTime = (completeTime: string) => {
    if(!completeTime) return;
    const completeDate = parseISO(completeTime);
    const currentDate = new Date();

    let difference = completeDate.getTime() - currentDate.getTime();

    if (difference < 0) {
      return "Completed";
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    difference -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(difference / (1000 * 60));

    return `${days}d ${hours}h ${minutes}m`;
  };

  const increaseUpxAmountHandler = (contract_id: number) => {
    if (contract_id) {
      navigate(`/contracts/${contract_id}`);
    }
  };

  return (
    <>
      <div className="h-full flex flex-col">
        <Header />
        <div className="container mx-auto flex-1">
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col mt-10 px-5">
              <div className="flex items-center justify-between mb-2">
                <div className="flex items-center space-x-5">
                  <p className="uppercase font-ano text-white font-bold text-4xl">
                    Search Spark Listing
                  </p>
                </div>
                <div>
                  <div>
                    <select className="px-4 py-2 uppercase rounded-full bg-[#1C1C1C] border border-white text-white">
                      <option>Sort by</option>
                      <option>Sort by</option>
                      <option>Sort by</option>
                      <option>Sort by</option>
                    </select>
                  </div>
                </div>
              </div>

              <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6 mb-10">
                <div className="flow-root">
                  <div className="overflow-x-auto">
                    <div className="inline-block min-w-full py-2">
                      <table className="min-w-full divide-y divide-[#2C2C2C]">
                        <thead>
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 text-center font-ano font-bold text-xl text-white"
                            >
                              Address
                            </th>
                            <th
                              scope="col"
                              className="text-center font-ano font-bold text-xl text-white"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="text-center font-ano font-bold text-xl text-white"
                            >
                              Owner
                            </th>
                            <th
                              scope="col"
                              className="text-center font-ano font-bold text-xl text-white"
                            >
                              UPX/hr
                            </th>

                            <th
                              scope="col"
                              className="text-center font-ano font-bold text-xl text-white"
                            >
                              Remaining UPX Pool
                            </th>
                            <th
                              scope="col"
                              className="text-center font-ano font-bold text-xl text-white"
                            >
                              Spark Staked
                            </th>

                            <th
                              scope="col"
                              className="text-center font-ano font-bold text-xl text-white"
                            >
                              Progress
                            </th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-[#2C2C2C]">
                          {isLoadingContractsApi ? (
                            <tr>
                              <td
                                colSpan={7}
                                className="py-10 font-ano text-white text-lg text-center font-bold"
                              >
                                Loading contracts...
                              </td>
                            </tr>
                          ) : error ? (
                            <tr>
                              <td
                                colSpan={7}
                                className="py-10 font-ano text-white text-lg text-center font-bold"
                              >
                                Error: {error}
                              </td>
                            </tr>
                          ) : contracts.length > 0 ? (
                            contracts.map((contract) => {
                              const remainingDays = calculateRemainingTime(
                                contract.completeTime
                              );

                              return (
                                <tr key={contract.spark_contract_id} className="cursor-pointer" onClick={() => navigate(`/spark-calculate/${contract?.spark_contract_id}`)}>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    {contract.address} {contract.city}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                    {contract.property_type}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                    {contract.eos_id}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                    {contract.upx_hr}{" "}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    0/0 {" "}
                                    <span className="font-bold text-[F4C952]">
                                      UPX
                                    </span>
                                  </td>

                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    {contract.current_stacked_spark}/
                                    {contract.max_stacked_spark}{" "}
                                  </td>

                                  <td className="py-4 font-ano font-normal text-base text-center text-white">
                                    {remainingDays}
                                    <ProgressBar
                                      startedAt={contract?.startTime}
                                      finishedAt={contract?.completeTime}
                                    />
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={7}
                                className="py-10 font-ano text-white text-lg text-center font-bold"
                              >
                                No contracts found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
          <Footer />
      </div>
    </>
  );
}
