import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp, useNotification } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import BackIcon from "../assets/images/back.svg";
import BlueRedirectIcon from "../assets/images/redirect_blue.svg";

import {
  parseISO,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  differenceInCalendarDays
} from "date-fns";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import ProgressBar from "../common/components/ProgressBar";
import AgreeCreateListingModal from "../components/AgreeCreateListingModal";
import SparkProgressBar from "../common/components/SparkProgressBar";
import NavigationSelect from "../common/components/NavigationSelect";

interface PropertyDetail {
  property_id: number;
  email_address: string;
  current_status: string;
  property_type: string;
  address: string;
  city: string;
  price: string;
  eos_id: string;
  buildId: number;
  status: string;
  remaining_spark_hours: number;
  required_spark_hours: number;
  remaining_upx_pool?: number;
  start_time: string | undefined;
  complete_time: string | undefined;
  max_stacked_spark: number;
  current_stacked_spark: number;
  nft_id: number;
  startTime: string;
  upland_username: string;
  completeTime: string;
  calculatedTime: string;
  stakeHistory: StakeHistory[];
}

interface StakeHistory {
  build_id: string;
  eos_id: string;
  spark_amount: string;
  spark_staked_id: number;
  email_address: string;
  start_time: string;
  type: string;
  total_reward: string;
  upland_username: string;
}

export function PropertyById() {
  const { authToken, user } = useAuth();
  const notify = useNotification();
  const location = useLocation();

  const [error, setError] = useState("");
  const [userUpxBalance, setUserUpxBalance] = useState("");
  const [showAgreeCreateListing, setShowAgreeCreateListing] = useState(false);
  const [property, setPropertyById] = useState<PropertyDetail | null>(null);
  const [propertyStakeHistory, setPropertyStakeHistory] = useState<
    StakeHistory[] | null
  >(null);
  const { nftId } = useParams<string>();
  const [depositAmount, setDepositAmount] = useState(0);

  const navigate = useNavigate();

  const [upxHr, setUpxHr] = useState("");
  const [totalUpxPool, setTotalUpxPool] = useState("");
  const [errorForUpxHr, setErrorForUpxHr] = useState("");
  const [errorCreateListing, setErrorCreateListing] = useState("");

  const {
    sendRequest: getPropertyDetailApi,
    isLoading: isLoadingPropertyDetailApi
  } = useHttp();

  const {
    sendRequest: fetchUserUpxBalanceApi,
    isLoading: isLoadingFetchUserUpxBalanceApi
  } = useHttp();

  const {
    sendRequest: createListingApi,
    isLoading: isLoadingCreateListingApi
  } = useHttp();

  useEffect(() => {
    if (authToken && user && nftId) {
      fetchPropertyDetailByNftId(nftId);
      // fetchPropertyStakeHistory(nftId);
      fetchUserUpxBalance();
    }
  }, [authToken, user?.email_address, nftId]);

  useEffect(() => {
    // Assuming userUpxBalance and totalUpxPool are strings, convert them to numbers.
    const balance = Number(user?.walletUpxBalance);
    const pool = Number(totalUpxPool);

    // Calculate the deposit amount
    const calculatedDepositAmount = balance - pool;

    // Update the deposit amount state
    setDepositAmount(calculatedDepositAmount);
  }, [user?.walletUpxBalance, totalUpxPool]);

  const fetchPropertyDetailByNftId = (_nftId: string) => {
    setError("");

    getPropertyDetailApi(
      {
        url: `api/property/${_nftId}`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }

        setPropertyById(data.property || []); // Set the transactions or an empty array if undefined
      }
    );
  };

  const fetchPropertyStakeHistory = (propertyId: string) => {
    setError("");

    getPropertyDetailApi(
      {
        url: `api/property-stake-history/${propertyId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }

        setPropertyStakeHistory(data.history || []); // Set the transactions or an empty array if undefined
      }
    );
  };

  const fetchUserUpxBalance = () => {
    fetchUserUpxBalanceApi(
      {
        url: `api/balance`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }

        setUserUpxBalance(data?.results?.upx_balance);
      }
    );
  };

  const handleUpxHrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorForUpxHr("");
    setErrorCreateListing("");
    const newUpxHrValue = event.target.value;

    // Check for an empty string and reset relevant states
    if (newUpxHrValue === "") {
      setUpxHr("");
      setTotalUpxPool("");
      setError("");
      return;
    }

    const newUpxHr = Number(newUpxHrValue);
    if (isNaN(newUpxHr) || newUpxHr < 1 || newUpxHr > 100) {
      // Set error but do not update UPX/hr value
      // setError("Please enter a valid UPX/hr value between 1 and 100.");
      return;
    }

    setError("");
    setUpxHr(newUpxHrValue);
    const calculatedUpxPool =
      (property?.remaining_spark_hours ?? 0) * Number(newUpxHr);

    console.log("Number(calculatedUpxPool)", Number(calculatedUpxPool));
    console.log(
      "Number(property?.remaining_spark_hours) * 100",
      Number(property?.remaining_spark_hours) * 100
    );
    console.log(
      "Number(calculatedUpxPool) > Number(property?.remaining_spark_hours) * 100",
      Number(calculatedUpxPool) > Number(property?.remaining_spark_hours) * 100
    );
    if (
      Number(calculatedUpxPool) >
      Number(property?.remaining_spark_hours) * 100
    ) {
      setTotalUpxPool(calculatedUpxPool.toString());
      return "";
    }

    setTotalUpxPool(calculatedUpxPool.toString());
  };

  // Handle change in Total UPX Pool input field
  const handleUpxPoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorForUpxHr("");
    setErrorCreateListing("");

    const newTotalUpxPool = parseFloat(event.target.value) || "";

    if (
      Number(newTotalUpxPool) >
      Number(property?.remaining_spark_hours) * 100
    ) {
      setTotalUpxPool(
        (Number(property?.remaining_spark_hours) * 100).toString()
      );
      return "";
    }

    setTotalUpxPool(newTotalUpxPool.toString());

    if (!newTotalUpxPool || isNaN(newTotalUpxPool)) {
      setUpxHr("");
      setError("Please enter a valid Total UPX Pool value.");
      return;
    }

    const newUpxHr = newTotalUpxPool / (property?.remaining_spark_hours || 1);
    if (Number(newUpxHr) < 1) {
      setErrorForUpxHr("Upx/hr must be greater than 1");
    }
    setUpxHr(newUpxHr.toString());
  };

  const createListingHandler = () => {
    setShowAgreeCreateListing(false);
    if (!upxHr || isNaN(Number(upxHr))) {
      setError("Please enter a valid UPX/hr value.");
      return;
    }

    console.log("property: ", property);
    console.log("email: ", user?.email_address);
    console.log("propertyId: ", property?.property_id);
    console.log("totalUpxPool: ", totalUpxPool);
    console.log("buildId: ", property?.buildId);
    console.log("status: ", "In Progress");
    console.log("upxHr: ", upxHr);
    console.log("remainingUpxAmount: ", totalUpxPool);

    setErrorCreateListing(""); // Reset previous errors
    createListingApi(
      {
        url: "api/create-listing",
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address,
          propertyId: property?.property_id,
          totalUpxPool: totalUpxPool,
          buildId: nftId,
          status: "In Progress",
          upxHr: upxHr,
          remainingUpxAmount: totalUpxPool
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          // handle error scenario
          console.log("data.error", data.error);
          setErrorCreateListing(data.error);
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
          navigate("/dashboard");
        }
      }
    );
  };

  const calculateRemainingTime = (
    startTime: string | undefined,
    completeTime: string | undefined
  ): string => {
    if (!startTime || !completeTime) {
      return "N/A"; // Return 'N/A' or any appropriate placeholder when dates are null
    }

    const startDate = parseISO(startTime);
    const completeDate = parseISO(completeTime);
    const currentDate = new Date();

    if (currentDate > completeDate) {
      return "Time expired"; // Return this or a similar message if the current date is past the complete date
    }

    const days = differenceInDays(completeDate, currentDate);
    const hours = differenceInHours(completeDate, currentDate) % 24;
    const seconds = differenceInSeconds(completeDate, currentDate) % 60;

    return `${days} day${days !== 1 ? "s" : ""} ${hours} hour${
      hours !== 1 ? "s" : ""
    } ${seconds} second${seconds !== 1 ? "s" : ""}`;
  };

  function formatNumberToTwoDecimals(value: any, place = 2) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    // Format number to fixed decimal places
    let formattedValue = Number(value).toFixed(place);
    // Remove unnecessary trailing zeros and decimal point if not needed
    return formattedValue.replace(/(\.\d*?[1-9])0+$|\.0*$/, "$1");
  }

  const isPathActive = (path: string) => {
    return location.pathname === path;
  };

  function formatTime(hoursTotal: number) {
    // Constants for time calculations
    const HOURS_PER_DAY = 24;
    const MINUTES_PER_HOUR = 60;

    // Calculate days, hours, and minutes
    const days = Math.floor(hoursTotal / HOURS_PER_DAY);
    const remainingHoursTotal = hoursTotal % HOURS_PER_DAY;
    const remainingHours = Math.floor(remainingHoursTotal);
    const remainingMinutes = Math.floor(
      (remainingHoursTotal - remainingHours) * MINUTES_PER_HOUR
    );

    // Create a formatted string
    let formattedTime = "";
    if (days > 0) formattedTime += `${days}d `;
    if (remainingHours > 0) formattedTime += `${remainingHours}hr `;
    if (remainingMinutes > 0) formattedTime += `${remainingMinutes}min`;

    return formattedTime.trim();
  }

  const formatDateWithTime = (dateString: string | null): string => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <AgreeCreateListingModal
        isOpen={showAgreeCreateListing}
        onAction={createListingHandler}
        onCancel={() => setShowAgreeCreateListing(false)}
      />
      <div className="h-full flex flex-col">
        <Header />
        <div className="flex flex-col lg:items-center justify-center space-y-3 border-b border-[#676767] mt-6 pb-6 lg:px-0 px-6">
          <div className="hidden lg:grid grid-cols-3 gap-6">
            <p
              onClick={() => navigate("/home")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/home")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              Spark Market
            </p>
            <p
              onClick={() => navigate("/dashboard")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/dashboard") ||
                isPathActive(`/active-builds/${nftId}`)
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              SPARK DASHBOARD
            </p>

            <p
              onClick={() => navigate("/upx-dashboard")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/upx-dashboard")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              UPX DASHBOARD
            </p>
          </div>
          <NavigationSelect />
        </div>
        <div className="lg:px-20 px-5 flex-1 my-10">
          <div className="flex flex-col space-y-8">
            <div className="">
              <div className="grid lg:grid-cols-6 grid-cols-1 lg:gap-10">
                <div className="lg:col-span-2 col-span-4">
                  <div className="flex items-center mb-5 space-x-3">
                    <img
                      src={BackIcon}
                      className="w-8 cursor-pointer"
                      onClick={() => navigate(-1)}
                    />
                    <p className="uppercase font-ano font-bold text-2xl text-white">
                      PROPERTY DETAILS
                    </p>
                  </div>
                  <div className="flex flex-col bg-[#1C1C1C] rounded-2xl px-6 py-4">
                    <div>
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Address
                      </p>

                      <div className="flex items-start justify-between space-x-10 mt-3">
                        <p className="font-ano text-lg text-white font-bold">
                          {property?.address} {property?.city}
                        </p>
                        <p
                          className={`${
                            property?.status === "processing"
                              ? "bg-[#3E7EFF] text-white"
                              : property?.status === "completed"
                              ? "bg-[#1EA067] text-white"
                              : "bg-[#F9F41F] text-black"
                          }  capitalize font-ano font-bold text-xs p-1 w-28 text-center`}
                        >
                          {property?.status}
                        </p>
                      </div>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Type
                      </p>

                      <p className="font-ano capitalize text-xl text-white font-normal">
                        {property?.property_type
                          ? property?.property_type
                          : "---"}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        NftID
                      </p>

                      <p className="font-ano capitalize text-xl text-white font-normal">
                        {property?.nft_id ? property?.nft_id : "---"}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Staked Spark
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {formatNumberToTwoDecimals(property?.current_stacked_spark)}/
                        {property?.max_stacked_spark}{" "}
                        <span className="text-[#4F99F0]">SPARK</span>
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Remaining UPX Pool
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {property?.remaining_upx_pool
                          ? formatNumberToTwoDecimals(
                              property?.remaining_upx_pool
                            )
                          : "--- "}
                        {property?.remaining_upx_pool && (
                          <span className="font-bold text-[#F9F41F]">UPX</span>
                        )}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Remaining Spark Hours
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {property?.remaining_spark_hours
                          ? formatNumberToTwoDecimals(
                              property?.remaining_spark_hours
                            )
                          : "--- "}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        UPX/hr
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {upxHr ? formatNumberToTwoDecimals(upxHr, 4) : ""}
                        {upxHr ? (
                          <span className="font-bold text-[#F9F41F]"> UPX</span>
                        ) : (
                          "---"
                        )}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Owner
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {property?.upland_username}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Build Progress
                      </p>

                      <div className="mt-2">
                        <SparkProgressBar
                          remainingSparkHours={Number(property?.remaining_spark_hours)}
                          requiredSparkHours={Number(property?.required_spark_hours)}
                        />
                      </div>
                      <p className="font-ano text-sm text-[#999999] float-right mt-4 font-bold">
                        Remaining Time :{" "}
                        {/* {calculateRemainingTime(
                          property?.startTime,
                          property?.completeTime
                        )} */}
                        {property?.calculatedTime}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-4 flex flex-col space-y-4 lg:mt-0 mt-10">
                  <div>
                    <p className="uppercase font-ano font-bold text-2xl text-white mb-5">
                      CREATE LISTING
                    </p>

                    <div className="rounded-2xl grid lg:grid-cols-2 grid-cols-1">
                      <div className="bg-[#1C1C1C] rounded-tl-2xl lg:rounded-tr-none rounded-tr-2xl lg:rounded-bl-2xl">
                        <div className="px-4 py-6 flex flex-col">
                          <div className="flex flex-col">
                            <div className="lg:w-auto w-full px-2 pt-4">
                              <div className="flex items-center justify-between">
                                <p className="font-ano font-normal text-base mb-1 text-white">
                                  UPX/hr
                                </p>
                                <div className="font-ano text-base font-normal text-[#999999]">
                                  Current Balance:{" "}
                                  {new Intl.NumberFormat().format(
                                    Number(userUpxBalance) || 0
                                  )}
                                </div>
                              </div>
                              <input
                                type="text"
                                className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                placeholder=""
                                value={upxHr}
                                onChange={handleUpxHrChange}
                              />
                            </div>

                            <div className="lg:w-auto w-full px-2 pt-4">
                              <p className="font-ano font-normal text-base mb-1 text-white">
                                UPX Pool
                              </p>
                              <input
                                type="text"
                                className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                placeholder=""
                                value={totalUpxPool}
                                onChange={handleUpxPoolChange}
                              />
                            </div>

                            {/* <div className="flex items-baseline space-x-2 px-2 pt-4">
                              <input
                                type="checkbox"
                                name="verify"
                                checked={isCheckboxChecked}
                                onChange={handleCheckboxChange}
                              />
                              <p className="font-ano font-normal text-lg text-white">
                                Lorem Ipsum is simply dummy text of the printing
                                and typesetting industry.
                              </p>
                            </div> */}
                            <div className="flex items-center justify-between mt-5">
                              <div
                                onClick={() =>
                                  upxHr !== "" &&
                                  errorForUpxHr === "" &&
                                  setShowAgreeCreateListing(true)
                                }
                                className={`w-40 border-2 rounded-[50px] font-ano font-bold text-base text-center py-1 bg-[#1C1C1C] uppercase outline-none ${
                                  upxHr !== "" && errorForUpxHr === ""
                                    ? "hover:bg-[#3E7EFF] border-white text-white cursor-pointer"
                                    : "border-[#272727] text-[#272727] cursor-not-allowed"
                                }`}
                              >
                                {!isLoadingCreateListingApi && (
                                  <>CREATE LISTING</>
                                )}
                                {isLoadingCreateListingApi && (
                                  <ThreeDots
                                    height={20}
                                    width={20}
                                    color="#ffffff"
                                    wrapperStyle={{}}
                                    wrapperClass="justify-center py-1"
                                    visible={true}
                                    ariaLabel="oval-loading"
                                  />
                                )}
                              </div>
                              {errorCreateListing && (
                                <p className="font-ano text-sm font-bold uppercase text-[#FF6F6F]">
                                  {errorCreateListing}
                                </p>
                              )}
                              {errorForUpxHr && (
                                <p className="font-ano text-sm font-bold uppercase text-[#FF6F6F]">
                                  {errorForUpxHr}
                                </p>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-[#272727] lg:rounded-tr-2xl rounded-tr-none h-full lg:rounded-bl-none rounded-bl-2xl rounded-br-2xl">
                        <div className="flex flex-col h-full px-8 py-3">
                          <p className="text-[#999999] font-ano font-normal text-center text-lg mb-2">
                            ESTIMATED TIME
                          </p>
                          <div className="flex items-center justify-between space-x-5">
                            <p className="font-ano font-bold text-lg text-white">
                              Remaining spark hours
                            </p>
                            <p className="font-ano font-semibold text-xl text-[#F9F41F]">
                              {formatNumberToTwoDecimals(
                                property?.remaining_spark_hours
                              )}{" "}
                              hrs
                            </p>
                          </div>
                          <div className="flex items-center justify-between space-x-4 my-4">
                            <p className="font-ano font-bold text-lg text-white">
                              With current stacked spark
                            </p>
                            <p className="font-ano font-semibold text-xl text-white text-right">
                              <span>
                                {formatTime(
                                  Number(property?.remaining_spark_hours) /
                                    Number(property?.current_stacked_spark)
                                )}
                              </span>{" "}
                              <span className="text-[#F9F41F]"></span>
                            </p>
                          </div>
                          <div className="flex items-center justify-between space-x-5">
                            <p className="font-ano font-bold text-lg text-white">
                              With max stacked spark
                            </p>
                            <p className="font-ano font-semibold text-xl text-white">
                              {formatTime(
                                Number(property?.remaining_spark_hours) /
                                  Number(property?.max_stacked_spark)
                              )}
                            </p>
                          </div>

                          <div className="border-b border-[#676767] my-4"></div>

                          <div className="flex items-center justify-between space-x-5">
                            <p className="font-ano font-bold text-lg text-white">
                              Fee
                            </p>
                            <p className="font-ano font-semibold text-xl text-white">
                              0
                            </p>
                          </div>

                          {depositAmount < 0 && (
                            <div className="flex items-center justify-between space-x-7 mt-10">
                              <div className="bg-[#1C1C1C] rounded-lg px-3 py-1 flex items-center space-x-3 text-white">
                                <p className="font-ano font-bold text-base">
                                  Deposit Amount
                                </p>
                                <p className="font-ano font-bold text-base">
                                  {formatNumberToTwoDecimals(
                                    Math.abs(depositAmount)
                                  )}{" "}
                                  <span className="text-[#F9F41F]">UPX</span>
                                </p>
                              </div>
                              <div>
                                <p
                                  onClick={() => navigate("/deposit")}
                                  className="flex font-ano text-[#4F99F0] text-base cursor-pointer font-normal"
                                >
                                  <span>Deposit now</span>
                                  <img
                                    src={BlueRedirectIcon}
                                    className="ml-2"
                                  />
                                </p>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center mb-2">
                      <div className="flex items-center space-x-5">
                        <p className="uppercase font-ano font-bold text-2xl text-white">
                          STAKE HISTORY
                        </p>

                        {/* <div className="flex items-center space-x-3">
                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            SPARK LOGS
                          </p>

                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            COMPLETED CONTRACTS
                          </p>
                        </div> */}
                      </div>
                    </div>

                    <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6 mb-10">
                      <div className="flow-root">
                        <div className="overflow-x-auto">
                          <div className="inline-block min-w-full py-2">
                            <table className="min-w-full divide-y divide-[#2C2C2C]">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 text-center font-ano font-bold text-xl text-white"
                                  >
                                    Username
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Staked Spark
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Time
                                  </th>
                                  {/* <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Unstaked time
                                  </th> */}

                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Type
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-[#2C2C2C]">
                                {property?.stakeHistory &&
                                property?.stakeHistory.length > 0 ? (
                                  property?.stakeHistory.map((item) => (
                                    <tr key={item?.spark_staked_id}>
                                      <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                        {item?.upland_username}
                                      </td>
                                      <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                        {item.spark_amount}
                                      </td>
                                      <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                        {item?.start_time
                                          ? formatDateWithTime(item.start_time)
                                          : "N/A"}
                                      </td>
                                      <td className="py-4 capitalize font-ano font-normal text-xl text-center text-white">
                                        {item.type}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={5}
                                      className="py-4 font-ano font-normal text-xl text-center text-white"
                                    >
                                      No Stake History Available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
