import { MagnifyingGlassIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ThreeDots } from "react-loader-spinner";

interface Users {
  userEmail: string;
  email_address: string | null;
  otp: string;
  full_name: string | null;
  wax_address: string | null;
  eth_address: string | null;
  discord_id: string | null;
  twitter_account: string | null;
  auth_token: string;
  type: string | null;
  created_at: string | null;
  updated_at: string | null;
  upland_user_id: string | null;
  upland_auth_token: string | null;
  upland_eos_id: string | null;
  upland_username: string | null;
  code: string | null;
  avatar_url: string | null;
  networth: number | null;
  upland_level: number | null;
  upx_balance: number;
}

export default function AdminChangeFee() {
  const navigate = useNavigate();
  const { authToken, user } = useAuth();
  const notify = useNotification();

  const [fees, setFees] = useState({});
  const [selectedTier, setSelectedTier] = useState("");

  const [withdrawFee, setWithdrawFee] = useState("");
  const [transferFee, setTransferFee] = useState("");
  const [listingFee, setListingFee] = useState("");
  const [sharesTransferFee, setSharesTransferFee] = useState("");

  const { sendRequest: getTaxApi, isLoading: isLoadingTaxApi } = useHttp();

  const { sendRequest: saveFeeApi, isLoading: isLoadingSaveFee } =
    useHttp();

  const getTaxHandler = () => {
    getTaxApi(
      {
        url: `api/fee`,
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        console.log("data", data);

        setFees(data.fee || null);
      }
    );
  };

  useEffect(() => {
    getTaxHandler();
  }, []);

  useEffect(() => {
    if (selectedTier && fees[selectedTier]) {
      const feeTypes = {
        withdraw: "withdrawal",
        transfer: "transfer",
        listing: "listing",
        sharesTransfer: "sharetrading" // Assuming "Shares Transfers" is "sharetrading"
      };

      const findFeePercentage = (type) => {
        const fee = fees[selectedTier].find((fee) => fee.type === type);
        // Explicitly check for undefined to allow 0 to be set
        return fee !== undefined ? fee.percentage : "";
      };

      setWithdrawFee(findFeePercentage(feeTypes.withdraw));
      setTransferFee(findFeePercentage(feeTypes.transfer));
      setListingFee(findFeePercentage(feeTypes.listing));
      setSharesTransferFee(findFeePercentage(feeTypes.sharesTransfer));
    }
  }, [selectedTier, fees]);

  const updateFees = async () => {
    if (!selectedTier) {
      notify("Please select a user tier to update fees for.", "error");
      return;
    }
  
    if (isLoadingSaveFee) return;
  
    const updatedFees = {
      tier: selectedTier,
      fees: [
        { type: "withdrawal", percentage: parseFloat(withdrawFee) || 0 },
        { type: "transfer", percentage: parseFloat(transferFee) || 0 },
        { type: "listing", percentage: parseFloat(listingFee) || 0 },
        { type: "sharetrading", percentage: parseFloat(sharesTransferFee) || 0 },
      ],
    };
  
    try {
      await saveFeeApi(
        {
          url: `api/fee/update`,
          method: "POST",
          payload: JSON.stringify(updatedFees),
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken || ""
          },
        },
        (data: any) => {
          if (data?.error) {
            notify(data.error, "error");
            return;
          }
          notify("Fees updated successfully.", "success");
          // Optionally, refresh the fees data from the backend
          getTaxHandler();
        }
      );
    } catch (error) {
      console.error("Error updating fees:", error);
      notify("Failed to update fees. Please try again.", "error");
    }
  };

  const handleWithdrawFeeChange = (e) => {
    setWithdrawFee(e.target.value);
  };
  
  const handleTransferFeeChange = (e) => {
    setTransferFee(e.target.value);
  };
  
  const handleListingFeeChange = (e) => {
    setListingFee(e.target.value);
  };
  
  const handleSharesTransferFeeChange = (e) => {
    setSharesTransferFee(e.target.value);
  };
  
  return (
    <div className="bg-white w-full px-14 pt-10">
      <div className="flex items-center space-x-3">
        <svg
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="5" fill="#1C1C1C" />
          <path
            d="M19.2002 22.4004L12.8002 16.0004L19.2002 9.60039"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>

        <p className="font-ano font-bold text-3xl text-black">Change Fee</p>
      </div>

      {/* <div className="relative rounded-md mt-5">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          className="rounded-full w- border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
          placeholder="Search"
        />
      </div> */}
      <div className="w-full mt-10">
        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="userType"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              User Type
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <select
                onChange={(e) => setSelectedTier(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              >
                <option value="">Select User Tier</option>
                {Object.keys(fees).map((tier) => (
                  <option key={tier} value={tier}>
                    {tier}
                  </option>
                ))}
              </select>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="withdraw"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Withdraw
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="withdraw"
                value={withdrawFee}
                onChange={handleWithdrawFeeChange}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
              
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="transfer"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Transfer
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="transfer"
                value={transferFee}
                onChange={handleTransferFeeChange}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="listingFee"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Listing Fee
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="transfer"
                value={listingFee}
                onChange={handleListingFeeChange}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="shareTransfers"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Shares Transfers
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="transfer"
                value={sharesTransferFee}
                onChange={handleSharesTransferFeeChange}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
              />
            </div>
          </div>

          <div className="w-full"></div>
        </div>

        <div className="mt-10">
          <button
            onClick={updateFees}
            type="button"
            className="lg:w-[150px] w-full cursor-pointer border-2 rounded-[50px] bg-[#2D6BE3] border-[#2D6BE3] font-ano font-bold lg:text-lg text-sm text-center py-1 uppercase text-white outline-none"
          >
            {!isLoadingSaveFee && <>Save</>}
            {isLoadingSaveFee && (
              <ThreeDots
                height={20}
                width={20}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass="justify-center py-1"
                visible={true}
                ariaLabel="oval-loading"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
