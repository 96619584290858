import React from 'react';
import Sidebar from '../components/Sidebar';
import { Outlet } from 'react-router-dom';

const MainLayout = () => (
  <>
    <Sidebar />
    <Outlet />
  </>
);

export default MainLayout;
