import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import { parseISO, differenceInCalendarDays } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import ProgressBar from "../common/components/ProgressBar";
import SparkProgressBar from "../common/components/SparkProgressBar";
import BackIcon from "../assets/images/back.svg";

interface Property {
  property_id: number;
  email_address: string;
  current_status: string;
  property_type: string;
  address: string;
  city: string;
  price: string;
  nft_id: string;
  buildId: number;
  build_status: string;
  max_stacked_spark: number;
  current_stacked_spark: number;
  remaining_spark_hours: number;
  update_time: string;
  startTime: string;
  completeTime: string;
}

interface ActiveSparkContractResponse {
  property_type: string;
  spark_contract_id: number;
  max_stacked_spark: number;
  email_address: string;
  property_id: number;
  current_stacked_spark: number;
  total_upx_pool: string;
  build_id: number;
  status: string;
  upx_hr: string;
  remaining_upx_pool: string;
  required_spark_hours: number;
  remaining_spark_hours: number;
  created_at: string;
  start_time: string;
  complete_time: string;
  address: string;
  updated_at: string;
  startTime: string;
  completeTime: string;
}

export function StakedContracts() {
  const { authToken, user } = useAuth();
  const navigate = useNavigate();

  const location = useLocation();

  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [activeSparkContracts, setActiveSparkContracts] = useState<
    ActiveSparkContractResponse[]
  >([]);

  const {
    sendRequest: getActiveSparkContractApi,
    isLoading: isLoadingActiveSparkContractApi
  } = useHttp();

  useEffect(() => {
    if (authToken && user) {
      getActiveSparkContractHandler();
    }
  }, [authToken, user?.email_address]); // Make sure the dependency array is empty to avoid repeated calls

  const getActiveSparkContractHandler = () => {
    getActiveSparkContractApi(
      {
        url: "api/upx-dashboard-contracts",
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }
        setActiveSparkContracts(data.contracts || []);
      }
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // This will re-render your component every minute, updating the remaining time
      setActiveSparkContracts([...activeSparkContracts]);
    }, 60000); // Update every minute

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [activeSparkContracts]);

  const calculateRemainingTime = (completeTime: string) => {
    if (!completeTime) return;
    const completeDate = parseISO(completeTime);
    const currentDate = new Date();

    let difference = completeDate.getTime() - currentDate.getTime();

    if (difference < 0) {
      return "Completed";
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    difference -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(difference / (1000 * 60));

    return `${days}d ${hours}h ${minutes}m`;
  };

  const isPathActive = (path: string) => {
    return location.pathname === path;
  };

  function formatNumberToTwoDecimals(value: any) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    return Number(value).toFixed(2);
  }

  return (
    <>
      <div className="h-full relative flex flex-col">
        <Header />
        <div className="flex flex-col items-center justify-center space-y-3 border-b border-[#676767] mt-6">
          {/* <p className="uppercase font-ano text-white font-bold lg:text-3xl text-2xl">
            Spark Market
          </p> */}
          <div className="flex items-center space-x-5 ">
            <div className="grid grid-cols-3 gap-6 mb-5">
              <p
                onClick={() => navigate("/home")}
                className={`text-center bg-[#1C1C1C] ${
                  isPathActive("/home")
                    ? "bg-[#3E7EFF]"
                    : "hover:bg-[#3E7EFF]"
                } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
              >
                Spark Market
              </p>
              <p
                onClick={() => navigate("/dashboard")}
                className={`text-center bg-[#1C1C1C] ${
                  isPathActive("/dashboard")
                    ? "bg-[#3E7EFF]"
                    : "hover:bg-[#3E7EFF]"
                } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
              >
                SPARK DASHBOARD
              </p>

              <p
                onClick={() => navigate("/upx-dashboard")}
                className={`text-center bg-[#1C1C1C] ${
                  isPathActive("/upx-dashboard") ||
                  isPathActive("/upx-dashboard/staked-contracts")
                    ? "bg-[#3E7EFF]"
                    : "hover:bg-[#3E7EFF]"
                } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
              >
                UPX DASHBOARD
              </p>
            </div>
          </div>
        </div>
        <div className="my-10 flex-1 px-20">
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col mt-10">
              
              <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-1">
                <div className="flex items-center justify-between border-b border-[#676767] pb-4">
                <img
                src={BackIcon}
                className="w-8 cursor-pointer mb-2"
                onClick={() => navigate(-1)}
              />
                  {/* <div></div> */}
                  <p className="uppercase font-ano text-white font-bold lg:text-2xl text-xl">
                    Staked Contracts
                  </p>
                  <div className=""></div>
                </div>

                <div className="flow-root">
                  <div className="overflow-x-auto">
                    <div className="inline-block min-w-full py-2">
                      <table className="min-w-full divide-y divide-[#2C2C2C]">
                        <thead>
                          <tr>
                          <th
                              scope="col"
                              className="py-3.5 text-center font-ano font-semibold text-sm text-white"
                            >
                              ID
                            </th>
                            <th
                              scope="col"
                              className="py-3.5 text-center font-ano font-semibold text-sm text-white"
                            >
                              Address
                            </th>
                            <th
                              scope="col"
                              className="text-center font-ano font-semibold text-sm text-white"
                            >
                              Type
                            </th>
                            <th
                              scope="col"
                              className="text-center font-ano font-semibold text-sm text-white"
                            >
                              Spark Staked
                            </th>
                            <th
                              scope="col"
                              className="text-center font-ano font-semibold text-sm text-white"
                            >
                              UPX/hr
                            </th>

                            <th
                              scope="col"
                              className="text-center font-ano font-semibold text-sm text-white"
                            >
                              Pool
                            </th>

                            <th
                              scope="col"
                              className="text-center font-ano font-semibold text-sm text-white"
                            >
                              Progress
                            </th>

                            <th
                              scope="col"
                              className="text-center font-ano font-semibold text-sm text-white"
                            >
                              Remaining Time
                            </th>
                            <th
                              scope="col"
                              className="text-center font-ano font-semibold text-sm text-white"
                            ></th>
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-[#2C2C2C]">
                          {isLoading ? (
                            <tr>
                              <td
                                colSpan={6}
                                className="py-10 font-ano text-white text-lg text-center font-bold"
                              >
                                Loading contracts...
                              </td>
                            </tr>
                          ) : error ? (
                            <tr>
                              <td
                                colSpan={6}
                                className="py-10 font-ano text-white text-lg text-center font-bold"
                              >
                                Error: {error}
                              </td>
                            </tr>
                          ) : activeSparkContracts.length > 0 ? (
                            activeSparkContracts.map((contract) => {
                              const remainingDays = calculateRemainingTime(
                                contract.completeTime
                              );
                              return (
                                <tr key={contract.address}>
                                  <td className="py-4 font-ano font-normal text-sm text-center text-white">
                                    {contract.spark_contract_id}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-sm text-center text-white">
                                    {contract.address}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-sm text-center text-white">
                                    {contract.property_type}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-sm text-center text-white">
                                    {contract.current_stacked_spark}/
                                    {contract.max_stacked_spark}{" "}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-sm text-center text-white">
                                    {contract.upx_hr}{" "}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-sm text-center text-white">
                                    {formatNumberToTwoDecimals(
                                      contract.remaining_upx_pool
                                    )}{" "}
                                  </td>

                                  <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                    {formatNumberToTwoDecimals(
                                      contract?.required_spark_hours -
                                        contract?.remaining_spark_hours
                                    )}
                                    /{contract?.required_spark_hours}
                                    <SparkProgressBar
                                      remainingSparkHours={
                                        contract?.remaining_spark_hours
                                      }
                                      requiredSparkHours={
                                        contract?.required_spark_hours
                                      }
                                    />
                                  </td>

                                  <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                    {remainingDays}
                                  </td>

                                  <td className="py-4 px-4">
                                    <div className="flex items-center space-x-2">
                                      <svg
                                        onClick={() =>
                                          navigate(
                                            `/contracts/${contract?.spark_contract_id}`
                                          )
                                        }
                                        className="cursor-pointer inline-block stroke-white hover:stroke-[#F9F41F]"
                                        width="18"
                                        height="20"
                                        viewBox="0 0 18 20"
                                        fill="none"
                                        xmlns="http://www.w3.org/2000/svg"
                                      >
                                        <path
                                          d="M6.5 5H11.5M6.5 9H11.5M6.5 13H11.5M15 19V4.2C15 3.0799 15 2.51984 14.782 2.09202C14.5903 1.71569 14.2843 1.40973 13.908 1.21799C13.4802 1 12.9201 1 11.8 1H6.2C5.0799 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M17 19H1"
                                          strokeWidth="1.5"
                                          strokeLinecap="round"
                                          strokeLinejoin="round"
                                        />
                                      </svg>
                                      <a
                                        href={`https://play.upland.me/?prop_id=${contract?.property_id}`}
                                        target="_blank"
                                        onClick={(e) => {
                                          e.stopPropagation();
                                        }}
                                      >
                                        <svg
                                          className="cursor-pointer inline-block stroke-white hover:stroke-[#3E7EFF]"
                                          width={15}
                                          height={15}
                                          viewBox="0 0 15 15"
                                          fill="none"
                                          xmlns="http://www.w3.org/2000/svg"
                                        >
                                          <path
                                            d="M14 5.33333L14 1M14 1H9.66667M14 1L8.22222 6.77778M6.05556 2.44444H4.46667C3.25322 2.44444 2.6465 2.44444 2.18302 2.6806C1.77534 2.88832 1.44388 3.21978 1.23615 3.62747C1 4.09094 1 4.69766 1 5.91111V10.5333C1 11.7468 1 12.3535 1.23615 12.817C1.44388 13.2247 1.77534 13.5561 2.18302 13.7638C2.6465 14 3.25322 14 4.46667 14H9.08889C10.3023 14 10.9091 14 11.3725 13.7638C11.7802 13.5561 12.1117 13.2247 12.3194 12.817C12.5556 12.3535 12.5556 11.7468 12.5556 10.5333V8.94444"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                          />
                                        </svg>
                                      </a>
                                    </div>
                                  </td>
                                </tr>
                              );
                            })
                          ) : (
                            <tr>
                              <td
                                colSpan={7}
                                className=" pt-5 font-ano text-white text-lg text-center font-bold"
                              >
                                No spark contract found.
                              </td>
                            </tr>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
