import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp, useNotification } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import { parseISO, differenceInCalendarDays } from "date-fns";
import { useLocation, useNavigate } from "react-router-dom";
import BackIcon from "../assets/images/back.svg";

import CopyIcon from "../assets/images/copy.svg";
import RedirectIcon from "../assets/images/redirect.svg";
import SparkProgressBar from "../common/components/SparkProgressBar";

interface Contracts {
  property_type: string;
  spark_contract_id: number;
  max_stacked_spark: number;
  email_address: string;
  property_id: number;
  current_stacked_spark: number;
  total_upx_pool: string;
  build_id: number;
  contract_status: string;
  upx_hr: string;
  remaining_upx_pool: string;
  required_spark_hours: number;
  remaining_spark_hours: number;
  created_at: string;
  city: string;
  price: string;
  eos_id: string;
  startTime: string;
  completeTime: string;
  address: string;
  updated_at: string;
}

export function Contracts() {
  const { authToken, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotification();

  const [activeTab, setActiveTab] = useState("active");
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [contracts, setContracts] = useState<Contracts[]>([]);

  const { sendRequest: getContractsApi, isLoading: isLoadingContractsApi } =
    useHttp();

  useEffect(() => {
    if (authToken && user) getContractsHandler();
  }, [authToken, user?.email_address]);

  const getContractsHandler = () => {
    setError("");
    getContractsApi(
      {
        url: "api/contracts",
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          setError(data.error);
          return;
        }

        setContracts(data.contracts || []);
      }
    );
  };

  useEffect(() => {
    const interval = setInterval(() => {
      // This will re-render your component every minute, updating the remaining time
      setContracts([...contracts]);
    }, 60000); // Update every minute

    return () => clearInterval(interval); // Clear interval on component unmount
  }, [contracts]);

  const calculateRemainingTime = (completeTime: string) => {
    if (!completeTime) return;
    const completeDate = parseISO(completeTime);
    const currentDate = new Date();

    let difference = completeDate.getTime() - currentDate.getTime();

    if (difference < 0) {
      return "Completed";
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24));
    difference -= days * (1000 * 60 * 60 * 24);

    const hours = Math.floor(difference / (1000 * 60 * 60));
    difference -= hours * (1000 * 60 * 60);

    const minutes = Math.floor(difference / (1000 * 60));

    return `${days}d ${hours}h ${minutes}m`;
  };

  const increaseUpxAmountHandler = (contract_id: number) => {
    if (contract_id) {
      navigate(`/contracts/${contract_id}`);
    }
  };

  const isPathActive = (path: string) => {
    return location.pathname === path;
  };

  const filterContractsByStatus = (status: string) => {
    return contracts.filter((contract) => contract.contract_status === status);
  };

  function formatNumberToTwoDecimals(value: any, place = 2) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    // Format number to fixed decimal places
    let formattedValue = Number(value).toFixed(place);
    // Remove unnecessary trailing zeros and decimal point if not needed
    return formattedValue.replace(/(\.\d*?[1-9])0+$|\.0*$/, "$1");
  }

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Handle successful copy
        notify("Copied", "success");
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        // Handle error case
        console.error("Error in copying text: ", err);
      });
  };

  return (
    <>
      <div className="h-full flex flex-col">
        <Header />
        <div className="flex flex-col items-center justify-center space-y-3 border-b border-[#676767] mt-6">
          <div className="grid grid-cols-3 gap-6 pb-5">
            <p
              onClick={() => navigate("/home")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/home")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              Spark Market
            </p>
            <p
              onClick={() => navigate("/dashboard")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/dashboard/active-contracts")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              SPARK DASHBOARD
            </p>

            <p
              onClick={() => navigate("/upx-dashboard")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/upx-dashboard")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              UPX DASHBOARD
            </p>
          </div>
        </div>

        <div className="px-20 flex-1">
          <div className="flex flex-col space-y-8">
            <div className="flex flex-col mt-10">
              <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6 mb-10">
                <div className="grid grid-cols-3 border-b border-[#676767] pb-4">
                  {/* <div></div> */}
                  <img
                src={BackIcon}
                className="w-8 cursor-pointer"
                onClick={() => navigate(-1)}
              />
                  <p className="uppercase font-ano text-white font-bold text-center lg:text-2xl text-xl mt-1">
                    {activeTab === "active" && <span>Active Contracts</span>}

                    {activeTab === "completed" && (
                      <span>Completed Contracts</span>
                    )}
                  </p>
                  <div className="flex items-center justify-end space-x-3 pb-5">
                    <p
                      onClick={() => setActiveTab("active")}
                      className={`text-center bg-[#1C1C1C] ${
                        activeTab === "active"
                          ? "bg-[#3E7EFF]"
                          : "hover:bg-[#3E7EFF]"
                      } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-xs w-40 px-2 py-2`}
                    >
                      Active Contracts
                    </p>

                    {/* <p
                      onClick={() => setActiveTab("completed")}
                      className={`text-center bg-[#1C1C1C] ${
                        activeTab === "completed"
                          ? "bg-[#3E7EFF]"
                          : "hover:bg-[#3E7EFF]"
                      } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-xs w-40 px-2 py-2`}
                    >
                      Completed CONTRACTS
                    </p> */}
                  </div>
                </div>
                {activeTab === "active" && (
                  <div className="flow-root">
                    <div className="overflow-x-auto">
                      <div className="inline-block min-w-full py-2">
                        <table className="min-w-full divide-y divide-[#676767]">
                          <thead>
                            <tr>
                            <th
                                scope="col"
                                className="py-3.5 px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                ID
                              </th>
                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Address
                              </th>
                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Type
                              </th>
                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Spark Staked
                              </th>
                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                UPX/hr
                              </th>

                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Remaining UPX Pool
                              </th>

                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Progress
                              </th>

                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Remaining Time
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-[#2C2C2C]">
                            {isLoadingContractsApi ? (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="py-10 font-ano text-white text-lg text-center font-bold"
                                >
                                  Loading contracts...
                                </td>
                              </tr>
                            ) : error ? (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="py-10 font-ano text-white text-lg text-center font-bold"
                                >
                                  Error: {error}
                                </td>
                              </tr>
                            ) : contracts.length > 0 ? (
                              filterContractsByStatus("in progress").length >
                              0 ? (
                                filterContractsByStatus("in progress").map(
                                  (contract) => {
                                    const remainingDays =
                                      calculateRemainingTime(
                                        contract.completeTime
                                      );

                                    return (
                                      <tr key={contract.spark_contract_id}>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white capitalize">
                                          {contract.spark_contract_id}
                                        </td>
                                        <td
                                          onClick={() =>
                                            copyToClipboard(contract.address)
                                          }
                                          className="py-4 px-4 font-ano font-normal text-sm text-center text-white hover:text-[#F9F41F] cursor-pointer"
                                        >
                                          <span>
                                            {contract.address} {contract.city}
                                          </span>
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white capitalize">
                                          {contract.property_type}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          {contract.current_stacked_spark}/
                                          {contract.max_stacked_spark}{" "}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          {formatNumberToTwoDecimals(
                                            contract.upx_hr
                                          )}{" "}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          {formatNumberToTwoDecimals(
                                            contract.remaining_upx_pool
                                          )}
                                          /
                                          {formatNumberToTwoDecimals(
                                            contract.total_upx_pool
                                          )}{" "}
                                        </td>

                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                        {formatNumberToTwoDecimals(contract?.required_spark_hours - contract?.remaining_spark_hours)}/{contract?.required_spark_hours}

                                          <SparkProgressBar
                                            remainingSparkHours={
                                              contract?.remaining_spark_hours
                                            }
                                            requiredSparkHours={
                                              contract?.required_spark_hours
                                            }
                                          />
                                        </td>

                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          {remainingDays}
                                        </td>

                                        {/* <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          <div
                                            onClick={() =>
                                              increaseUpxAmountHandler(
                                                contract?.spark_contract_id
                                              )
                                            }
                                            className="cursor-pointer bg-[#1C1C1C] rounded-[50px] py-1 w-40 font-ano font-bold text-base text-center text-white border border-[#3E7EFF] uppercase"
                                          >
                                            Increase UPX
                                          </div>
                                        </td> */}

                                        <td className="py-4 px-4">
                                          <div className="flex items-center space-x-2">
                                            <svg
                                              onClick={() =>
                                                navigate(
                                                  `/contracts/${contract?.spark_contract_id}`
                                                )
                                              }
                                              className="cursor-pointer inline-block stroke-white hover:stroke-[#F9F41F]"
                                              width="18"
                                              height="20"
                                              viewBox="0 0 18 20"
                                              fill="none"
                                              xmlns="http://www.w3.org/2000/svg"
                                            >
                                              <path
                                                d="M6.5 5H11.5M6.5 9H11.5M6.5 13H11.5M15 19V4.2C15 3.0799 15 2.51984 14.782 2.09202C14.5903 1.71569 14.2843 1.40973 13.908 1.21799C13.4802 1 12.9201 1 11.8 1H6.2C5.0799 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M17 19H1"
                                                strokeWidth="1.5"
                                                strokeLinecap="round"
                                                strokeLinejoin="round"
                                              />
                                            </svg>
                                            <a
                                              href={`https://play.upland.me/?prop_id=${contract?.property_id}`}
                                              target="_blank"
                                              onClick={(e) => {
                                                e.stopPropagation();
                                              }}
                                            >
                                              <svg
                                                className="cursor-pointer inline-block stroke-white hover:stroke-[#3E7EFF]"
                                                width={15}
                                                height={15}
                                                viewBox="0 0 15 15"
                                                fill="none"
                                                xmlns="http://www.w3.org/2000/svg"
                                              >
                                                <path
                                                  d="M14 5.33333L14 1M14 1H9.66667M14 1L8.22222 6.77778M6.05556 2.44444H4.46667C3.25322 2.44444 2.6465 2.44444 2.18302 2.6806C1.77534 2.88832 1.44388 3.21978 1.23615 3.62747C1 4.09094 1 4.69766 1 5.91111V10.5333C1 11.7468 1 12.3535 1.23615 12.817C1.44388 13.2247 1.77534 13.5561 2.18302 13.7638C2.6465 14 3.25322 14 4.46667 14H9.08889C10.3023 14 10.9091 14 11.3725 13.7638C11.7802 13.5561 12.1117 13.2247 12.3194 12.817C12.5556 12.3535 12.5556 11.7468 12.5556 10.5333V8.94444"
                                                  stroke-linecap="round"
                                                  stroke-linejoin="round"
                                                />
                                              </svg>
                                            </a>
                                          </div>
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td
                                    colSpan={8}
                                    className="py-10 font-ano text-white text-lg text-center font-bold"
                                  >
                                    No contracts found.
                                  </td>
                                </tr>
                              )
                            ) : (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="py-10 font-ano text-white text-lg text-center font-bold"
                                >
                                  No contracts found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
                {activeTab === "completed" && (
                  <div className="flow-root">
                    <div className="overflow-x-auto">
                      <div className="inline-block min-w-full py-2">
                        <table className="min-w-full divide-y divide-[#676767]">
                          <thead>
                            <tr>
                            <th
                                scope="col"
                                className="py-3.5 px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                ID
                              </th>

                              <th
                                scope="col"
                                className="py-3.5 px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Address
                              </th>
                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Type
                              </th>
                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Spark Staked
                              </th>
                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                UPX/hr
                              </th>

                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Remaining UPX Pool
                              </th>

                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Remaining Time
                              </th>

                              <th
                                scope="col"
                                className="px-2 text-center font-ano font-semibold text-sm text-white"
                              >
                                Completed Time
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-[#2C2C2C]">
                            {isLoadingContractsApi ? (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="py-10 font-ano text-white text-lg text-center font-bold"
                                >
                                  Loading contracts...
                                </td>
                              </tr>
                            ) : error ? (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="py-10 font-ano text-white text-lg text-center font-bold"
                                >
                                  Error: {error}
                                </td>
                              </tr>
                            ) : contracts.length > 0 ? (
                              filterContractsByStatus("completed").length >
                              0 ? (
                                filterContractsByStatus("completed").map(
                                  (contract) => {
                                    const remainingDays =
                                      calculateRemainingTime(
                                        contract.completeTime
                                      );

                                    return (
                                      <tr key={contract.spark_contract_id}>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white capitalize">
                                          {contract.spark_contract_id}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          {contract.address} {contract.city}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white capitalize">
                                          {contract.property_type}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          {contract.current_stacked_spark}/
                                          {contract.max_stacked_spark}{" "}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          {contract.upx_hr}{" "}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          {formatNumberToTwoDecimals(
                                            contract.remaining_upx_pool
                                          )}
                                          /{contract.total_upx_pool}{" "}
                                        </td>
                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-[#1EA067]">
                                          {contract.contract_status}
                                        </td>

                                        <td className="py-4 px-4 font-ano font-normal text-sm text-center text-white">
                                          12/23/2023 07:12
                                        </td>
                                      </tr>
                                    );
                                  }
                                )
                              ) : (
                                <tr>
                                  <td
                                    colSpan={8}
                                    className="py-10 font-ano text-white text-lg text-center font-bold"
                                  >
                                    No contracts found.
                                  </td>
                                </tr>
                              )
                            ) : (
                              <tr>
                                <td
                                  colSpan={8}
                                  className="py-10 font-ano text-white text-lg text-center font-bold"
                                >
                                  No contracts found.
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
