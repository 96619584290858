import { MagnifyingGlassIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context";
import _ from "lodash";
import SearchDropdown from "../../common/components/SearchDropdown";

interface Shareholders {
  created_on: string;
  email_address: string;
  shares: number;
}
export interface DropdownItem {
  id: string;
  label: string;
  value: string;
  href?: string;
  onClick?: () => void;
}

export default function AdminShareholders() {
  const [selectedItem, setSelectedItem] = useState<DropdownItem | null>({
    id: "1",
    label: "Email Address",
    value: "email_address",
    href: "#"
  });

  const menuItems: DropdownItem[] = [
    { id: "1", label: "Email Address", value: "email_address", href: "#" }
  ];
  const handleItemSelect = (item: DropdownItem) => {
    setSelectedItem(item);
    setSortField(item.value);
    console.log("Selected item:", item.value);
  };

  const { authToken, user } = useAuth();
  const [shareholders, setShareholders] = useState<Shareholders[]>([]);
  const navigate = useNavigate();

  const [sortField, setSortField] = useState("email_address");
  const [sortDirection, setSortDirection] = useState("asc");
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const {
    sendRequest: getShareholdersApi,
    isLoading: isLoadingShareholdersApi
  } = useHttp();

  const getShareholders = () => {
    const queryParams = `page=${currentPage}&pageSize=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`;

    getShareholdersApi(
      {
        url: `api/shareholders?${queryParams}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        console.log("data", data);

        setShareholders(data.shareholders || []);
        setTotalPages(data?.pagination?.totalPages);
      }
    );
  };

  useEffect(() => {
    const debouncedGetShareholders = _.debounce(() => {
      getShareholders();
    }, 500);

    debouncedGetShareholders();

    return () => {
      debouncedGetShareholders.cancel();
    };
  }, [sortField, sortDirection, currentPage, searchQuery]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };
  const renderPagination = () => {
    if (totalPages <= 1) {
      return null;
    }

    let pages: JSX.Element[] = [];
    let startPage: number, endPage: number;

    const paginationCutOff = 5;

    if (totalPages <= paginationCutOff) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = paginationCutOff;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - (paginationCutOff - 1);
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // First page
    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          className="text-black"
          onClick={() => setCurrentPage(1)}
        >
          [1]
        </button>
      );
      pages.push(
        <span key="start-ellipsis" className="text-black">
          ...
        </span>
      );
    }

    // Middle pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`px-1 ${
            i === currentPage ? "text-[#4F99F0]" : "text-black"
          }`}
          onClick={() => setCurrentPage(i)}
        >
          [{i}]
        </button>
      );
    }

    // Last page
    if (endPage < totalPages) {
      pages.push(
        <span key="end-ellipsis" className="text-black">
          ...
        </span>
      );
      pages.push(
        <button
          key={totalPages}
          className="text-black"
          onClick={() => setCurrentPage(totalPages)}
        >
          [{totalPages}]
        </button>
      );
    }

    return <div className="flex space-x-2">{pages}</div>;
  };

  function formatNumberToTwoDecimals(value: any, place = 2) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    // Format number to fixed decimal places
    let formattedValue = Number(value).toFixed(place);
    // Remove unnecessary trailing zeros and decimal point if not needed
    return formattedValue.replace(/(\.\d*?[1-9])0+$|\.0*$/, "$1");
  }

  const formatDateWithTime = (dateString: string | null): string => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <div className="bg-white w-full px-14 pt-10">
      <div className="flex items-center justify-between">
        <p className="font-ano font-bold text-3xl text-black">Shareholders</p>
        <button
          onClick={() => navigate(`/admin/add-shareholder`)}
          type="button"
          className="px-3 w-auto cursor-pointer border-2 rounded-[50px] bg-[#2D6BE3] border-[#2D6BE3] font-ano font-bold lg:text-lg text-sm text-center py-1 uppercase text-white outline-none"
        >
          Add Shareholder
        </button>
      </div>

      <div className="flex items-center space-x-4 mt-5">
        <div className="relative rounded-md">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            name="abc"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-full w- border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            placeholder="Search"
          />
        </div>

        <SearchDropdown
          items={menuItems}
          buttonLabel={selectedItem?.label || "Field"}
          onItemSelect={handleItemSelect}
        />
      </div>

      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  onClick={() => handleSort("email_address")}
                  scope="col"
                  className="hidden text-left py-3.5 text-base font-semibold text-[#686868] sm:table-cell hover:text-black cursor-pointer"
                >
                  Email
                </th>
                <th
                  scope="col"
                  className="hidden text-center py-3.5 text-base font-semibold text-[#686868] sm:table-cell hover:text-black cursor-pointer"
                >
                  Shares
                </th>

                <th
                  scope="col"
                  className="text-center py-3.5 text-base font-semibold text-[#686868]"
                >
                  Created on
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {isLoadingShareholdersApi ? (
                <tr>
                  <td
                    colSpan={4}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-black sm:pl-0"
                  >
                    <div className="text-center py-10">Loading...</div>
                  </td>
                </tr>
              ) : shareholders.length > 0 ? (
                shareholders.map((shareholder, key) => (
                  <tr key={key}>
                    <td className="whitespace-nowrap py-4 text-left text-base font-medium text-black sm:pl-0">
                      {shareholder.email_address}
                    </td>
                    <td className="whitespace-nowrap py-4 text-center text-base text-black sm:pl-0">
                      {shareholder.shares}
                    </td>
                    <td className="hidden whitespace-nowrap text-center py-4 text-base text-black sm:table-cell">
                      {formatDateWithTime(shareholder.created_on)}
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={4}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-black sm:pl-0"
                  >
                    <div className="text-center py-10">
                      No shareholder found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {shareholders.length > 0 && (
        <div className="flex justify-center items-center my-4 text-black">
          {renderPagination()}
        </div>
      )}
    </div>
  );
}
