import React, { useState, useEffect } from 'react';
import ReactApexChart from 'react-apexcharts';
import { ApexOptions } from 'apexcharts';

interface DataItem {
  shares_amount: number;
  upx_amount: number;
  created_at: string;
}

interface Props {
  data: DataItem[];
}

const MixedChart: React.FC<Props> = ({ data }) => {
  const [series, setSeries] = useState([
    {
      name: 'Shares',
      type: 'column',
      data: [] as number[],
    },
    {
      name: 'UPX Amount',
      type: 'line',
      data: [] as number[],
      color: '#F9F41F'
    },
  ]);

  const [options, setOptions] = useState<ApexOptions>({
    chart: {
      height: 350,
      type: 'line',
      toolbar: {
        show: false, // Disables the toolbar including burger menu, zoom in, zoom out
      },
      background: '#272727', // Optional: for a transparent background

    },
    dataLabels: {
      enabled: false,
    },
    stroke: {
      width: [0, 2],
    },
    // title: {
    //   text: 'Shares & UPX Amount',
    // },
    xaxis: {
      type: 'datetime',
      categories: [],
      labels: {
        style: {
          colors: 'white',
        },
      },
    },
    yaxis: [
      {
        title: {
          text: 'Shares',
          style: {
            color: 'white',
          },
        },
        labels: {
          style: {
            colors: 'white',
          },
        },
      },
      {
        opposite: true,
        title: {
          text: 'UPX Amount',
          style: {
            color: 'white',
          },
        },
        labels: {
          style: {
            colors: 'white',
          },
        },
      },
    ],
    legend: {
      labels: {
        colors: 'white',
      },
    },
  });

  useEffect(() => {
    setSeries([
      {
        name: 'Shares',
        type: 'column',
        data: data.map(item => item.shares_amount),
      },
      {
        name: 'UPX Amount',
        type: 'line',
        data: data.map(item => item.upx_amount),
      },
    ]);

    setOptions(prevOptions => ({
      ...prevOptions,
      xaxis: {
        ...prevOptions.xaxis,
        categories: data.map(item => item.created_at),
      },
    }));
  }, [data]);

  return (
    <ReactApexChart options={options} series={series} type="line" height={350} />
  );
};

export default MixedChart;
