import { MagnifyingGlassIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ThreeDots } from "react-loader-spinner";

interface Build {
  nft_id: number;
  upland_username: string;
  build_start_time: string | null;
  property_id: number;
  required_spark_hours: string | null;
  status: string | null;
  max_stake: string | null;
}

export default function AdminEditBuilds() {
  const navigate = useNavigate();
  const { authToken, user } = useAuth();
  const { nft_id } = useParams<string>();
  const notify = useNotification();
  const [requiredSparkHours, setRequiredSparkHours] = useState("");
  const [status, setStatus] = useState("");
  const [maxStake, setMaxStake] = useState("");
  const [buildStartTime, setBuildStartTime] = useState<string | "">("");

  const [selectedBuild, setSelectedBuild] = useState<Build | null>(null);
  const { sendRequest: getBuildByIdApi, isLoading: isLoadingGetBuildApi } =
    useHttp();

  const { sendRequest: saveBuildApi, isLoading: isLoadingSaveBuildApi } =
    useHttp();

  const getBuildById = () => {
    getBuildByIdApi(
      {
        url: `api/build/edit/${nft_id}`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        console.log("data", data);

        setSelectedBuild(data.build || null);
      }
    );
  };

  useEffect(() => {
    console.log("nft_id", nft_id);
    if (nft_id) getBuildById();
  }, [nft_id]);

  useEffect(() => {
    if (selectedBuild) {
      setRequiredSparkHours(selectedBuild?.required_spark_hours || "");
      setStatus(selectedBuild?.status || "");
      setMaxStake(selectedBuild?.max_stake || "");
      setBuildStartTime(
        new Date(selectedBuild?.build_start_time || "")
          .toISOString()
          .split("T")[0] || ""
      );
    }
  }, [selectedBuild]);

  const updateUserDetails = async () => {
    if (isLoadingSaveBuildApi) return;
    // API call to update user details
    try {
      saveBuildApi(
        {
          url: `api/build/update/${nft_id}`,
          method: "POST",
          payload: JSON.stringify({
            requiredSparkHours,
            status,
            maxStake
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken || ""
          }
        },
        (data: any) => {
          if (data?.error) {
            notify(data?.error, "error");
            return;
          }
          if (data?.message) {
            notify(data?.message, "success");
          }
          console.log("data", data);

          setSelectedBuild(data.user || null);
        }
      );
    } catch (error) {
      console.error("Update error:", error);
    }
  };

  return (
    <div className="bg-white w-full px-14 pt-10">
      <div className="flex items-center space-x-3">
        <svg
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="5" fill="#1C1C1C" />
          <path
            d="M19.2002 22.4004L12.8002 16.0004L19.2002 9.60039"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>

        <p className="font-ano font-bold text-3xl text-black">
          Edit Build Under Construction
        </p>
      </div>

      {/* <div className="relative rounded-md mt-5">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          className="rounded-full w- border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
          placeholder="Search"
        />
      </div> */}
      <div className="w-full mt-10">
        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="nftId"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              NFT ID
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="nftId"
                id="email"
                value={selectedBuild?.nft_id}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="username"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Username
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="username"
                id="username"
                value={selectedBuild?.upland_username}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="buildStartTime"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Build Start Time
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="buildStartTime"
                id="buildStartTime"
                value={buildStartTime}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="propertyId"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Property ID
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="propertyId"
                id="propertyId"
                value={selectedBuild?.property_id}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="requiredSparkHours"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Required Spark Hours
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="requiredSparkHours"
                id="requiredSparkHours"
                value={requiredSparkHours}
                onChange={(e) => setRequiredSparkHours(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />

            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="status"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Status
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="status"
                id="status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
        
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="maxStake"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Max Stake
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="maxStake"
                id="maxStake"
                value={maxStake}
                onChange={(e) => setMaxStake(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
       
            </div>
          </div>

          <div className="w-full"></div>
        </div>

        <div className="mt-10">
          <button
            onClick={updateUserDetails}
            type="button"
            className="lg:w-[150px] w-full cursor-pointer border-2 rounded-[50px] bg-[#2D6BE3] border-[#2D6BE3] font-ano font-bold lg:text-lg text-sm text-center py-1 uppercase text-white outline-none"
          >
            {!isLoadingSaveBuildApi && <>Save</>}
            {isLoadingSaveBuildApi && (
              <ThreeDots
                height={20}
                width={20}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass="justify-center py-1"
                visible={true}
                ariaLabel="oval-loading"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
