import React, { ChangeEvent, useEffect, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";
import Header from "../components/Header";
import Footer from "../components/Footer";

import UpxIcon from "../assets/images/upx_icon.svg";
import { useNavigate } from "react-router-dom";
import { MainHeading } from "../common/components/MainHeading";
import { useAuth } from "../context";
import DepositModal from "../components/DepositModal";
import { ThreeDots } from "react-loader-spinner";

export function Transfer() {
  const navigate = useNavigate();
  const notify = useNotification();

  const { user, getUser, authToken } = useAuth();
  const [amount, setAmount] = useState("");
  const [username, setUsername] = useState("");
  const [containerId, setContainerId] = useState("");
  const [showCityModal, setShowCityModal] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showConfirmTransfer, setShowConfirmTransfer] = useState(false);
  const [showSuccessTransfer, setShowSuccessTransfer] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [usernameError, setUsernameError] = useState("");
  const [uplandTax, setUplandTax] = useState<number>(0);
  const [upxplorerTax, setUpxplorerTax] = useState<number>(0);
  const [finalAmount, setFinalAmount] = useState<number>(0);

  const { sendRequest: continueTransferApi, isLoading: isLoadingDepositApi } =
    useHttp();

    const { sendRequest: checkTransactionApi, isLoading: isLoadingCheckTransactionApi } =
    useHttp();

  // Call calculateTaxesAndFinalAmount whenever the amount changes
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && validateAmount(amount)) {
      calculateTaxesAndFinalAmount(parsedAmount);
    }
  }, [amount]);

  // Call calculateTaxes whenever the amount changes
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && validateAmount(amount)) {
      calculateTaxes(parsedAmount);
    }
  }, [amount]);

  // Function to format numbers to remove unnecessary decimal zeros
  const formatNumber = (num: number): string => {
    return num.toFixed(2);
  };
  const validateAmount = (amountValue: any) => {
    const stringValue = String(amountValue).trim(); // Convert to string and trim
    if (stringValue === "") {
      return false; // Return false if the amount is empty
    }
    const regex = /^\d+(\.\d{0,2})?$/;
    return regex.test(stringValue);
  };
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const handleUserNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setUsername(value);
  };

  // Function to safely parse environment variables
  const safeParseFloat = (
    value: string | undefined,
    defaultValue: number
  ): number => {
    if (value === undefined) {
      return defaultValue;
    }
    const parsed = parseFloat(value);
    return isNaN(parsed) ? defaultValue : parsed; // Fallback to default if parsed value is NaN
  };

  // Function to calculate taxes
  const calculateTaxes = (amount: number) => {
    const uplandTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPLAND_TAX,
      0
    );
    const upxplorerTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPXLORER_TAX,
      0
    );

    const calculatedUplandTax = (uplandTaxPercentage / 100) * amount;
    const calculatedUpxplorerTax = (upxplorerTaxPercentage / 100) * amount;

    setUplandTax(calculatedUplandTax);
    setUpxplorerTax(calculatedUpxplorerTax);
  };

  const calculateTaxesAndFinalAmount = (amount: number) => {
    const uplandTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPLAND_TAX,
      0
    );
    const upxplorerTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPXLORER_TAX,
      0
    );

    const calculatedUplandTax = (uplandTaxPercentage / 100) * amount;
    const calculatedUpxplorerTax = (upxplorerTaxPercentage / 100) * amount;

    setUplandTax(calculatedUplandTax);
    setUpxplorerTax(calculatedUpxplorerTax);

    // const totalTax = calculatedUplandTax + calculatedUpxplorerTax;
    const totalTax = calculatedUpxplorerTax;
    const calculatedFinalAmount = amount - totalTax;

    // Round the final amount to remove unnecessary decimal zeros
    setFinalAmount(Math.round(calculatedFinalAmount * 100) / 100);
  };

  // Update your continueTransferHandler to handle empty inputs
  const continueTransferHandler = () => {
    if (!validateAmount(amount)) {
      setAmountError("Invalid amount");
      return;
    }
    if (parseFloat(amount) < 1) {
      setAmountError("Invalid amount");
      return;
    }

    checkTransactionApi(
      {
        url: "api/verify-transfer",
        method: "POST",
        payload: JSON.stringify({
          amount: amount,
          username: username,
          email: user?.email_address,
          type: "transfer"
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
        }
        setAmountError(""); // Clear any previous error messages
        setShowConfirmTransfer(true);
      }
    );


  };

  const confirmDepositHandler = () => {
    // setShowDepositModal(false);

    if (!validateAmount(amount)) {
      setAmountError("Invalid amount");
      return;
    }
    continueTransferApi(
      {
        url: "api/transfer",
        method: "POST",
        payload: JSON.stringify({
          amount: amount,
          username: username,
          senderUsername: user?.upland_username,
          email: user?.email_address,
          type: "transfer"
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
        }
        setShowConfirmTransfer(false);
        setShowSuccessTransfer(true)
      }
    );
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <Header />
        <div className="2xl:px-14 xl:px-12 lg:px-10 md:px-0 px-5 h-full">
          <div className="flex flex-col items-center justify-center h-full">
            <MainHeading title="Transfer UPX" />
            {!showConfirmTransfer && !showSuccessTransfer ? (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  Enter the following details
                </p>
                <div className="lg:w-auto w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2 mb-1">
                      <p className="font-ano font-normal text-lg text-white">
                        UPX AMOUNT
                      </p>

                      <img src={UpxIcon} className="" />
                    </div>
                    {amountError && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {amountError}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      className="bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 lg:w-[510px] w-full focus:border-[#3E7EFF] font-ano text-white placeholder:text-[#676767] font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                      placeholder="1000 UPX"
                      value={amount}
                      onChange={handleAmountChange}
                    />
                  </div>
                </div>

                <div className="lg:w-auto w-full mt-2">
                  <div className="flex items-center justify-between">
                    <p className="font-ano font-normal text-lg text-white mb-1">
                      USER NAME
                    </p>

                    {usernameError && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {usernameError}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      className="bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 lg:w-[510px] w-full focus:border-[#3E7EFF] font-ano text-white placeholder:text-[#676767] font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                      placeholder=""
                      value={username}
                      onChange={handleUserNameChange}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-5">
                  <div
                    onClick={continueTransferHandler}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Continue
                  </div>
                </div>
              </div>
            ) : null}

            {showConfirmTransfer && !showSuccessTransfer ? (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  Confirm transfer
                </p>
                <div className="w-full px-12">
                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-normal text-white text-2xl uppercase">
                      Receiver USERNAME
                    </p>
                    <p className="font-ano font-normal text-white text-2xl">
                      {username}
                    </p>
                  </div>

                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-normal text-white text-2xl uppercase">
                      Send Amount
                    </p>
                    <p className="font-ano font-normal text-white text-2xl">
                      {amount} Upx
                    </p>
                  </div>

                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-normal text-white text-2xl uppercase">
                      upxplorer fee
                    </p>
                    <p className="font-ano font-normal text-white text-2xl">
                      -{formatNumber(upxplorerTax)} UPX
                    </p>
                  </div>

                  <div className="bg-[#676767] h-px my-6"></div>

                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-bold text-white text-2xl">
                      TOTAL CREDIT AMOUNT
                    </p>
                    <p className="font-ano font-normal text-[#F9F41F] text-2xl">
                      {formatNumber(finalAmount)} UPX
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between space-x-5">
                  <div
                    onClick={() => setShowConfirmTransfer(false)}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#676767] border-[#676767] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Back
                  </div>

                  <div
                    onClick={confirmDepositHandler}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    {!isLoadingDepositApi && <>Continue</>}
                    {isLoadingDepositApi && (
                      <ThreeDots
                        height={20}
                        width={20}
                        color="#ffffff"
                        wrapperStyle={{}}
                        wrapperClass="justify-center py-1"
                        visible={true}
                        ariaLabel="oval-loading"
                      />
                    )}
                  </div>
                </div>
              </div>
            ) : null}

            {showSuccessTransfer && !showConfirmTransfer && (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  UPX HAS BEEN TRANSFERED
                </p>
                <p className="font-ano font-medium lg:text-4xl text-3xl text-[#1EA067] text-center mb-6">
                  TRANSACTION SUCCESSFUL
                </p>

                <div
                  onClick={() => navigate("/transactions")}
                  className="flex items-center justify-between space-x-5"
                >
                  <div className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none">
                    TRANSACTIONS
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
