import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../context";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ThreeDots } from "react-loader-spinner";

export default function AdminAddShareholder() {
  const navigate = useNavigate();
  const { authToken, user } = useAuth();
  const notify = useNotification();
  const [email, setEmail] = useState("");
  const [shares, setShares] = useState("");

  const {
    sendRequest: addShareholderApi,
    isLoading: isLoadingAddShareholderApi
  } = useHttp();

  const addShareholder = async () => {
    if (isLoadingAddShareholderApi) return;
    try {
      addShareholderApi(
        {
          url: `api/add-shareholder`,
          method: "POST",
          payload: JSON.stringify({
            email,
            shares
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken || ""
          }
        },
        (data: any) => {
          if (data?.error) {
            notify(data?.error, "error");
            return;
          }
          if (data?.message) {
            notify(data?.message, "success");
            navigate("/admin/shareholders");
          }
        }
      );
    } catch (error) {
      console.error("Update error:", error);
    }
  };

  return (
    <div className="bg-white w-full px-14 pt-10">
      <div className="flex items-center space-x-3">
        <svg
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="5" fill="#1C1C1C" />
          <path
            d="M19.2002 22.4004L12.8002 16.0004L19.2002 9.60039"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>

        <p className="font-ano font-bold text-3xl text-black">
          Add Shareholder
        </p>
      </div>

      <div className="w-full mt-10">
        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="email"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Email
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="email"
                id="email"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="shares"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Shares
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="shares"
                id="shares"
                value={shares}
                onChange={(e) => setShares(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div className="mt-10">
          <button
            onClick={addShareholder}
            type="button"
            className="lg:w-[150px] w-full cursor-pointer border-2 rounded-[50px] bg-[#2D6BE3] border-[#2D6BE3] font-ano font-bold lg:text-lg text-sm text-center py-1 uppercase text-white outline-none"
          >
            {!isLoadingAddShareholderApi && <>Add</>}
            {isLoadingAddShareholderApi && (
              <ThreeDots
                height={20}
                width={20}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass="justify-center py-1"
                visible={true}
                ariaLabel="oval-loading"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
