import { MagnifyingGlassIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ThreeDots } from "react-loader-spinner";

interface SparkContract {
  build_id: string;
  contract_status: string;
  created_on: string;
  email_address: string;
  eos_id: string;
  last_rewarded: string;
  property_id: number;
  remaining_upx_pool: string;
  spark_contract_id: number;
  total_upx_pool: string;
  upland_username: string;
  upx_hr: string;
  build_start_time: string;
  current_staked_spark: number;
  expected_complete_time: string;
  fullyStaked: number;
  max_stake: number;
  nft_id: string;
  owner_eos_id: string;
  remaining_spark_hours: number;
  required_spark_hours: number;
  status: string;
  txn_id: string;
  updated_on: string;
  created_at: string;
}

export default function AdminEditSparkContracts() {
  const navigate = useNavigate();
  const { authToken, user } = useAuth();
  const { spark_contract_id } = useParams<string>();
  const notify = useNotification();
  const [totalUpxPool, setTotalUpxPool] = useState("");
  const [remainingUpxPool, setRemainingUpxPool] = useState("");
  const [upxHr, setUpxHr] = useState("");
  const [lastRewarded, setLastRewarded] = useState("");

  const [selectedSparkContract, setSelectedSparkContract] =
    useState<SparkContract | null>(null);
  const {
    sendRequest: getSparkContractByIdApi,
    isLoading: isLoadingSparkContractApi
  } = useHttp();

  const {
    sendRequest: saveSparkContractApi,
    isLoading: isLoadingSaveSparkContractApi
  } = useHttp();

  const getSparkContractById = () => {
    getSparkContractByIdApi(
      {
        url: `api/spark-contract/edit/${spark_contract_id}`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        console.log("data", data);

        setSelectedSparkContract(data.sparkContract || null);
      }
    );
  };

  useEffect(() => {
    console.log("spark_contract_id", spark_contract_id);
    if (spark_contract_id) getSparkContractById();
  }, [spark_contract_id]);

  useEffect(() => {
    if (selectedSparkContract) {
      setTotalUpxPool(selectedSparkContract?.total_upx_pool || "");
      setRemainingUpxPool(selectedSparkContract?.remaining_upx_pool || "");
      setUpxHr(selectedSparkContract?.upx_hr || "");
      setLastRewarded(new Date(selectedSparkContract?.last_rewarded).toISOString().split('T')[0] || "");

    }
  }, [selectedSparkContract]);

  const updateSparkContractDetails = async () => {
    if (isLoadingSaveSparkContractApi) return;
    // API call to update user details
    try {
      saveSparkContractApi(
        {
          url: `api/spark-contract/update/${spark_contract_id}`,
          method: "POST",
          payload: JSON.stringify({
            totalUpxPool,
            remainingUpxPool,
            upxHr,
            lastRewarded
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken || ""
          }
        },
        (data: any) => {
          if (data?.error) {
            notify(data?.error, "error");
            return;
          }
          if (data?.message) {
            notify(data?.message, "success");
          }
          console.log("data", data);

          setSelectedSparkContract(data.user || null);
        }
      );
    } catch (error) {
      console.error("Update error:", error);
    }
  };

  return (
    <div className="bg-white w-full px-14 pt-10">
      <div className="flex items-center space-x-3">
        <svg
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="5" fill="#1C1C1C" />
          <path
            d="M19.2002 22.4004L12.8002 16.0004L19.2002 9.60039"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>

        <p className="font-ano font-bold text-3xl text-black">Edit Spark Contract</p>
      </div>

      {/* <div className="relative rounded-md mt-5">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          className="rounded-full w- border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
          placeholder="Search"
        />
      </div> */}
      <div className="w-full mt-10">
        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="username"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Username
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="username"
                id="username"
                value={selectedSparkContract?.upland_username}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="propertyID"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Property ID
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="propertyID"
                id="propertyID"
                value={selectedSparkContract?.property_id}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="stakedSpark"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Staked Spark
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="stakedSpark"
                id="stakedSpark"
                value={selectedSparkContract?.current_staked_spark}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="remainingSparkHours"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Remaining Spark Hours
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="remainingSparkHours"
                id="remainingSparkHours"
                value={Number(selectedSparkContract?.remaining_spark_hours)}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="totalSparkHours"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Total Spark Hours
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="totalSparkHours"
                id="totalSparkHours"
                value={selectedSparkContract?.required_spark_hours}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="createdAt"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Created Date
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="createdAt"
                id="createdAt"
                value={selectedSparkContract?.created_on}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
                />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="completeTime"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
              >
              Expected Complete Time
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="completeTime"
                id="completeTime"
                disabled
                value={selectedSparkContract?.expected_complete_time}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                  />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="totalUpxPool"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Total Upx Pool
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="totalUpxPool"
                id="totalUpxPool"
                value={totalUpxPool}
                onChange={(e) => setTotalUpxPool(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="remainingUpxPool"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Remaining Upx Pool
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="remainingUpxPool"
                id="remainingUpxPool"
                value={remainingUpxPool}
                onChange={(e) => setRemainingUpxPool(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="upxHr"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Upx/Hr
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="upxHr"
                id="upxHr"
                value={upxHr}
                onChange={(e) => setUpxHr(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="lastRewarded"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Last Rewarded
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="lastRewarded"
                id="lastRewarded"
                value={lastRewarded}
                disabled
                // onChange={(e) => setLastRewarded(e.target.value)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <div className="w-full"></div>
        </div>

        {/* <div className="mt-10">
          <button
            onClick={updateSparkContractDetails}
            type="button"
            className="lg:w-[150px] w-full cursor-pointer border-2 rounded-[50px] bg-[#2D6BE3] border-[#2D6BE3] font-ano font-bold lg:text-lg text-sm text-center py-1 uppercase text-white outline-none"
          >
            {!isLoadingSaveSparkContractApi && <>Save</>}
            {isLoadingSaveSparkContractApi && (
              <ThreeDots
                height={20}
                width={20}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass="justify-center py-1"
                visible={true}
                ariaLabel="oval-loading"
              />
            )}
          </button>
        </div> */}
      </div>
    </div>
  );
}
