import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp, useNotification } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import BackIcon from "../assets/images/back.svg";
import BlueRedirectIcon from "../assets/images/redirect_blue.svg";

import {
  parseISO,
  differenceInDays,
  differenceInCalendarDays,
  differenceInSeconds,
  differenceInHours
} from "date-fns";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import ProgressBar from "../common/components/ProgressBar";
import AgreeCreateListingModal from "../components/AgreeCreateListingModal";
import SparkProgressBar from "../common/components/SparkProgressBar";
import NavigationSelect from "../common/components/NavigationSelect";

interface Contract {
  property_type: string;
  spark_contract_id: number;
  max_stacked_spark: number;
  email_address: string;
  property_id: number;
  current_stacked_spark: number;
  total_upx_pool: string;
  build_id: number;
  contract_status: string;
  upx_hr: string;
  remaining_upx_pool: string;
  created_at: string;
  address: string;
  city: string;
  price: string;
  eos_id: string;
  updated_at: string;
  required_spark_hours: number;
  remaining_spark_hours: number;
  update_time: string;
  startTime: string;
  userStake: boolean;
  userStakeAmount: number;
  completeTime: string;
  upland_username: string;
  nft_id: number;
  stakeHistory: StakeHistory[];
}

interface StakeHistory {
  spark_amount: string;
  spark_end_time: string;
  spark_staked_id: number;
  spark_start_time: string;
  total_reward: string;
  type: string;
}

interface StakeHistory {
  build_id: string;
  email_address: string;
  end_time: string;
  eos_id: string;
  spark_amount: string;
  spark_staked_id: number;
  start_time: string;
  total_reward: string;
  upland_username: string;
}

export function ContractById() {
  const { authToken, user } = useAuth();
  const notify = useNotification();
  const navigate = useNavigate();
  const location = useLocation();
  const [showAgreeCreateListing, setShowAgreeCreateListing] = useState(false);

  const [error, setError] = useState("");
  const [userUpxBalance, setUserUpxBalance] = useState("");
  const [depositAmount, setDepositAmount] = useState(0);
  const [contract, setContractById] = useState<Contract | null>(null);
  const { sparkContractId } = useParams<string>();

  const [daysDifference, setDaysDifference] = useState<number>(0);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);
  const [isContractsLoading, setIsContractsLoading] = useState(false);

  const [contracts, setContracts] = useState<Contract[]>([]);
  const [contractStakeHistory, setContractStakeHistory] = useState<
    StakeHistory[]
  >([]);

  const [upxHr, setUpxHr] = useState("");
  const [sparkAmount, setSparkAmount] = useState("");
  const [sparkHours, setSparkHours] = useState("");
  const [estimatedTotalEarnings, setEstimatedTotalEarnings] = useState(0);

  const [totalUpxPool, setTotalUpxPool] = useState("");
  const [addition_to_pool, setAdditionToPool] = useState("")
  const [errorForUpxHr, setErrorForUpxHr] = useState("");
  const [errorCreateListing, setErrorCreateListing] = useState("");

  const {
    sendRequest: getContractDetailApi,
    isLoading: isLoadingContractDetailApi
  } = useHttp();

  const {
    sendRequest: fetchUserUpxBalanceApi,
    isLoading: isLoadingFetchUserUpxBalanceApi
  } = useHttp();

  const {
    sendRequest: createListingApi,
    isLoading: isLoadingCreateListingApi
  } = useHttp();

  const { sendRequest: getContractsApi, isLoading: isLoadingContractsApi } =
    useHttp();

  useEffect(() => {
    if (authToken && user) getContractsHandler();
  }, [authToken, user?.email_address]);

  useEffect(() => {
    if (authToken && user && sparkContractId) {
      fetchContractDetailById(sparkContractId);
      // fetchContractStakeHistoryById(sparkContractId);
      fetchUserUpxBalance();
    }
  }, [authToken, user?.email_address, sparkContractId]); // Make sure the dependency array is empty to avoid repeated calls

  useEffect(() => {
    // Assuming userUpxBalance and totalUpxPool are strings, convert them to numbers.
    const balance = Number(user?.walletUpxBalance);
    const pool = Number(totalUpxPool);

    // Calculate the deposit amount
    const calculatedDepositAmount =
      balance - (pool - Number(contract?.remaining_upx_pool));

    // Update the deposit amount state
    setDepositAmount(calculatedDepositAmount);
  }, [user?.walletUpxBalance, totalUpxPool]);

  const fetchContractDetailById = (sparkContractId: string) => {
    setError("");

    getContractDetailApi(
      {
        url: `api/contract/${sparkContractId}`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }
        setContractById(data.contract || []);
      }
    );
  };

  const fetchContractStakeHistoryById = (sparkContractId: string) => {
    setError("");

    getContractDetailApi(
      {
        url: `api/stake-history/${sparkContractId}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }
        setContractStakeHistory(data.history || []);
      }
    );
  };

  const fetchUserUpxBalance = () => {
    fetchUserUpxBalanceApi(
      {
        url: `api/balance`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }

        setUserUpxBalance(data?.results?.upx_balance);
      }
    );
  };

  useEffect(() => {
    if (contract && sparkAmount) {
      setEstimatedTotalEarnings(
        Number(contract?.upx_hr) *
          Number(sparkAmount) *
          Number(contract?.remaining_spark_hours)
      );
    }
  }, [contract?.upx_hr, sparkAmount, contract?.remaining_spark_hours]);

  const handleSparkAmountChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSparkAmount(event.target.value);
  };

  const handleSparkHoursChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const sparkHours = event.target.value;

    if(Number(sparkHours) >= Number(contract?.remaining_spark_hours)) {
      setSparkHours(String(contract?.remaining_spark_hours))
    } else {
      setSparkHours(sparkHours);
    }
    setEstimatedTotalEarnings(
      Number(contract?.upx_hr) * Number(sparkAmount) * Number(sparkHours)
    );
  };

  const handleUpxHrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorForUpxHr("");
    setErrorCreateListing("");

    const upxPerHour = event.target.value;

    if (upxPerHour === "") {
      setUpxHr("");
      setTotalUpxPool("");
      setAdditionToPool("");
      setError("");
      return;
    }

    const newUpxHr = Number(upxPerHour);
    if (isNaN(newUpxHr) || newUpxHr < 1 || newUpxHr > 100) {
      return;
    }

    setError("");
    setUpxHr(upxPerHour);

    const hrsDifference =
      (contract?.remaining_spark_hours ?? 0) /
      (contract?.max_stacked_spark ?? 0);
    setDaysDifference(hrsDifference);

    const addition_to_pool =
      ((contract?.remaining_spark_hours ?? 0)) * Number(upxPerHour) -
      Number(contract?.remaining_upx_pool);
    const calculatedUpxPool =
    ((contract?.remaining_spark_hours ?? 0)) * Number(upxPerHour) 

    setTotalUpxPool(calculatedUpxPool.toString());
    setAdditionToPool(addition_to_pool.toString());

  };

  const updateListingHandler = () => {
    setShowAgreeCreateListing(false)
    if (!upxHr || isNaN(Number(upxHr))) {
      setError("Please enter a valid UPX/hr value.");
      return;
    }

    // // Check if property times are defined
    // if (!contract?.complete_time || !contract?.start_time) {
    //   console.error("Start time or end time is missing");
    //   return;
    // }

    console.log("contract: ", contract);
    console.log("email: ", user?.email_address);
    console.log("sparkContractId: ", contract?.property_id);
    console.log("totalUpxPool: ", totalUpxPool);
    console.log("buildId: ", contract?.build_id);
    console.log("status: ", "in progress");
    console.log("upxHr: ", upxHr);
    console.log("remainingUpxAmount: ", totalUpxPool);

    setError(""); // Reset previous errors
    createListingApi(
      {
        url: "api/update-listing",
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address,
          sparkContractId: contract?.spark_contract_id,
          totalUpxPool: Number(totalUpxPool) + Number(contract?.remaining_upx_pool),
          buildId: contract?.build_id,
          status: "in progress",
          upxHr: Number(upxHr),
          remainingUpxAmount: Number(totalUpxPool)
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          notify(data.error, "error");

          setError(data.error);
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
          navigate("/dashboard");
        }

        setUpxHr("");
        setIsCheckboxChecked(false);
      }
    );
  };

  const getContractsHandler = () => {
    setError("");
    setIsContractsLoading(true);
    getContractsApi(
      {
        url: "api/contracts",
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          setError(data.error);
          return;
        }

        setContracts(data.contracts || []);
        setIsContractsLoading(false);
      }
    );
  };

  const calculateRemainingTime = (
    startTime: string | undefined,
    completeTime: string | undefined
  ): string => {
    if (!startTime || !completeTime) {
      return "N/A"; // Return 'N/A' or any appropriate placeholder when dates are null
    }

    const startDate = parseISO(startTime);
    const completeDate = parseISO(completeTime);
    const currentDate = new Date();

    if (currentDate > completeDate) {
      return "Time expired"; // Return this or a similar message if the current date is past the complete date
    }

    const days = differenceInDays(completeDate, currentDate);
    const hours = differenceInHours(completeDate, currentDate) % 24;
    const seconds = differenceInSeconds(completeDate, currentDate) % 60;

    return `${days} day${days !== 1 ? "s" : ""} ${hours} hour${
      hours !== 1 ? "s" : ""
    } ${seconds} second${seconds !== 1 ? "s" : ""}`;
  };

  function formatNumberToTwoDecimals(value: any) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    return Number(value).toFixed(2);
  }

  const isPathActive = (path: string) => {
    return location.pathname === path;
  };

  const handleUpxPoolChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setErrorForUpxHr("");
    setErrorCreateListing("");

    const newTotalUpxPool = parseFloat(event.target.value) || "";
    setTotalUpxPool(newTotalUpxPool.toString());

    if (!newTotalUpxPool || isNaN(newTotalUpxPool)) {
      setUpxHr("");
      setError("Please enter a valid Total UPX Pool value.");
      return;
    }

    const newUpxHr = newTotalUpxPool / (contract?.remaining_spark_hours || 1);
    if (Number(newUpxHr) < 1) {
      setErrorForUpxHr("Upx/hr must be greater than 1");
    }
    const addition_to_pool = Number(newTotalUpxPool) - Number(contract?.remaining_upx_pool)
    if(addition_to_pool > 0){
      setAdditionToPool(addition_to_pool.toString())
    }else {
      setAdditionToPool("")
    }
    setUpxHr(newUpxHr.toString());
  };

  const formatDateWithTime = (dateString: string | null): string => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <AgreeCreateListingModal
        isOpen={showAgreeCreateListing}
        onAction={updateListingHandler}
        onCancel={() => setShowAgreeCreateListing(false)}
      />
      <div className="h-full flex flex-col">
        <Header />
        <div className="flex flex-col lg:items-center justify-center space-y-3 border-b border-[#676767] mt-6 pb-6 lg:px-0 px-6">
          <div className="hidden lg:grid grid-cols-3 gap-6">
            <p
              onClick={() => navigate("/home")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/home")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              Spark Market
            </p>
            <p
              onClick={() => navigate("/dashboard")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/dashboard")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              SPARK DASHBOARD
            </p>

            <p
              onClick={() => navigate("/upx-dashboard")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/upx-dashboard")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              UPX DASHBOARD
            </p>
          </div>
          <NavigationSelect />
        </div>
        <div className="lg:px-20 px-5 flex-1 my-10">
          <div className="flex flex-col space-y-8">
            <div className="">
              <div className="grid lg:grid-cols-6 grid-cols-1 lg:gap-10">
                <div className="lg:col-span-2 col-span-12">
                  <div className="flex items-center mb-5 space-x-3">
                    <img
                      src={BackIcon}
                      className="w-8 cursor-pointer"
                      onClick={() => navigate(-1)}
                    />
                    <p className="uppercase font-ano font-bold text-2xl text-white">
                      PROPERTY DETAILS
                    </p>
                  </div>
                  <div className="flex flex-col bg-[#1C1C1C] rounded-2xl px-6 py-4">
                    <div>
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Address
                      </p>

                      <div className="flex items-start justify-between space-x-10 mt-3">
                        <p className="font-ano text-lg text-white font-bold">
                          {contract?.address} {contract?.city}
                        </p>
                        <p
                          className={`${
                            contract?.contract_status === "processing"
                              ? "bg-[#3E7EFF] text-white"
                              : contract?.contract_status === "completed"
                              ? "bg-[#1EA067] text-white"
                              : "bg-[#F9F41F] text-black"
                          }  capitalize font-ano font-bold text-xs p-1 w-28 text-center`}
                        >
                          {contract?.contract_status}
                        </p>
                      </div>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Type
                      </p>

                      <p className="font-ano capitalize text-xl text-white font-normal">
                        {contract?.property_type
                          ? contract?.property_type
                          : "---"}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        NftID
                      </p>

                      <p className="font-ano capitalize text-xl text-white font-normal">
                        {contract?.build_id ? contract?.build_id : "---"}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Staked Spark
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {formatNumberToTwoDecimals(contract?.current_stacked_spark)}/
                        {contract?.max_stacked_spark}{" "}
                        <span className="text-[#4F99F0]">SPARK</span>
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Remaining UPX Pool
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {formatNumberToTwoDecimals(
                          contract?.remaining_upx_pool
                        )}{" "}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Remaining Spark Hours
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {formatNumberToTwoDecimals(
                          contract?.remaining_spark_hours
                        )}{" "}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        UPX/hr
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {contract?.upx_hr
                          ? formatNumberToTwoDecimals(contract?.upx_hr)
                          : ""}
                        {contract?.upx_hr ? <></> : "---"}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Owner
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {contract?.upland_username
                          ? contract?.upland_username
                          : "---"}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Build Progress
                      </p>

                      <div className="mt-2">
                        {/* <ProgressBar
                          startedAt={contract?.startTime}
                          finishedAt={contract?.completeTime}
                        /> */}
                        <SparkProgressBar
                          remainingSparkHours={Number(contract?.remaining_spark_hours)}
                          requiredSparkHours={Number(contract?.required_spark_hours)}
                        />
                      </div>
                      <p className="font-ano text-sm text-[#999999] float-right mt-4 font-bold">
                        Remaining Time :{" "}
                        {calculateRemainingTime(
                          contract?.startTime,
                          contract?.completeTime
                        )}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="lg:col-span-4 col-span-12 flex flex-col space-y-4 lg:mt-0 mt-10">
                  {!isContractsLoading &&
                  contract?.eos_id === user?.upland_eos_id ? (
                    <div>
                      <>
                        <p className="uppercase font-ano font-bold text-2xl text-white mb-5">
                          Increase UPX PerHour
                        </p>

                        <div className="rounded-2xl grid lg:grid-cols-2 grid-cols-1">
                          <div className="bg-[#1C1C1C] rounded-tl-2xl lg:rounded-tr-none rounded-tr-2xl lg:rounded-bl-2xl">
                            <div className="px-4 py-6 flex flex-col">
                              <div className="flex flex-col">
                                <div className="lg:w-auto w-full px-2 pt-4">
                                  <div className="flex items-center justify-between">
                                    <p className="font-ano font-normal text-base mb-1 text-white">
                                      UPX/hr
                                    </p>
                                    <div className="font-ano text-base font-normal text-[#999999]">
                                      Current Balance:{" "}
                                      {new Intl.NumberFormat().format(
                                        Number(userUpxBalance) || 0
                                      )}
                                    </div>
                                  </div>
                                  <input
                                    type="text"
                                    className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                    placeholder=""
                                    value={upxHr}
                                    onChange={handleUpxHrChange}
                                  />
                                </div>

                                <div className="lg:w-auto w-full px-2 pt-4">
                                  <p className="font-ano font-normal text-base mb-1 text-white">
                                    UPX Pool
                                  </p>
                                  <input
                                    type="text"
                                    className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                    placeholder=""
                                    value={totalUpxPool}
                                    onChange={handleUpxPoolChange}
                                  />
                                </div>

                                {/* <div className="flex items-baseline space-x-2 px-2 pt-4">
                                  <input
                                    type="checkbox"
                                    name="verify"
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                  />
                                  <p className="font-ano font-normal text-lg text-white">
                                    Lorem Ipsum is simply dummy text of the printing
                                    and typesetting industry.
                                  </p>
                                </div> */}
                                <div className="flex items-center justify-between mt-5">
                                  <div
                                    onClick={() =>
                                      upxHr !== "" &&
                                      setShowAgreeCreateListing(true)
                                    }
                                    className={`w-40 cursor-pointer border-2 rounded-[50px] font-ano font-bold text-base text-center py-1 bg-[#1C1C1C] uppercase outline-none ${
                                      upxHr !== ""
                                        ? "hover:bg-[#3E7EFF] border-white text-white cursor-pointer"
                                        : "border-[#272727] text-[#272727] cursor-not-allowed"
                                    }`}
                                  >
                                    {!isLoadingCreateListingApi && (
                                      <>UPDATE LISTING</>
                                    )}
                                    {isLoadingCreateListingApi && (
                                      <ThreeDots
                                        height={20}
                                        width={20}
                                        color="#ffffff"
                                        wrapperStyle={{}}
                                        wrapperClass="justify-center py-1"
                                        visible={true}
                                        ariaLabel="oval-loading"
                                      />
                                    )}
                                  </div>

                                  {errorCreateListing && (
                                    <p className="font-ano text-sm font-bold uppercase text-[#FF6F6F]">
                                      {errorCreateListing}
                                    </p>
                                  )}
                                  {errorForUpxHr && (
                                    <p className="font-ano text-sm font-bold uppercase text-[#FF6F6F]">
                                      {errorForUpxHr}
                                    </p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="bg-[#272727] lg:rounded-tr-2xl h-full rounded-br-2xl lg:rounded-bl-none rounded-bl-2xl">
                            <div className="flex flex-col px-8 py-3 h-full">
                              <p className="text-[#999999] font-ano text-center font-normal text-lg mb-2">
                                LISTING ESTIMATIONS
                              </p>

                              <div className="flex items-center justify-between space-x-5">
                                <p className="font-ano font-bold text-lg text-white">
                                  Remaining spark hours
                                </p>
                                <p className="font-ano font-semibold text-xl text-[#F9F41F]">
                                  {formatNumberToTwoDecimals(
                                    contract?.remaining_spark_hours
                                  )}{" "}
                                  hrs
                                </p>
                              </div>

                              <div className="flex items-center justify-between space-x-5 mt-2">
                                <p className="font-ano font-normal text-lg text-white">
                                  Current UPX/hr
                                </p>
                                <p className="font-ano font-semibold text-xl text-white">
                                  <span>
                                    {formatNumberToTwoDecimals(
                                      contract?.upx_hr
                                    )}
                                  </span>{" "}
                                  <span className="text-[#F9F41F]">UPX</span>
                                </p>
                              </div>

                              <div className="flex items-center justify-between space-x-5">
                                <p className="font-ano font-normal text-lg text-white">
                                  Remaining UPX Pool
                                </p>
                                <p className="font-ano font-semibold text-xl text-white">
                                  <span>
                                    {formatNumberToTwoDecimals(
                                      contract?.remaining_upx_pool
                                    )}
                                  </span>{" "}
                                  <span className="text-[#F9F41F]">UPX</span>
                                </p>
                              </div>

                              <div className="flex items-center justify-between space-x-5 mt-4">
                                <p className="font-ano font-bold text-lg text-white">
                                  New UPX/hr
                                </p>
                                <p className="font-ano font-semibold text-xl text-white">
                                  <span>
                                    {formatNumberToTwoDecimals(
                                      upxHr.toString()
                                    )}
                                  </span>{" "}
                                  <span className="text-[#F9F41F]">
                                    UPX
                                    {/* {Number(upxHr) > 0 ? "UPX" : ""} */}
                                  </span>
                                </p>
                              </div>

                              <div className="flex items-center justify-between space-x-5">
                                <p className="font-ano font-bold text-lg text-white">
                                  New UPX Pool
                                </p>
                                <p className="font-ano font-semibold text-xl text-white">
                                  <span>
                                    {formatNumberToTwoDecimals(
                                      totalUpxPool.toString()
                                    )}
                                  </span>{" "}
                                  <span className="text-[#F9F41F]">UPX</span>
                                </p>
                              </div>
                              <br></br>

                              <div className="flex items-center justify-between space-x-5">
                                <p className="font-ano font-bold text-lg text-white">
                                  Addition to Pool
                                </p>
                                <p className="font-ano font-semibold text-xl text-white">
                                  <span>
                                    {formatNumberToTwoDecimals(
                                      addition_to_pool.toString()
                                    )}
                                  </span>{" "}
                                  <span className="text-[#F9F41F]">UPX</span>
                                </p>
                              </div>

                              <div className="border-b border-[#676767] my-4"></div>

                              <div className="flex items-center justify-between space-x-5">
                                <p className="font-ano font-bold text-lg text-white">
                                  Fee{" "}
                                  <span className="font-normal text-xs text-[#B5B5B5] uppercase">
                                    (Limited Time)
                                  </span>
                                </p>
                                <p className="font-ano font-semibold text-xl text-white">
                                  0%
                                </p>
                              </div>

                              {depositAmount < 0 && (
                                <div className="flex items-center justify-between space-x-7 mt-10">
                                  <div className="bg-[#1C1C1C] rounded-lg px-3 py-1 flex items-center space-x-3 text-white">
                                    <p className="font-ano font-bold text-base">
                                      Deposit Amount
                                    </p>
                                    <p className="font-ano font-bold text-base">
                                      {formatNumberToTwoDecimals(
                                        Math.abs(depositAmount)
                                      )}{" "}
                                      <span className="text-[#F9F41F]">
                                        UPX
                                      </span>
                                    </p>
                                  </div>
                                  <div>
                                    <p
                                      onClick={() => navigate("/deposit")}
                                      className="font-ano text-[#4F99F0] text-base font-normal cursor-pointer flex"
                                    >
                                      <span>Deposit now</span>
                                      <img
                                        src={BlueRedirectIcon}
                                        className="ml-2"
                                      />
                                    </p>
                                  </div>
                                </div>
                              )}
                            </div>
                          </div>
                        </div>
                      </>
                    </div>
                  ) : null}

                  {!isContractsLoading &&
                  contract?.eos_id !== user?.upland_eos_id ? (
                    <div>
                      {/* secoonds last if userStake is true */}
                      {contract?.userStake ? (
                        <>
                          <p className="uppercase font-ano font-bold text-2xl text-white mb-5">
                            Spark Calculator
                          </p>

                          <div className="rounded-2xl grid grid-cols-2">
                            <div className="bg-[#1C1C1C] rounded-tl-2xl rounded-bl-2xl">
                              <div className="px-4 py-6 flex flex-col">
                                <div className="flex flex-col">
                                  <div className="lg:w-auto w-full px-2 pt-4">
                                    <div className="flex items-center justify-between">
                                      <p className="font-ano font-normal text-base mb-1 text-white">
                                        Spark Amount
                                      </p>
                                      <p className="font-ano text-base font-normal text-[#999999]">
                                        Spark Balance:{" "}
                                        {user?.uplandBalance?.availableSpark}
                                      </p>
                                    </div>
                                    <div className="relative">
                                      <input
                                        type="text"
                                        className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                        placeholder=""
                                        value={sparkAmount}
                                        onChange={handleSparkAmountChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="lg:w-auto w-full mt-4 px-2 pt-4">
                                    <p className="font-ano font-normal text-base mb-1 text-white">
                                      Spark hours
                                    </p>
                                    <div className="relative">
                                      <input
                                        type="text"
                                        className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                        placeholder=""
                                        value={sparkHours}
                                        max={contract?.remaining_spark_hours}
                                        onChange={handleSparkHoursChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bg-[#272727] rounded-tr-2xl h-full rounded-br-2xl">
                              <div className="flex flex-col px-8 py-3 h-full">
                                <p className="text-[#999999] font-ano font-normal text-center text-lg border-b border-[#676767] mb-2">
                                  EARNINGS ESTIMATIONS
                                </p>

                                <div className="flex items-center justify-between space-x-5 mt-4">
                                  <p className="font-ano font-normal text-lg text-[#999999]">
                                    Staked Spark
                                  </p>
                                  <p className="font-ano font-semibold text-xl text-white">
                                    {formatNumberToTwoDecimals(contract?.userStakeAmount)}{" "}
                                    <span className="text-[#4F99F0] uppercase">
                                      SPARK
                                    </span>
                                  </p>
                                </div>

                                <div className="flex items-center justify-between space-x-5 mt-3">
                                  <p className="font-ano font-bold text-lg text-white">
                                    Current Daily Earnings
                                  </p>
                                  <p className="font-ano font-semibold text-xl text-white">
                                    <span>
                                      {formatNumberToTwoDecimals(
                                        Number(contract?.upx_hr) *
                                          Number(contract?.userStakeAmount) *
                                          24
                                      )}
                                    </span>{" "}
                                    <span className="text-[#F9F41F]">UPX</span>
                                  </p>
                                </div>
                                <div className="flex items-center justify-between space-x-5 mt-3">
                                  <p className="font-ano font-bold text-lg text-white">
                                    New Daily Earnings
                                  </p>
                                  <p className="font-ano font-semibold text-xl text-white">
                                    <span>
                                      {formatNumberToTwoDecimals(
                                        Number(contract?.upx_hr) *
                                          Number(sparkAmount) *
                                          24
                                      )}
                                    </span>{" "}
                                    <span className="text-[#F9F41F]">UPX</span>
                                  </p>
                                </div>

                                <div className="flex items-center justify-between space-x-5 mt-3">
                                  <p className="font-ano font-bold text-lg text-white">
                                    Estimated Total Earnings
                                  </p>
                                  <p className="font-ano font-semibold text-xl text-white">
                                    <span>
                                      {formatNumberToTwoDecimals(
                                        estimatedTotalEarnings
                                      )}
                                    </span>{" "}
                                    <span className="text-[#F9F41F]">UPX</span>
                                  </p>
                                </div>

                                <div className="flex items-center justify-end mt-10">
                                  <a
                                    href={`https://play.upland.me/?prop_id=${contract?.property_id}`}
                                    target="_blank"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <p className="font-ano text-[#4F99F0] cursor-pointer text-base flex font-normal">
                                      <span>Stake in upland</span>
                                      <img
                                        src={BlueRedirectIcon}
                                        className="ml-2"
                                      />
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <p className="uppercase font-ano font-bold text-2xl text-white mb-5">
                            Spark Calculator
                          </p>

                          <div className="rounded-2xl grid grid-cols-2">
                            <div className="bg-[#1C1C1C] rounded-tl-2xl rounded-bl-2xl">
                              <div className="px-4 py-6 flex flex-col">
                                <div className="flex flex-col">
                                  <div className="lg:w-auto w-full px-2 pt-4">
                                    <div className="flex items-center justify-between">
                                      <p className="font-ano font-normal text-base mb-1 text-white">
                                        Spark Amount
                                      </p>
                                      <p className="font-ano text-base font-normal text-[#999999]">
                                        Spark Balance:{" "}
                                        {user?.uplandBalance?.availableSpark}
                                      </p>
                                    </div>
                                    <div className="relative">
                                      <input
                                        type="text"
                                        className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                        placeholder=""
                                        value={sparkAmount}
                                        onChange={handleSparkAmountChange}
                                      />
                                    </div>
                                  </div>

                                  <div className="lg:w-auto w-full mt-4 px-2 pt-4">
                                    <p className="font-ano font-normal text-base mb-1 text-white">
                                      Spark hours
                                    </p>
                                    <div className="relative">
                                      <input
                                        type="text"
                                        className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                        placeholder=""
                                        value={sparkHours}
                                        max={contract?.remaining_spark_hours}
                                        onChange={handleSparkHoursChange}
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="bg-[#272727] rounded-tr-2xl h-full rounded-br-2xl">
                              <div className="flex flex-col px-8 py-3 h-full">
                                <p className="text-[#999999] font-ano font-normal text-center text-lg border-b border-[#676767] mb-2">
                                  EARNINGS ESTIMATIONS
                                </p>

                                <div className="flex items-center justify-between space-x-5 mt-4">
                                  <p className="font-ano font-normal text-lg text-[#999999]">
                                    Staked Spark
                                  </p>
                                  <p className="font-ano font-semibold text-xl text-white">
                                    {contract?.userStakeAmount}{" "}
                                    <span className="text-[#4F99F0] uppercase">
                                      SPARK
                                    </span>
                                  </p>
                                </div>

                                <div className="flex items-center justify-between space-x-5 mt-3">
                                  <p className="font-ano font-bold text-lg text-white">
                                    Current Daily Earnings
                                  </p>
                                  <p className="font-ano font-semibold text-xl text-white">
                                    <span>
                                      {formatNumberToTwoDecimals(
                                        Number(contract?.upx_hr) *
                                          Number(contract?.userStakeAmount) *
                                          24
                                      )}
                                    </span>{" "}
                                    <span className="text-[#F9F41F]">UPX</span>
                                  </p>
                                </div>
                                <div className="flex items-center justify-between space-x-5 mt-3">
                                  <p className="font-ano font-bold text-lg text-white">
                                    New Daily Earnings
                                  </p>
                                  <p className="font-ano font-semibold text-xl text-white">
                                    <span>
                                      {formatNumberToTwoDecimals(
                                        Number(contract?.upx_hr) *
                                          Number(sparkAmount) *
                                          24
                                      )}
                                    </span>{" "}
                                    <span className="text-[#F9F41F]">UPX</span>
                                  </p>
                                </div>

                                <div className="flex items-center justify-between space-x-5 mt-3">
                                  <p className="font-ano font-bold text-lg text-white">
                                    Estimated Total Earnings
                                  </p>
                                  <p className="font-ano font-semibold text-xl text-white">
                                    <span>
                                      {formatNumberToTwoDecimals(
                                        estimatedTotalEarnings
                                      )}
                                    </span>{" "}
                                    <span className="text-[#F9F41F]">UPX</span>
                                  </p>
                                </div>

                                <div className="flex items-center justify-end mt-10">
                                  <a
                                    href={`https://play.upland.me/?prop_id=${contract?.property_id}`}
                                    target="_blank"
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <p className="font-ano text-[#4F99F0] text-base cursor-pointer font-normal flex">
                                      <span>Stake in upland</span>
                                      <img
                                        src={BlueRedirectIcon}
                                        className="ml-2"
                                      />
                                    </p>
                                  </a>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </div>
                  ) : null}

                  <div>
                    <div className="flex items-center mb-2">
                      <div className="flex items-center space-x-5">
                        <p className="uppercase font-ano font-bold text-2xl text-white">
                          STAKE HISTORY
                        </p>

                        {/* <div className="flex items-center space-x-3">
                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            SPARK LOGS
                          </p>

                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            COMPLETED CONTRACTS
                          </p>
                        </div> */}
                      </div>
                    </div>

                    <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6 mb-10">
                      <div className="flow-root">
                        <div className="overflow-x-auto">
                          <div className="inline-block min-w-full py-2">
                            <table className="min-w-full divide-y divide-[#2C2C2C]">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 text-center font-ano font-bold text-xl text-white"
                                  >
                                    Username
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Staked Spark
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Time
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Type
                                  </th>

                                  {/* <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Rewards
                                  </th> */}
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-[#2C2C2C]">
                                {contract?.stakeHistory &&
                                contract?.stakeHistory.length > 0 ? (
                                  contract?.stakeHistory.map((item) => (
                                    <tr key={item?.spark_staked_id}>
                                      <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                        {item?.upland_username}
                                      </td>
                                      <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                        {item.spark_amount}
                                      </td>
                                      <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                        {item?.start_time
                                          ? formatDateWithTime(item.start_time)
                                          : "N/A"}
                                      </td>
                                      <td className="py-4 capitalize font-ano font-normal text-xl text-center text-white">
                                        {item?.type}
                                      </td>
                                    </tr>
                                  ))
                                ) : (
                                  <tr>
                                    <td
                                      colSpan={5}
                                      className="py-4 font-ano font-normal text-xl text-center text-white"
                                    >
                                      No Stake History Available
                                    </td>
                                  </tr>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
