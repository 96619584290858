import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context";
import { LoadingSpinner } from "../components/LoadingSpinner";

interface ProtectedRouteProps {
  children: ReactNode;
}

export function ProtectedRoute({ children }: ProtectedRouteProps): JSX.Element | null {
  const { user, isLoading } = useAuth();
// console.log('isLoadinggggggggggggg', isLoading)
//   if (isLoading) {
//     return <LoadingSpinner />;
//   }

//   console.log('=========================')
//   console.log('isLoading', isLoading)
//   console.log('user', user)
//   console.log('=========================')

//   if (!user) {
//     return <Navigate to="/login" />;
//   }

  return <>{children}</>; // Wrap children in a fragment
}
