import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAuth } from "../context";
import { useEffect, useRef, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";
import { ThreeDots } from "react-loader-spinner";
import ReCAPTCHA from "react-google-recaptcha";

interface IFeedbackForm {
  fullName: string;
  email: string;
  message: string;
}

interface IFormErrors {
  fullName: string;
  email: string;
  message: string;
}

export function ContactUs() {
  const { user } = useAuth();
  const notify = useNotification();
  const [feedback, setFeedback] = useState<IFeedbackForm>({
    fullName: "",
    email: "",
    message: ""
  });
  const [errors, setErrors] = useState<IFormErrors>({
    fullName: "",
    email: "",
    message: ""
  });
  const [isFeedbackPresent, setIsFeedbackPresent] = useState(false);
  const [isVerifiedCaptcha, setIsVerifiedCaptcha] = useState(false);

  const recaptchaRef = useRef(null);

  const { sendRequest: sendFeedbackApi, isLoading: isLoadingFeedbackApi } =
    useHttp();

  const {
    sendRequest: verifyCaptchaApi,
    isLoading: isLoadingVerifyCaptchaApi
  } = useHttp();

  const validateForm = (): boolean => {
    let isValid = true;
    let newErrors: IFormErrors = { fullName: "", email: "", message: "" };

    if (!feedback.fullName) {
      newErrors.fullName = "Full name is required";
      isValid = false;
    }
    if (!feedback.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }
    if (!feedback.message) {
      newErrors.message = "Message is required";
      isValid = false;
    }

    setErrors(newErrors);
    return isValid;
  };

  const sendFeedbackHandler = () => {
    if (!validateForm()) {
      return; // Stop the function if the form is not valid
    }

    if (!isVerifiedCaptcha) {
      return;
    }

    sendFeedbackApi(
      {
        url: "api/feedback",
        method: "POST",
        payload: JSON.stringify({
          fullName: feedback.fullName,
          email: feedback.email,
          message: feedback.message
        }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
          console.log("Message", data?.message);
          setIsFeedbackPresent(true);
          setFeedback({
            fullName: "",
            email: "",
            message: ""
          });
        }
      }
    );
  };

  const onReCAPTCHAChange = (captchaCode) => {
    console.log("captchaCode", captchaCode);
    if (!captchaCode) {
      return; // User did not solve CAPTCHA yet
    }

    verifyCaptchaApi(
      {
        url: "api/verify-recaptcha",
        method: "POST",
        payload: JSON.stringify({
          email: feedback.email,
          token: captchaCode
        }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
          console.log("Message", data?.message);
          setIsVerifiedCaptcha(true);
        }
      }
    );
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <Header />
        <div className="lg:px-10 px-5">
          <div className="lg:my-0 my-10 lg:flex w-full lg:items-center lg:justify-center flex-col">
            <p className="font-ano font-bold text-4xl text-white text-center mb-8 uppercase">
              Contact Us
            </p>
            <div className="flex flex-col space-y-6 lg:w-3/4 w-full lg:mx-auto">
              <div className="flex flex-col bg-[#1C1C1C] rounded-2xl py-5 lg:px-10 px-4">
                <div className="flex lg:flex-row flex-col lg:items-center lg:justify-between lg:space-x-4">
                  <div className="w-full">
                    <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                      Full Name
                    </p>
                    <input
                      type="text"
                      placeholder="Your name"
                      className="bg-[#1C1C1C] py-1 lg:px-5 px-4 w-full font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[30px] outline-none"
                      value={feedback.fullName}
                      onChange={(e) =>
                        setFeedback({ ...feedback, fullName: e.target.value })
                      }
                    />
                    {errors.fullName && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {errors.fullName}
                      </div>
                    )}
                  </div>
                  <div className="w-full lg:mt-0 mt-4">
                    <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                      Email Address
                    </p>
                    <input
                      placeholder="abc@xyz.com"
                      type="text"
                      className="bg-[#1C1C1C] py-1 lg:px-5 px-4 w-full font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[30px] outline-none"
                      value={feedback.email}
                      onChange={(e) =>
                        setFeedback({ ...feedback, email: e.target.value })
                      }
                    />
                    {errors.email && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-col space-y-2 mt-4">
                  <div className="w-full">
                    <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                      Message
                    </p>
                    <textarea
                      placeholder="your message here..."
                      className="bg-[#1C1C1C] h-36 py-1 lg:px-5 px-4 w-full font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[30px] outline-none"
                      value={feedback.message}
                      onChange={(e) =>
                        setFeedback({ ...feedback, message: e.target.value })
                      }
                    ></textarea>

                    {errors.message && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {errors.message}
                      </div>
                    )}
                  </div>
                </div>

                <div className="mt-4">
                  <ReCAPTCHA
                    ref={recaptchaRef}
                    sitekey="6LdCdWYpAAAAAI4guRF709797YM4AMO3L1p1z_Hc"
                    onChange={onReCAPTCHAChange}
                  />
                </div>

                <div
                  className={`flex items-center ${
                    isFeedbackPresent ? "justify-between" : "justify-end"
                  } mt-6`}
                >
                  {isFeedbackPresent && (
                    <p className="font-ano font-bold lg:text-2xl text-base text-[#1EA067]">
                      THANK YOU FOR YOUR FEEDBACK
                    </p>
                  )}
                  <button
                    onClick={sendFeedbackHandler}
                    type="button"
                    className="lg:w-[210px] w-full cursor-pointer border-2 rounded-[30px] border-[#1EA067] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    {!isLoadingFeedbackApi && <>Send</>}
                    {isLoadingFeedbackApi && (
                      <ThreeDots
                        height={20}
                        width={20}
                        color="#ffffff"
                        wrapperStyle={{}}
                        wrapperClass="justify-center py-1"
                        visible={true}
                        ariaLabel="oval-loading"
                      />
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
