import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onAction: (numberOfShares: number) => Promise<void>;
  numberOfShares: number; // Assuming you manage it as a string due to input handling
  setNumberOfShares: (value: number) => void; // Adjust based on your state management
  maxShares: number | null;
  upxPerShare: number;
  fee: number;
}

export default function BuyShareModal({
  isOpen,
  onCancel,
  onAction,
  numberOfShares,
  setNumberOfShares,
  maxShares,
  upxPerShare,
  fee,
}: Props) {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const totalUpxCost = Number(numberOfShares) * upxPerShare + fee;
  
  const handleBuy = async () => {
    const shares = Number(numberOfShares);
    if (!shares) return;
  
    setIsSubmitting(true);
    try {
      await onAction(shares);
      onCancel(); // Close the modal upon successful action
    } catch (error) {
      console.error("Error buying shares:", error);
      // Handle error (e.g., show error message to user)
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <div className="fixed inset-0 bg-[#000000DE] transition-opacity" />

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-[#1C1C1C] text-left shadow-xl transition-all w-[550px] h-auto">
                <div className="py-10 px-6 flex flex-col ">
                  <p className="font-ano font-bold text-2xl w-full text-center text-white mb-4 uppercase">
                    Buy Share
                  </p>

                  <div className="w-full px-10 pt-4">
                    <div className="flex items-center justify-between">
                      <p className="font-ano uppercase font-normal text-base mb-1 text-white">
                        NO# of Shares
                      </p>
                      <div className="font-ano text-base font-normal text-[#999999]">
                        Max Shares: {maxShares}
                      </div>
                    </div>
                    <input
                      type="text"
                      className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                      placeholder=""
                      value={numberOfShares}
                      onChange={(e) => setNumberOfShares(Number(e.target.value))}
                    />
                  </div>

                  <div className="px-10 mt-5">
                    <div className="flex items-center justify-between mb-3">
                      <p className="font-ano text-base font-normal text-white">
                        UPX/Share amount
                      </p>
                      <p className="font-ano text-base font-bold text-white">
                        {numberOfShares}
                      </p>
                    </div>

                    <div className="flex items-center justify-between mb-3">
                      <p className="font-ano text-base font-normal text-white">
                        Total UPX after sale
                      </p>
                      <p className="font-ano text-base font-bold text-white">
                        {totalUpxCost - fee}
                      </p>
                    </div>

                    <div className="flex items-center justify-between mb-3">
                      <p className="font-ano text-base font-normal text-white">
                        Fee
                      </p>
                      <p className="font-ano text-base font-bold text-white">
                        {fee}
                      </p>
                    </div>

                    <hr />

                    <div className="flex items-center justify-between mt-3 mb-3">
                      <p className="font-ano text-base font-normal text-white">
                        Total
                      </p>
                      <p className="font-ano text-base font-bold text-white">
                        {totalUpxCost}
                      </p>
                    </div>
                  </div>

                  <div className="flex items-center justify-center space-x-3">
                    <button
                      onClick={handleBuy}
                      disabled={isSubmitting}
                      type="button"
                      className={`w-[180px] lg:mt-8 mt-6 ${
                        isSubmitting ? "cursor-not-allowed" : "cursor-pointer"
                      } border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none`}
                    >
                      {isSubmitting ? "Processing..." : "Buy"}
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
