import React, {
  createContext,
  useContext,
  useState,
  ReactNode,
  useEffect,
} from "react";
import { useHttp, useNotification } from "../common/hooks";
import { redirect, useNavigate } from "react-router-dom";

interface AuthContextType {
  authToken: string;
  user: User | null;
  updateAuthToken: (token: string) => void;
  loading?: boolean;
  isLoading?: boolean;
  isDrawerOpen: boolean;
  setIsDrawerOpen: (isOpen: boolean) => void;
  setUser: (value: null) => void;
  getUser: (value: string) => any;
}

const AuthContext = createContext<AuthContextType | null>(null);

interface AuthProviderProps {
  children: ReactNode; // Define the type for children
}

interface User {
  email_address: string;
  full_name: string;
  wax_address: string;
  eth_address: string;
  twitter_account: string;
  discord_id: string;
  otp?: string;
  auth_token?: string;
  upland_user_id?: string;
  code?: string;
  upland_auth_token?: string;
  upland_eos_id?: string;
  upland_username?: string;
  networth?: bigint;
  upland_level?: string;
  avatar_url?: string;
  userEmail?: string;
  walletUpxBalance?: string;
  currentCity?: string;
  uplandBalance?: UplandBalance;
  isAdmin: boolean;
}

interface UplandBalance {
  availableUpx: number;
  availableSpark: number;
  stakedSpark: number;
  availableSends: number;
}

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const notify = useNotification();
  // const navigate = useNavigate();
  const [authToken, setAuthToken] = useState<string>("");
  const [user, setUser] = useState<User | null>(null);
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(true); // Start with true



  const updateAuthToken = (token: string) => {
    localStorage.setItem("authToken", token);
    setAuthToken(token);
  };

  const { sendRequest: getUserApi, isLoading: isLoadingGetUserApi } = useHttp();

  useEffect(() => {
    const token = localStorage.getItem("authToken");
    if (token) {
      updateAuthToken(token);
      getUser(token); // Fetch user details if token exists
    } else {
      setIsLoading(false); // Set to false if no token
    }
  }, [authToken]);

  const getUser = (token: string) => {
    setIsLoading(true); // Set loading to true when starting the API call

    getUserApi(
      {
        url: "api/user",
        method: "GET",
        payload: {},
        headers: {
          "Content-Type": "application/json",
          Authorization: token || "",
        },
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          localStorage.removeItem("authToken");
          updateAuthToken("");
          setUser(null);
          redirect("/login");
          // navigate("/home");
          return;
        } else {
          setUser(data);
        }
        setIsLoading(false); // Set loading to false after handling error

      }
    );
  };
  return (
    <AuthContext.Provider
      value={{
        authToken,
        updateAuthToken,
        user,
        setUser,
        isLoading,
        loading: isLoadingGetUserApi,
        isDrawerOpen,
        setIsDrawerOpen,
        getUser
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  const context = useContext(AuthContext);
  if (!context) {
    throw new Error("useAuth must be used within an AuthProvider");
  }
  return context;
};
