import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import Step1 from "../assets/images/steps/step1.svg";
import Step2 from "../assets/images/steps/step2.svg";
import Step3 from "../assets/images/steps/step3.svg";
import Step4 from "../assets/images/steps/step4.svg";
import Step5 from "../assets/images/steps/step5.svg";
import Step6 from "../assets/images/steps/step6.svg";
import Step7 from "../assets/images/steps/step7.svg";
import CloseIcon from "../assets/images/close.svg";
import LeftArrowIcon from "../assets/images/left-arrow.svg";
import RightArrowIcon from "../assets/images/right-arrow.svg";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
  onAction: () => void;
}

export default function DepositModal({ isOpen, onCancel, onAction }: Props) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <div className="fixed inset-0 bg-[#000000DE] transition-opacity" />

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-[#1C1C1C] text-left shadow-xl transition-all w-[550px] h-auto">
                <div className="lg:p-10 p-5 flex flex-col items-center justify-center">


                  <p className="font-ano font-bold text-2xl text-white mb-4">DEPOSIT UPX disclaimer. </p>
                  <p className="font-ano text-lg font-normal text-justify text-white">
                  Depositing UPX to the UPXPLORER.me platform. By making a deposit of UPX to the Upxplorer platform the UPX will be shows as a credit displayed in the users profile.The UPX balance can be used for services offered on the Upxplorer platform. For each UPX Deposit made, user will be charged a 10% fee by Upland. This fee is calcuated and displayed prior to a user submitting the deposit request. This is a non recoverable transaction fee. User can request a withdraw at anytime. The same Upland transaction fee will apply per each Deposit/Withdraw requested. By checking the box next to the text (i agree) you are indicating that you have read and accept the Upxplorer's terms and conditions.
                  </p>  
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={onAction}
                      type="button"
                      className="w-[180px] tracking-widest lg:mt-8 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                    >
                      I agree
                    </button>

                    
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
