import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import BackIcon from "../assets/images/back.svg";
import { useNavigate } from "react-router-dom";

interface RewardHistory {
  address: string;
  city: string;
  price: number;
  property_id: number;
  property_type: string;
  reward_amount: number;
  rewarded_time: string;
  user_balance: string;
  user_email: string;
  spark_hours: number;
  reward_id: number;
}

export function RewardHistory() {
  const { authToken, user } = useAuth();
  const [rewardHistory, setRewardHistory] = useState<RewardHistory[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const navigate = useNavigate();

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);

  const { sendRequest: getRewardHistoryApi } = useHttp();

  useEffect(() => {
    if (authToken && user) getRewardHistoryHandler();
  }, [authToken, user?.email_address, currentPage]);

  const getRewardHistoryHandler = () => {
    setIsLoading(true);
    setError("");

    getRewardHistoryApi(
      {
        url: `api/reward-history?page=${currentPage}&pageSize=10`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        setIsLoading(false);

        if (data?.error) {
          setError(data.error);
          return;
        }
        setRewardHistory(data.history || []);
        setTotalPages(data.pagination.totalPages); // Set total pages from response
      }
    );
  };

  // const renderPagination = () => {
  //   // Check if there are pages to display
  //   if (totalPages <= 1) {
  //     return null; // Don't render pagination for 1 or 0 pages
  //   }
  //   let pages: JSX.Element[] = []; // Explicitly define the type of pages as an array of JSX elements
  //   for (let i = 1; i <= totalPages; i++) {
  //     pages.push(
  //       <button
  //         key={i}
  //         className={`px-1 ${
  //           i === currentPage ? "text-[#4F99F0]" : "text-white"
  //         }`}
  //         onClick={() => setCurrentPage(i)}
  //       >
  //         [ {i} ]
  //       </button>
  //     );
  //   }
  //   return <div className="flex space-x-2">{pages}</div>;
  // };

  const renderPagination = () => {
    if (totalPages <= 1) {
      return null; // Don't render pagination for 1 or 0 pages
    }
  
    let pages: JSX.Element[] = []; // Correctly typed as an array of JSX elements
    let startPage: number, endPage: number;
  
  
    const paginationCutOff = 5; // Number of pages to display in the pagination bar
  
    if (totalPages <= paginationCutOff) {
      // Less than paginationCutOff total pages so show all
      startPage = 1;
      endPage = totalPages;
    } else {
      // More than paginationCutOff total pages
      if (currentPage <= 3) {
        startPage = 1;
        endPage = paginationCutOff;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - (paginationCutOff - 1);
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }
  
    // First page
    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          className="text-white"
          onClick={() => setCurrentPage(1)}
        >
          [1]
        </button>
      );
      pages.push(<span key="start-ellipsis" className="text-white">...</span>);
    }
  
    // Middle pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`px-1 ${i === currentPage ? "text-[#4F99F0]" : "text-white"}`}
          onClick={() => setCurrentPage(i)}
        >
          [{i}]
        </button>
      );
    }
  
    // Last page
    if (endPage < totalPages) {
      pages.push(<span key="end-ellipsis" className="text-white">...</span>);
      pages.push(
        <button
          key={totalPages}
          className="text-white"
          onClick={() => setCurrentPage(totalPages)}
        >
          [{totalPages}]
        </button>
      );
    }
  
    return <div className="flex space-x-2">{pages}</div>;
  };
  

  const formatDateWithTime = (dateString: string | null): string => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  function formatNumberToTwoDecimals(value: any) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    return Number(value).toFixed(2);
  }

  return (
    <>
      <div className="flex flex-col h-full">
        <Header />
        <div className="flex-1 px-20 mb-10">
          <div className="flex items-center justify-between mt-16">
            <div className="flex items-center space-x-2">
              <img
                src={BackIcon}
                className="w-8 cursor-pointer"
                onClick={() => navigate(-1)}
              />
              <p className="font-ano font-bold text-3xl text-white">
                Reward History
              </p>
              {/* <div>
                <select className="px-4 py-2 uppercase rounded-full bg-[#1C1C1C] border border-white text-white">
                  <option>Filters</option>
                  <option>Filters</option>
                  <option>Filters</option>
                  <option>Filters</option>
                </select>
              </div> */}
            </div>
            {/* <div>
              <div>
                <select className="px-4 py-2 uppercase rounded-full bg-[#1C1C1C] border border-white text-white">
                  <option>Sort by</option>
                  <option>Sort by</option>
                  <option>Sort by</option>
                  <option>Sort by</option>
                </select>
              </div>
            </div> */}
          </div>

          <div className="bg-[#1C1C1C] rounded-2xl py-6 px-3 my-10">
            <div className="flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2">
                  <table className="min-w-full divide-y divide-[#2C2C2C]">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Address
                        </th>
                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Spark Hours
                        </th>
                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Upx Balance
                        </th>
                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Amount
                        </th>

                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Time
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-[#2C2C2C]">
                      {isLoading ? (
                        <tr>
                          <td
                            colSpan={5}
                            className="py-10 font-ano text-white text-lg text-center font-bold"
                          >
                            Loading history...
                          </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td
                            colSpan={5}
                            className="py-10 font-ano text-white text-lg text-center font-bold"
                          >
                            Error: {error}
                          </td>
                        </tr>
                      ) : rewardHistory.length > 0 ? (
                        rewardHistory.map((reward) => (
                          <tr key={reward.reward_id}>
                            <td className="py-4 font-ano font-normal text-lg px-4 text-center text-white">
                              {reward.address} {reward.city}
                            </td>
                            <td
                              className={`py-4 capitalize font-ano font-normal text-lg px-4 text-center text-white`}
                            >
                              {reward.spark_hours}
                            </td>

                            <td
                              className={`py-4 font-ano font-normal text-lg px-4 text-center text-white`}
                            >
                              {reward.user_balance}
                            </td>

                            <td
                              className={`py-4 capitalize font-ano font-normal text-lg px-4 text-center text-white`}
                            >
                              {formatNumberToTwoDecimals(reward.reward_amount)}
                              <span className="text-[#F9F41F]"> UPX</span>
                            </td>

                            <td
                              className={`py-4 capitalize font-ano font-normal text-lg px-4 text-center text-white`}
                            >
                              {formatDateWithTime(reward.rewarded_time)}
                            </td>
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={5}
                            className="py-10 font-ano text-white text-lg text-center font-bold"
                          >
                            No history found.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center my-4">
              {renderPagination()}
            </div>
          </div>
        </div>
          <Footer />
      </div>
    </>
  );
}
