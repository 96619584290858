interface Props {
  title: string;
}
export function MainHeading({ title }: Props) {
  return (
    <>
      <p className="font-ano lg:text-4xl text-2xl font-extrabold text-white lg:mt-0 mt-12">
        {title}
      </p>
    </>
  );
}
