import AppRouter from "./routes/AppRouter";
import { BrowserRouter as Router } from "react-router-dom";
import { AuthProvider } from "./context";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <>
      <ToastContainer autoClose={5000} />
      <AuthProvider>
        <Router>
          <AppRouter />
        </Router>
      </AuthProvider>
    </>
  );
}

export default App;
