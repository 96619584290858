import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../context";
import { ThreeDots } from "react-loader-spinner";
import SignupBack from "../assets/images/signup-back.svg";
interface VerificationData {
  email: string;
  code: string;
}

export function Signup() {
  const navigate = useNavigate();
  const { authToken, updateAuthToken, setUser } = useAuth();
  const notify = useNotification();
  const [disableResend, setDisableResend] = useState(false);
  const [secondsLeft, setSecondsLeft] = useState<number>(30);

  const [email, setEmail] = useState("");
  const [otp, setOtp] = useState("");
  const [emailError, setEmailError] = useState("");
  const [otpError, setOtpError] = useState("");
  const [emailCard, setShowEmailCard] = useState(false);
  const [isUplandConnected, setIsUplandConnected] = useState(false);
  const [showPrivacyPolicy, setShowPrivacyPolicy] = useState(false);
  const [verificationData, setVerificationData] =
    useState<VerificationData | null>(null);

  const showEmailCard = () => {
    setShowEmailCard(true);
  };

  // useEffect(() => {
  //   if (authToken) {
  //     navigate("/home");
  //   }
  // }, [authToken]);

  const backToSignup = () => {
    setShowEmailCard(false);
    setVerificationData(null);
  };

  const { sendRequest: continueSignupApi, isLoading: isLoadingSignupApi } =
    useHttp();
  const { sendRequest: continueVerfiyOtpApi, isLoading: isLoadingOtpVerfiy } =
    useHttp();

  const validateEmail = (email: string) => {
    return /\S+@\S+\.\S+/.test(email);
  };

  const continueSignup = () => {
    if (isLoadingSignupApi) return;
    if (!validateEmail(email)) {
      setEmailError("Invalid email");
      return;
    }

    setEmailError("");
    continueSignupApi(
      {
        url: "api/signup",
        method: "POST",
        payload: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          return;
          console.log("Message", data?.error);
        }
        if (data?.message) {
          notify(data?.message, "success");
          console.log("Message", data?.message);
        }
        setShowEmailCard(true);
        setVerificationData(data);
      }
    );
  };

  const continueVerification = () => {
    if (isLoadingOtpVerfiy) return;
    if (!otp) {
      setOtpError("OTP is required");
      return;
    }

    setOtpError("");
    continueVerfiyOtpApi(
      {
        url: "api/verify-otp",
        method: "POST",
        payload: JSON.stringify({ email, otp }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }



        updateAuthToken(data?.authentication_token);
        localStorage.setItem("authToken", data?.authentication_token)
        // setShowPrivacyPolicy(true);
        setVerificationData(null);
        setShowEmailCard(false);
        // setIsUplandConnected(data?.isUplandConnected);
        if (!data?.isUplandConnected) {
          navigate("/connect-upland");
        } else {
          navigate("/home");
        }
      }
    );
  };

  const resendOtp = () => {
    if (disableResend) return; // Exit the function if resend is currently disabled
    if (!validateEmail(email)) {
      setEmailError("Invalid email");
      return;
    }

    setEmailError("");
    continueSignupApi(
      {
        url: "api/resend-otp",
        method: "POST",
        payload: JSON.stringify({ email }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
          console.log("Message", data?.message);
        }
        console.log(data);
        // setShowEmailCard(false);
        // setVerificationData(data);
      }
    );

    // Disable the resend button and start the countdown timer
    setDisableResend(true);
    setSecondsLeft(30);

    const intervalId = setInterval(() => {
      setSecondsLeft((prev) => {
        if (prev <= 1) {
          clearInterval(intervalId);
          setDisableResend(false);
          return 0;
        }
        return prev - 1;
      });
    }, 1000);
  };

  const acceptHandler = () => {
    if (!isUplandConnected) {
      navigate("/connect-upland");
    } else {
      navigate("/home");
    }
  };

  return (
    <>
      <div className="flex flex-col justify-center h-full">
        <Header />
        <div className="my-10 lg:px-10 md:px-0 px-5 lg:flex w-full lg:items-center lg:justify-center flex-1">
          <div className="flex flex-col items-center justify-center">
            <p className="font-ano lg:text-4xl text-2xl font-extrabold text-white">
              Welcome to UPXPLORER.ME
            </p>
            <p className="lg:block hidden font-ano lg:text-2xl text-xl font-normal mt-4 text-[#979797]">
              Spark Market - Auction - Banking
            </p>
            <p className="lg:hidden block text-center font-ano text-xl lg:mt-0 mt-4 font-normal text-[#979797]">
              Spark Market <br /> Auction <br /> Banking
            </p>

            {!emailCard && !verificationData && !showPrivacyPolicy && (
              <div className="w-full px-12 py-10 rounded-[30px] bg-[#1C1C1C] flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-3xl text-white text-center mb-6">
                  Sign Up/Login
                </p>
                <div className="lg:w-auto w-full">
                  <div className="flex items-center justify-between">
                    <p className="font-ano font-normal lg:block hidden lg:text-lg text-base mb-1 text-white">
                      Email address
                    </p>
                    {emailError && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {emailError}
                      </div>
                    )}
                  </div>
                  <input
                    type="email"
                    className="bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 lg:w-[510px] w-full focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                    placeholder="Email Address"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
                <div
                  onClick={continueSignup}
                  className="w-[210px] lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-[22px] text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                >
                  {!isLoadingSignupApi && <>Continue</>}
                  {isLoadingSignupApi && (
                    <ThreeDots
                      height={20}
                      width={20}
                      color="#ffffff"
                      wrapperStyle={{}}
                      wrapperClass="justify-center py-1"
                      visible={true}
                      ariaLabel="oval-loading"
                    />
                  )}
                </div>
              </div>
            )}

            {emailCard && verificationData && (
              <div className="w-full px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  VERIFY OTP
                </p>
                <div className="lg:w-auto w-full">
                  <div className="flex items-center justify-between">
                    <p className="mb-1 font-ano font-normal text-lg text-white">
                      Email address
                    </p>
                    {emailError && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {emailError}
                      </div>
                    )}
                  </div>
                  <div className="relative w-full">
                    <img
                      src={SignupBack}
                      className="absolute right-6 top-4 cursor-pointer"
                      onClick={backToSignup}
                    />
                    <input
                      type="email"
                      disabled
                      className="bg-[#979797] focus:border-[#3E7EFF] lg:py-2 py-1 lg:px-5 px-4 lg:w-[510px] w-full font-ano text-[#676767] font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                      placeholder="Email Address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </div>
                </div>

                <div className="mt-3 lg:w-auto w-full">
                  <div className="flex items-center justify-between">
                    <p className="mb-1 font-ano font-normal text-lg text-white">
                      Enter the OTP code
                    </p>
                    {otpError && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {otpError}
                      </div>
                    )}
                  </div>
                  <div className="relative">
                    <input
                      type="number"
                      className="bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 lg:w-[510px] w-full focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                      placeholder="OTP"
                      maxLength={6}
                      value={otp}
                      onChange={(e) => setOtp(e.target.value)}
                    />

                    <p
                      onClick={resendOtp}
                      className="absolute cursor-pointer lg:top-2.5 top-1.5 right-5 font-ano text-[#676767] font-bold lg:text-2xl text-lg"
                    >
                      {!disableResend ? <>Resend OTP</> : <>{secondsLeft}s</>}
                    </p>
                  </div>
                </div>
                <div
                  onClick={continueVerification}
                  className="w-[210px] lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-[22px] text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                >
                  {!isLoadingOtpVerfiy && <>Verify</>}
                  {isLoadingOtpVerfiy && (
                    <ThreeDots
                      height={20}
                      width={20}
                      color="#ffffff"
                      wrapperStyle={{}}
                      wrapperClass="justify-center py-1"
                      visible={true}
                      ariaLabel="oval-loading"
                    />
                  )}
                </div>
              </div>
            )}
            {showPrivacyPolicy && !isUplandConnected && !verificationData && !emailCard ? (
              <div className="w-full px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-[#4F99F0] text-center mb-6">
                  Privacy policy
                </p>
                <div className="lg:w-auto w-full">
                  <p className="h-56 font-ano font-normal lg:text-2xl text-base text-white overflow-y-scroll custom-scrollbar">
                    Lorem Ipsum is simply dummy text of the printing and
                    typesetting industry. Lorem Ipsum has been the industry's
                    standard dummy text ever since the 1500s, when an unknown
                    printer took a galley of type and scrambled it to make a
                    type specimen book. It has survived not only five centuries,
                    but also the leap into electronic typesetting, remaining
                    essentially unchanged. It was popularised in the 1960s with
                    the release Lorem Ipsum is simply dummy text of the printing
                    and typesetting industry. Lorem Ipsum has been the
                    industry's standard dummy text ever since the 1500s, when an
                    unknown printer took a galley of type and scrambled it to
                    make a type specimen book. It has survived not only five
                    centuries, but also the leap into electronic typesetting,
                    remaining essentially unchanged. It was popularised in the
                    1960s with the release Lorem Ipsum is simply dummy text of
                    the printing and typesetting industry. Lorem Ipsum has been
                    the industry's standard dummy text ever since the 1500s,
                    when an unknown printer took a galley of type and scrambled
                    it to make a type specimen book. It has survived not only
                    five centuries, but also the leap into electronic
                    typesetting, remaining essentially unchanged. It was
                    popularised in the 1960s with the release
                  </p>
                  <p className="font-ano font-bold lg:text-xl text-base mt-8 text-white">
                    By clicking the 'Accept' button, you agree to our use of
                    cookies and data as outlined in our Privacy Policy.
                  </p>
                </div>
                <div
                  onClick={acceptHandler}
                  className="w-[210px] lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold lg:text-[22px] text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                >
                  {!isLoadingSignupApi && <>I Accept</>}
                  {isLoadingSignupApi && (
                    <ThreeDots
                      height={20}
                      width={20}
                      color="#ffffff"
                      wrapperStyle={{}}
                      wrapperClass="justify-center py-1"
                      visible={true}
                      ariaLabel="oval-loading"
                    />
                  )}
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
