import React, { useState, useEffect } from 'react';

type ProgressBarProps = {
  remainingSparkHours: number;
  requiredSparkHours: number;
};

const SparkProgressBar: React.FC<ProgressBarProps> = ({ remainingSparkHours, requiredSparkHours }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (requiredSparkHours <= 0) {
      setProgress(0);
      return;
    }

    const progressPercentage = (((requiredSparkHours - remainingSparkHours) * 100) / requiredSparkHours);
    setProgress(progressPercentage);
  }, [remainingSparkHours, requiredSparkHours]);

  return (
    <div className="w-full h-2 bg-gray-600 rounded-full overflow-hidden">
      <div
        className="bg-blue-500 h-full"
        style={{ width: `${progress}%` }}
      ></div>
    </div>
  );
};

export default SparkProgressBar;
