import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

interface Props {
  isOpen: boolean;
  isLogout?: boolean;
  onCancel: () => void;
  onAction: () => void;
}

export default function DisconnectModal({ isOpen, onCancel, onAction, isLogout=false  }: Props) {
  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <div className="fixed inset-0 bg-[#000000DE] transition-opacity" />

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-[#1C1C1C] text-left shadow-xl transition-all lg:w-[550px] w-auto lg:h-72 h-auto flex justify-center">
                <div className="py-10 lg:px-6 px-20 flex flex-col items-center justify-center">
                  <p className="font-ano text-2xl text-white">
                    Are you sure you want to {isLogout ? 'logout' : 'disconnect'}
                  </p>
                  <div className="flex items-center space-x-3">
                    <button
                      onClick={onAction}
                      type="button"
                      className="w-[180px] lg:mt-8 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#FF6F6F] border-[#FF6F6F] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                    >
                      Yes
                    </button>

                    <button
                      onClick={onCancel}
                      type="button"
                      className="w-[180px] lg:mt-8 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                    >
                      No
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
