import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";

interface IAdvertisementForm {
  fullName: string;
  email: string;
  message: string;
  image: string;
}

interface IFormErrors {
  fullName: string;
  email: string;
  message: string;
  image: string;
}

export function Advertisement() {
  const { user } = useAuth();
  const notify = useNotification();
  const [imageURL, setImageURL] = useState("");
  const [imageName, setImageName] = useState("");

  const {
    sendRequest: sendAdvertisementApi,
    isLoading: isLoadingAdvertisementApi,
  } = useHttp();

  const [advertisementData, setAdvertisementData] =
    useState<IAdvertisementForm>({
      fullName: "",
      email: "",
      message: "",
      image: "",
    });
  const [errors, setErrors] = useState<IFormErrors>({
    fullName: "",
    email: "",
    message: "",
    image: "",
  });

  const [isFeedbackPresent, setIsFeedbackPresent] = useState(false);

  const {
    sendRequest: uploadImageApiHandler,
    isLoading: isLoadingUploadImageApi,
  } = useHttp();

  const handleFileSelect = () => {
    const fileInput = document.getElementById("fileInput") as HTMLInputElement;
    fileInput.click();
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files ? event.target.files[0] : null;
    if (!file) {
      return;
    }

    const formData = new FormData();
    formData.append("image", file);
    setImageName(file.name);


    uploadImageApiHandler(
      {
        url: "api/upload-image",
        method: "POST",
        payload: formData,
        headers: {
					'Content-Type': `multipart/form-data`,
				}
      },
      (data: any) => {
        if (data?.location) {
          setImageURL(data.location);
        }
      }
    );
  };

  const validateForm = (): boolean => {
    let isValid = true;
    let newErrors: IFormErrors = {
      fullName: "",
      email: "",
      message: "",
      image: "",
    };

    if (!advertisementData.fullName) {
      newErrors.fullName = "Full name is required";
      isValid = false;
    }
    if (!advertisementData.email) {
      newErrors.email = "Email is required";
      isValid = false;
    }
    if (!advertisementData.message) {
      newErrors.message = "Message is required";
      isValid = false;
    }
    // if (!advertisementData.image) {
    //   newErrors.image = "Image is required";
    //   isValid = false;
    // }

    setErrors(newErrors);
    return isValid;
  };

  const sendAdvertisementFormHandler = () => {
    if (!validateForm()) {
      return; // Stop the function if the form is not valid
    }
    
    sendAdvertisementApi(
      {
        url: "api/advertisement",
        method: "POST",
        payload: JSON.stringify({
          fullName: advertisementData.fullName,
          email: advertisementData.email,
          message: advertisementData.message,
          image: imageURL,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
          setIsFeedbackPresent(true);
          setAdvertisementData({
            fullName: "",
            email: "",
            message: "",
            image: "",
          });
          setImageName("");
          setImageURL("");
        }
      }
    );
  };

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <Header />
        <div className="2xl:px-14 xl:px-32 lg:px-10 md:px-0 px-5">
          <div className="lg:my-0 my-10 lg:flex w-full lg:items-center lg:justify-center flex-col">
            <p className="font-ano font-bold text-4xl text-white text-center mb-8 uppercase">
              Advertisement Form
            </p>

            <p className="font-ano font-normal text-xl text-white text-center mb-8">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
            <div className="flex flex-col space-y-6 lg:w-4/6 w-full lg:mx-auto">
              <div className="flex flex-col bg-[#1C1C1C] rounded-2xl py-5 px-10">
                <div className="flex lg:flex-row flex-col lg:items-center lg:justify-between lg:space-x-4">
                  <div className="w-full">
                    <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                      Full Name
                    </p>
                    <input
                      type="text"
                      placeholder="Your name"
                      className="bg-[#1C1C1C] py-1 lg:px-5 px-4 w-full font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[30px] outline-none"
                      value={advertisementData.fullName}
                      onChange={(e) =>
                        setAdvertisementData({
                          ...advertisementData,
                          fullName: e.target.value,
                        })
                      }
                    />

                    {errors.fullName && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {errors.fullName}
                      </div>
                    )}
                  </div>
                  <div className="w-full lg:mt-0 mt-4">
                    <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                      Email Address
                    </p>
                    <input
                      placeholder="abc@xyz.com"
                      type="text"
                      className="bg-[#1C1C1C] py-1 lg:px-5 px-4 w-full font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[30px] outline-none"
                      value={advertisementData.email}
                      onChange={(e) =>
                        setAdvertisementData({
                          ...advertisementData,
                          email: e.target.value,
                        })
                      }
                    />

                    {errors.email && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {errors.email}
                      </div>
                    )}
                  </div>
                </div>

                <div className="flex flex-col space-y-2 mt-4">
                  <div className="w-full">
                    <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                      Message
                    </p>
                    <textarea
                      placeholder="your message here..."
                      className="bg-[#1C1C1C] h-36 py-1 lg:px-5 px-4 w-full font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[30px] outline-none"
                      value={advertisementData.message}
                      onChange={(e) =>
                        setAdvertisementData({
                          ...advertisementData,
                          message: e.target.value,
                        })
                      }
                    ></textarea>

                    {errors.message && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {errors.message}
                      </div>
                    )}
                  </div>
                </div>

                <input
                  type="file"
                  id="fileInput"
                  style={{ display: "none" }}
                  onChange={handleFileChange}
                />

                <div className="bg-[#1C1C1C] flex items-center justify-between mt-4 py-2 lg:px-3 px-4 w-full font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[30px] outline-none">
                  <div className="flex items-center space-x-4">
                    <p
                      onClick={handleFileSelect}
                      className="font-ano font-bold cursor-pointer text-lg text-white py-2 px-5 rounded-[50px] border-2 border-[#3E7EFF]"
                    >
                      Select File
                    </p>
                    <p className="lg:block hidden font-ano font-bold text-xl text-[#676767]">
                      {imageName || "Upload an image"}
                    </p>
                  </div>
                  <p className="font-ano font-bold text-lg text-white">
                    {imageName || "No file selected"}
                  </p>
                </div>

                <div
                  className={`flex items-center ${
                    isFeedbackPresent ? "justify-between" : "justify-end"
                  } mt-6`}
                >
                  {isFeedbackPresent && (
                    <p className="font-ano font-bold lg:text-2xl text-base text-[#1EA067]">
                      THANK YOU FOR YOUR INTEREST
                    </p>
                  )}
                  <button
                    onClick={sendAdvertisementFormHandler}
                    type="button"
                    className="lg:w-[210px] w-full cursor-pointer border-2 rounded-[30px] border-[#1EA067] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Send
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
