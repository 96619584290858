import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context";
import { LockClosedIcon } from "@heroicons/react/24/outline";

interface Transaction {
  container_id: string;
  created_at: string;
  destination_username: string;
  destination_wallet: string;
  initiated_by: string;
  memo: string;
  source_username: string;
  source_wallet: string;
  status: string;
  transaction_id: string;
  type: string;
  updated_at: string;
  upland_username: string;
  upx_amount: string;
}

export default function AdminEditTransaction() {
  const navigate = useNavigate();
  const { authToken, user } = useAuth();
  const { txn_id } = useParams<string>();
  const notify = useNotification();

  const [txnTime, setTxnTime] = useState("");

  const [selectedTxn, setSelectedTxn] = useState<Transaction | null>(null);
  const { sendRequest: getTxnByIdApi, isLoading: isLoadingTxnApi } = useHttp();

  const { sendRequest: saveRewardApi, isLoading: isLoadingSaveTxnApi } =
    useHttp();

  const getTxnById = () => {
    getTxnByIdApi(
      {
        url: `api/txn/edit/${txn_id}`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        console.log("data", data);

        setSelectedTxn(data.txn || null);
      }
    );
  };

  useEffect(() => {
    if (selectedTxn) {
      setTxnTime(
        new Date(selectedTxn?.created_at || "").toISOString().split("T")[0] ||
          ""
      );
    }
  }, [selectedTxn]);

  useEffect(() => {
    console.log("txn_id", txn_id);
    if (txn_id) getTxnById();
  }, [txn_id]);

  return (
    <div className="bg-white w-full px-14 pt-10">
      <div className="flex items-center space-x-3">
        <svg
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="5" fill="#1C1C1C" />
          <path
            d="M19.2002 22.4004L12.8002 16.0004L19.2002 9.60039"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>

        <p className="font-ano font-bold text-3xl text-black">Transaction</p>
      </div>

      {/* <div className="relative rounded-md mt-5">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          className="rounded-full w- border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
          placeholder="Search"
        />
      </div> */}

      <div className="w-full mt-10">
        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="txnId"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Transaction ID
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="txnId"
                id="txnId"
                value={selectedTxn?.transaction_id}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
          <div className="w-full">
            <label
              htmlFor="username"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Username
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="username"
                id="username"
                value={selectedTxn?.upland_username}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="upxAmount"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Upx amount
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="upxAmount"
                id="upxAmount"
                value={selectedTxn?.upx_amount}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="uplandTax"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Upland Tax
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="uplandTax"
                id="uplandTax"
                // value={Number(selectedTxn?tax)}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="upxplorerTax"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              UpxPlorer Tax
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="upxplorerTax"
                id="upxplorerTax"
                // value={selectedTxn?.}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="type"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Type
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="type"
                id="type"
                value={selectedTxn?.type}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="memo"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Memo
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="memo"
                id="memo"
                disabled
                value={selectedTxn?.memo}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="source"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Source
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="source"
                id="source"
                disabled
                value={selectedTxn?.source_username}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="destination"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Destination
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="destination"
                id="destination"
                disabled
                value={selectedTxn?.destination_username}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="status"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Status
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="status"
                id="status"
                disabled
                value={selectedTxn?.status}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="createdAt"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Transaction Time
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="createdAt"
                id="createdAt"
                disabled
                value={txnTime}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full"></div>
        </div>

        {/* <div className="mt-10">
          <button
            type="button"
            className="lg:w-[150px] w-full cursor-pointer border-2 rounded-[50px] bg-[#2D6BE3] border-[#2D6BE3] font-ano font-bold lg:text-lg text-sm text-center py-1 uppercase text-white outline-none"
          >
            {!isLoadingSaveTxnApi && <>Save</>}
            {isLoadingSaveTxnApi && (
              <ThreeDots
                height={20}
                width={20}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass="justify-center py-1"
                visible={true}
                ariaLabel="oval-loading"
              />
            )}
          </button>
        </div> */}
      </div>
    </div>
  );
}
