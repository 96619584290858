import React from "react";
import AdminSidebar from "../components/AdminSidebar";
import { Outlet } from "react-router-dom";
import AdminHeader from "../components/AdminHeader";

const AdminLayout = () => (
  <>
    <div className="flex flex-col h-full">
      <AdminHeader />
      <div className="flex flex-grow w-full">
        <AdminSidebar />
        <Outlet />
      </div>
    </div>
  </>
);

export default AdminLayout;
