import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";
import UserPlaceholder from "../assets/images/user-placeholder.svg";
import WalletIcon from "../assets/images/wallet-icon.svg";
import SparkIcon from "../assets/images/spark_icon.svg";
import UpxIcon from "../assets/images/upx_icon.svg";
import UserIcon from "../assets/images/UserIcon.svg";
import PencilIcon from "../assets/images/pencil.svg";
import DisconnectIcon from "../assets/images/disconnect.svg";
import { ThreeDots } from "react-loader-spinner";
import { useNavigate } from "react-router-dom";
import DisconnectModal from "../components/DisconnectModal";

interface UplandAuthData {
  code: string;
  expireAt: string;
}

interface FormValues {
  fullName: string;
  email: string;
  discordId?: string;
}

const initialState: FormValues = {
  fullName: "",
  email: "",
  discordId: ""
};

export function UserProfile() {
  const notify = useNotification();
  const navigate = useNavigate();
  const { authToken, user, updateAuthToken, setUser } = useAuth();

  const [isEditEnabled, setIsEditEnabled] = useState(false);
  const [showDisconnectModal, setShowDisconnectModal] = useState(false);

  const [uplandAuthData, setUplandAuthData] = useState<UplandAuthData | null>(
    null
  );

  const [formValues, setFormValues] = useState<FormValues>(initialState);
  const [errors, setErrors] = useState<FormValues>(initialState);

  const isRequired = (value: string) => value.trim() === "";
  const isValidEmail = (value: string) =>
    /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(value);

  const { sendRequest: continueSignupApi, isLoading } = useHttp();
  const { sendRequest: getUserApi, isLoading: isLoadingGetUserApi } = useHttp();

  const {
    sendRequest: getUplandUserApi,
    isLoading: isLoadingGetUpalndUserApi
  } = useHttp();

  const { sendRequest: saveUserFormApi, isLoading: isLoadingSaveUserFormApi } =
    useHttp();

  const {
    sendRequest: disconnectUplandUserApi,
    isLoading: isLoadingDisconnectUplandUserApi
  } = useHttp();

  useEffect(() => {
    setFormValues({
      fullName: user?.full_name || "",
      email: user?.userEmail || "",
      discordId: user?.discord_id || ""
    });
  }, [user]);

  // useEffect(() => {
  //   if (!authToken) {
  //     navigate("/home");
  //   }
  // }, [authToken]);

  useEffect(() => {
    const getUplandUserHandler = () => {
      getUplandUserApi(
        {
          url: "api/upland-user",
          method: "POST",
          payload: JSON.stringify({
            email: user?.email_address,
            accessToken: user?.upland_auth_token
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: user?.upland_auth_token
              ? user?.upland_auth_token
              : ""
          }
        },
        (data: any) => {
          // setUser(data);
        }
      );
    };

    if (user?.upland_auth_token) {
      getUplandUserHandler();
    }
  }, [user?.upland_auth_token]);

  const editEnableHandler = () => {
    setIsEditEnabled(true);
  };

  const handleInputChange =
    (field: keyof FormValues) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value;
      setFormValues((prev) => ({ ...prev, [field]: newValue }));
    };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    let newErrors: FormValues = { ...initialState };

    if (isRequired(formValues.fullName)) {
      newErrors.fullName = "Name is required.";
    }

    if (!isValidEmail(formValues.email)) {
      newErrors.email = "Invalid email format.";
    }

    setErrors(newErrors);

    const isValid = Object.values(newErrors).every((error) => error === "");
    if (isValid) {
      console.log("Form is valid! Submitting:", formValues);
      saveUserFormApi(
        {
          url: "api/user",
          method: "POST",
          payload: JSON.stringify(formValues),
          headers: {
            "Content-Type": "application/json"
          }
        },
        (data: any) => {
          if (data?.error) {
            notify(data?.error, "error");
            console.log("Message", data?.error);
            return;
          }
          if (data?.message) {
            notify(data?.message, "success");
            console.log("Message", data?.message);
          }
          setIsEditEnabled(false);
          // setShowEmailCard(true);
          // setVerificationData(data);
        }
      );
    }
  };

  const disconnectUplandUser = () => {
    if (!user?.upland_user_id) return;
    disconnectUplandUserApi(
      {
        url: "api/disconnect-upland",
        method: "POST",
        payload: JSON.stringify({ userId: user?.upland_user_id }),
        headers: {
          "Content-Type": "application/json"
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
          setShowDisconnectModal(false);
          console.log("Message", data?.message);
        }

        getUserApi(
          {
            url: "api/user",
            method: "GET",
            payload: {},
            headers: {
              "Content-Type": "application/json",
              Authorization: authToken || ""
            }
          },
          (data: any) => {
            if (data?.error) {
              notify(data?.error, "error");
              console.log("Message", data?.error);
              localStorage.removeItem("authToken");
              updateAuthToken("");
              setUser(null);
              return;
            }
            setUser(data);
          }
        );
        console.log(data);
      }
    );
  };

  function formatNumberToTwoDecimals(value: any, place = 2) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    // Format number to fixed decimal places
    let formattedValue = Number(value).toFixed(place);
    // Remove unnecessary trailing zeros and decimal point if not needed
    return formattedValue.replace(/(\.\d*?[1-9])0+$|\.0*$/, "$1");
  }

  return (
    <>
      <DisconnectModal
        isOpen={showDisconnectModal}
        onAction={disconnectUplandUser}
        onCancel={() => setShowDisconnectModal(false)}
      />
      <div className="flex flex-col justify-between h-full">
        <Header />
        <div className="flex flex-col my-10">
          <p className="font-ano font-bold text-4xl text-white text-center mb-8">
            My Profile
          </p>
          <div className="lg:px-20 px-6">
            <div className="flex flex-col space-y-6">
              <div className="flex lg:flex-row flex-col lg:justify-between lg:space-x-10 bg-[#1C1C1C] rounded-2xl lg:py-5 py-4 lg:px-10 px-5">
                <div className="flex items-center lg:space-x-6">
                  <div>
                    <img
                      src={
                        user?.avatar_url ? user?.avatar_url : UserPlaceholder
                      }
                    />
                  </div>
                  <div className="flex flex-col lg:space-x-0 space-x-10">
                    <p className="lg:ml-0 ml-10 font-ano font-normal text-lg text-[#999999] mb-2">
                      Upland Username
                    </p>
                    <p className="fotn-ano font-bold text-2xl text-white">
                      {user?.upland_username || "---"}
                    </p>

                    <div className="my-4 bg-[#3E7EFF] font-ano font-bold text-[10px] p-1 w-16 rounded text-center text-white">
                      Verified
                    </div>

                    <p className="font-ano font-normal text-lg text-[#999999] mb-2">
                      eosId
                    </p>
                    <div className="flex items-center space-x-2">
                      <a target="_blank" href={`https://bloks.io/account/${user?.upland_eos_id}`} className="fotn-ano font-bold text-2xl text-white hover:text-[#3E7EFF] cursor-pointer">
                        {user?.upland_eos_id || "---"}
                      </a>

                      {user?.upland_auth_token ? (
                        <img
                          onClick={() => setShowDisconnectModal(true)}
                          src={DisconnectIcon}
                          className="cursor-pointer"
                        />
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="flex flex-col lg:items-end">
                  <div className="flex items-center lg:justify-end space-x-4 lg:mt-0 mt-10 mb-3">
                    <p className="font-ano font-bold text-2xl text-white uppercase">
                      My wallet
                    </p>
                    <img src={WalletIcon} />
                  </div>

                  <div className="flex lg:flex-row flex-col lg:items-center lg:justify-between lg:space-x-6">
                    <div className="flex lg:flex-col flex-row lg:justify-normal justify-between lg:space-y-0 space-y-4 lg:items-end">
                      <div className="flex items-center space-x-2">
                        <p className="font-ano font-bold text-lg text-[#999999]">
                          AVAILABLE SPARK
                        </p>
                        <img src={SparkIcon} />
                      </div>
                      <p className="font-ano font-bold text-2xl text-white">
                        {user?.uplandBalance?.availableSpark !== null &&
                        user?.uplandBalance?.availableSpark !== undefined
                          ? formatNumberToTwoDecimals(user.uplandBalance.availableSpark)
                          : "---"}
                      </p>
                    </div>

                    <div className="flex lg:flex-col flex-row lg:justify-normal justify-between lg:space-y-0 space-y-4 lg:items-end">
                      <div className="flex items-center space-x-2">
                        <p className="font-ano font-bold text-lg text-[#999999]">
                          STAKED SPARK
                        </p>
                        <img src={SparkIcon} />
                      </div>
                      <p className="font-ano font-bold text-2xl text-white">
                        {user?.uplandBalance?.stakedSpark !== null &&
                        user?.uplandBalance?.stakedSpark !== undefined
                          ? formatNumberToTwoDecimals(user.uplandBalance.stakedSpark)
                          : "---"}
                      </p>
                    </div>

                    <div className="flex lg:flex-col flex-row lg:justify-normal justify-between lg:space-y-0 space-y-4 lg:items-end">
                      <div className="flex items-center space-x-2">
                        <p className="font-ano font-bold text-lg text-[#999999]">
                          UPXPLORER UPX
                        </p>
                        <img src={UpxIcon} />
                      </div>
                      <p className="font-ano font-bold text-2xl text-white">
                        {user?.walletUpxBalance !== null &&
                        user?.walletUpxBalance !== undefined
                          ? user.walletUpxBalance
                          : "---"}
                      </p>
                    </div>
                  </div>

                  {!user?.upland_auth_token ? (
                    <button
                      onClick={() => navigate("/connect-upland")}
                      type="button"
                      className="lg:w-[180px] w-full lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold lg:text-lg text-sm text-center py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                    >
                      Connect upland
                    </button>
                  ) : (
                    <div className="flex lg:flex-row flex-col lg:items-center lg:space-x-4">
                      <button
                        onClick={() => navigate("/deposit")}
                        type="button"
                        className="lg:w-[150px] w-full lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-lg text-sm text-center py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                      >
                        Deposit Upx
                      </button>
                      <button
                        onClick={() => navigate("/withdraw")}
                        type="button"
                        className="lg:w-[150px] w-full lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-lg text-sm text-center py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                      >
                        Withdraw UPX
                      </button>

                      <button
                        onClick={() => navigate("/transfer")}
                        type="button"
                        className="lg:w-[150px] w-full lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-lg text-sm text-center py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                      >
                        Transfer Upx
                      </button>
                      <button
                        onClick={() => navigate("/transactions")}
                        type="button"
                        className="lg:w-[150px] w-full lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-lg text-sm text-center py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                      >
                        Transactions
                      </button>
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-col bg-[#1C1C1C] rounded-2xl lg:py-5 py-4 lg:px-10 px-6">
                <div className="flex items-center justify-between">
                  <div className="flex items-center space-x-4">
                    <img src={UserIcon} />
                    <p className="font-ano font-bold text-2xl text-white">
                      User Details
                    </p>
                  </div>

                  {!isEditEnabled && (
                    <div
                      className="flex items-center space-x-3 cursor-pointer"
                      onClick={editEnableHandler}
                    >
                      <p className="font-ano font-bold text-lg uppercase text-white">
                        Edit
                      </p>
                      <img src={PencilIcon} />
                    </div>
                  )}
                </div>

                <div className="grid lg:grid-cols-3 grid-cols-1 lg:gap-10 mt-5">
                  <div className="flex lg:mb-0 mb-5">
                    <div>
                      <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                        Name
                      </p>
                      {isEditEnabled ? (
                        <>
                          <input
                            value={formValues.fullName}
                            onChange={handleInputChange("fullName")}
                            type="text"
                            className="lg:w-auto w-full bg-[#1C1C1C] py-1 lg:px-5 px-4  font-ano text-white font-bold lg:text-2xl text-lg focus:border-[#3E7EFF] border-2 border-white rounded-[50px] outline-none"
                          />
                          {errors.fullName && (
                            <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                              {errors.fullName}
                            </div>
                          )}
                        </>
                      ) : (
                        <p className="font-ano font-bold text-2xl text-white truncate">
                          {formValues.fullName || "---"}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="flex lg:mb-0 mb-5">
                    <div>
                      <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                        Email
                      </p>
                      {isEditEnabled ? (
                        <>
                          <input
                            value={formValues.email}
                            onChange={handleInputChange("email")}
                            type="text"
                            className="lg:w-auto w-full bg-[#1C1C1C] py-1 lg:px-5 px-4  font-ano text-white font-bold lg:text-2xl text-lg focus:border-[#3E7EFF] border-2 border-white rounded-[50px] outline-none"
                          />
                          {errors.email && (
                            <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                              {errors.email}
                            </div>
                          )}
                        </>
                      ) : (
                        <p className="font-ano font-bold text-2xl text-white truncate">
                          {formValues.email || "---"}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="flex lg:mb-0 mb-5">
                    <div>
                      <p className="font-ano font-normal text-[#999999] text-lg mb-1">
                        Discord ID
                      </p>
                      {isEditEnabled ? (
                        <input
                          value={formValues.discordId}
                          onChange={handleInputChange("discordId")}
                          type="text"
                          className="lg:w-auto w-full bg-[#1C1C1C] py-1 lg:px-5 px-4  font-ano text-white font-bold lg:text-2xl text-lg focus:border-[#3E7EFF] border-2 border-white rounded-[50px] outline-none"
                        />
                      ) : (
                        <p className="font-ano font-bold text-2xl text-white truncate">
                          {formValues.discordId || "---"}
                        </p>
                      )}
                    </div>
                  </div>
                </div>

                {isEditEnabled && (
                  <div className="flex">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="w-[210px] lg:mt-12 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                    >
                      {!isLoadingSaveUserFormApi && <>Save</>}
                      {isLoadingSaveUserFormApi && (
                        <ThreeDots
                          height={20}
                          width={20}
                          color="#ffffff"
                          wrapperStyle={{}}
                          wrapperClass="justify-center py-1"
                          visible={true}
                          ariaLabel="oval-loading"
                        />
                      )}
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
