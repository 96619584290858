import { Routes, Route, Navigate } from "react-router-dom";
import {
  UserProfile,
  Home,
  Signup,
  Login,
  ConnectUpland,
  Deposit,
  DirectDeposit,
  Withdraw,
  Transactions,
  SparkDashboard,
  Properties,
  PropertyById,
  Contracts,
  ContractById,
  SparkListing,
  SparkCalculate,
  UpxDashboard,
  ActiveContracts,
  StakedContracts,
  TermsAndCondition,
  Faq,
  RewardHistory,
  Transfer
} from "../pages";
import { ProtectedRoute } from "./ProtectedRoute";
import { ContactUs } from "../pages/ContactUs";
import { Advertisement } from "../pages/Advertisement";
import { useAuth } from "../context";
import { ReactNode } from "react";
import { Maintenance } from "../pages/Maintenance";
import MainLayout from "../layouts/MainLayout";
import AdminLayout from "../layouts/AdminLayout";
import AdminHome from "../pages/admin/AdminHome";
import AdminUsers from "../pages/admin/AdminUsers";
import AdminEditUser from "../pages/admin/AdminEditUser";
import AdminSparkContracts from "../pages/admin/AdminSparkContracts";
import AdminTransactions from "../pages/admin/AdminTransactions";
import AdminBuildUnderConstruction from "../pages/admin/AdminBuildUnderConstruction";
import AdminHourlyRewards from "../pages/admin/AdminHourlyRewards";
import AdminEditSparkContracts from "../pages/admin/AdminEditSparkContracts";
import AdminEditHourlyReward from "../pages/admin/AdminEditHourlyReward";
import AdminEditBuilds from "../pages/admin/AdminEditBuilds";
import AdminEditTransaction from "../pages/admin/AdminEditTransaction";
import { ShareholdersExchange } from "../pages/ShareholdersExchange";
import { AdminProtectedRoute } from "./AdminProtectedRoute";
import AdminChangeFee from "../pages/admin/AdminChangeFee";
import AdminRewardUpx from "../pages/admin/AdminRewardUpx";
import AdminDebitUpx from "../pages/admin/AdminDebitUpx";
import { ShareHistory } from "../pages/ShareHistory";
import AdminShareholders from "../pages/admin/AdminShareholders";
import AdminShareMarket from "../pages/admin/AdminShareMarket";
import AdminShareOffers from "../pages/admin/AdminShareOffers";
import AdminShareTransactions from "../pages/admin/AdminShareTransactions";
import AdminAddShareholder from "../pages/admin/AdminAddShareholder";
export default function AppRouter() {
  return (
    <Routes>
      <Route path="/" element={<MainLayout />}>
        <Route index element={<Navigate to="/home" replace />} />

        <Route
          path="signup"
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />
        <Route
          path="login"
          element={
            <PublicRoute>
              <Signup />
            </PublicRoute>
          }
        />
        <Route path="contact-us" element={<ContactUs />} />
        <Route path="advertisement" element={<Advertisement />} />
        <Route
          path="home"
          element={
            // <ProtectedRoute>
            // <Home />
            <ActiveContracts />
            // </ProtectedRoute>
          }
        />

        {/* <Route
        path="/"
        element={
          // <ProtectedRoute>
          <Maintenance />
          // </ProtectedRoute>
        }
      /> */}

        <Route
          path="/connect-upland"
          element={
            <ProtectedRoute>
              <ConnectUpland />
            </ProtectedRoute>
          }
        />
        <Route
          path="/deposit"
          element={
            <ProtectedRoute>
              <Deposit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/direct-deposit"
          element={
            <ProtectedRoute>
              <DirectDeposit />
            </ProtectedRoute>
          }
        />
        <Route
          path="/transfer"
          element={
            <ProtectedRoute>
              <Transfer />
            </ProtectedRoute>
          }
        />
        <Route
          path="/withdraw"
          element={
            <ProtectedRoute>
              <Withdraw />
            </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard"
          element={
            // <ProtectedRoute>
            <SparkDashboard />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/upx-dashboard"
          element={
            // <ProtectedRoute>
            <UpxDashboard />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/active-builds"
          element={
            // <ProtectedRoute>
            <Properties />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/active-builds/:nftId"
          element={
            // <ProtectedRoute>
            <PropertyById />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/spark-calculate/:sparkContractId"
          element={
            // <ProtectedRoute>
            <SparkCalculate />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/spark-listing"
          element={
            // <ProtectedRoute>
            <SparkListing />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/dashboard/active-contracts"
          element={
            // <ProtectedRoute>
            <Contracts />
            // </ProtectedRoute>
          }
        />
        {/* <Route
        path="/active-contracts"
        element={
          // <ProtectedRoute>
          <ActiveContracts />
          // </ProtectedRoute>
        }
      /> */}
        <Route
          path="/upx-dashboard/staked-contracts"
          element={
            // <ProtectedRoute>
            <StakedContracts />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/contracts/:sparkContractId"
          element={
            // <ProtectedRoute>
            <ContractById />
            // </ProtectedRoute>
          }
        />
        <Route
          path="/transactions"
          element={
            <ProtectedRoute>
              <Transactions />
            </ProtectedRoute>
          }
        />
        <Route
          path="/reward-history"
          element={
            <ProtectedRoute>
              <RewardHistory />
            </ProtectedRoute>
          }
        />
        <Route
          path="/profile"
          element={
            <ProtectedRoute>
              <UserProfile />
            </ProtectedRoute>
          }
        />
        <Route
          path="/terms-and-condition"
          element={
            <ProtectedRoute>
              <TermsAndCondition />
            </ProtectedRoute>
          }
        />
        <Route path="/faq" element={<Faq />} />

        <Route
          path="/exchange"
          element={
            <ProtectedRoute>
              <ShareholdersExchange />
            </ProtectedRoute>
          }
        />

        <Route
          path="/share-history"
          element={
            <ProtectedRoute>
              <ShareHistory />
            </ProtectedRoute>
          }
        />
      </Route>

      {/* Admin Routes */}
      <Route
        path="/admin"
        element={
          <AdminProtectedRoute>
            <AdminLayout />
          </AdminProtectedRoute>
        }
      >
        <Route index element={<AdminHome />} />
        <Route path="users" element={<AdminUsers />} />
        <Route path="user/:email_address" element={<AdminEditUser />} />
        <Route path="spark-contracts" element={<AdminSparkContracts />} />
        <Route
          path="spark-contracts/:spark_contract_id"
          element={<AdminEditSparkContracts />}
        />
        <Route path="hourly-rewards" element={<AdminHourlyRewards />} />
        <Route
          path="hourly-reward/:reward_id"
          element={<AdminEditHourlyReward />}
        />
        <Route
          path="builds-under-construction"
          element={<AdminBuildUnderConstruction />}
        />
        <Route path="build/:nft_id" element={<AdminEditBuilds />} />
        <Route path="transactions" element={<AdminTransactions />} />
        <Route path="transaction/:txn_id" element={<AdminEditTransaction />} />
        <Route path="change-fee" element={<AdminChangeFee />} />
        <Route path="reward-upx" element={<AdminRewardUpx />} />
        <Route path="debit-upx" element={<AdminDebitUpx />} />
        <Route path="shareholders" element={<AdminShareholders />} />
        <Route path="add-shareholder" element={<AdminAddShareholder />} />
        <Route path="share-market" element={<AdminShareMarket />} />
        <Route path="share-offers" element={<AdminShareOffers />} />
        <Route path="share-transactions" element={<AdminShareTransactions />} />
      </Route>
    </Routes>
  );
}

interface PublicRouteProps {
  children: ReactNode;
}

const PublicRoute: React.FC<PublicRouteProps> = ({ children }) => {
  const { user } = useAuth();

  if (user) {
    return <Navigate to="/home" />; // Redirect to home if user is logged in
  }

  return <>{children}</>; // Render children (Login or Signup) if not logged in
};
