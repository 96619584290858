import React, { ChangeEvent, useEffect, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";
import Header from "../components/Header";
import Footer from "../components/Footer";

import InfoIcon from "../assets/images/info.svg";
import DirectDepositIcon from "../assets/images/direct-deposit.svg";
import UpxIcon from "../assets/images/upx_icon.svg";
import SearchIcon from "../assets/images/search_icon.svg";
import CopyIcon from "../assets/images/copy.svg";
import TellMeHow from "../components/TellMeHow";
import { useNavigate } from "react-router-dom";
import { MainHeading } from "../common/components/MainHeading";
import CityListModal from "../components/CityListModal";
import { useAuth } from "../context";
import DepositModal from "../components/DepositModal";
import { ThreeDots } from "react-loader-spinner";

interface City {
  address: string;
  city: string;
}

export function Deposit() {
  const navigate = useNavigate();
  const notify = useNotification();

  const { user, getUser, authToken } = useAuth();
  const [amount, setAmount] = useState("");
  const [containerId, setContainerId] = useState("");
  const [showCityModal, setShowCityModal] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showConfirmDeposit, setShowConfirmDeposit] = useState(false);
  const [showVerifyDeposit, setShowVerifyDeposit] = useState(false);
  const [showSuccessDeposit, setShowSuccessDeposit] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [uplandTax, setUplandTax] = useState<number>(0);
  const [upxplorerTax, setUpxplorerTax] = useState<number>(0);
  const [finalAmount, setFinalAmount] = useState<number>(0);
  const [isVerified, setIsVerified] = useState(false);

  const [showTellMeModal, setShowTellMeModal] = useState(false);

  const allCities: City[] = [
    {
      address: "631 S AKARD ST",
      city: "Dallas"
    },
    {
      address: "31 MITTELSTRASSE",
      city: "Berlin"
    },
    {
      address: "45B LOWER THAMES ST",
      city: "London"
    },
    {
      address: "RUA FIGUEIREDO DE MAGALHAES, 263",
      city: "Rio de Janeiro"
    },
    {
      address: "7405 N FRESNO ST",
      city: "Fresno"
    },
    {
      address: "8744 APPERSON ST",
      city: "Los Angeles"
    },
    {
      address: "103 PORTALES REAL",
      city: "Bakersfield"
    },
    {
      address: "801 2ND AVE",
      city: "Manhattan"
    },
    {
      address: "AVENIDA DO PARQUE 162",
      city: "Porto"
    },
    {
      address: "4219 E Pinecrest cir",
      city: "Las Vegas"
    },
    {
      address: "2160 wallace ave bronx NYC",
      city: "bronx"
    },
    {
      address: "8515 w catalpa ave",
      city: "chicago"
    },
    {
      address: "1776 E 40 ST",
      city: "cleveland"
    },
    {
      address: "1700 scovel st",
      city: "nashville"
    },
    {
      address: "3800 eagle st",
      city: "new orleans"
    },
    {
      address: "4718 GILLMOR ST",
      city: "santa clara"
    },
    {
      address: "40 aviva ct",
      city: "staaten island"
    },
    {
      address: "89 mozart st",
      city: "rutherford"
    },
    {
      address: "59 john bright st",
      city: "birmingham"
    },
    {
      address: "204 willis ave",
      city: "arlington"
    },
    {
      address: "13000 schoolcraft st",
      city: "detroit"
    },
    {
      address: "caboto 300",
      city: "buenos aires"
    },
    {
      address: "7105 E 69th ter kansas city",
      city: "kansas city"
    },
    {
      address: "3220 Fruitvale Ave oakland",
      city: "Alameda/oakland"
    },
    {
      address: "8234 243RD ST",
      city: "queens"
    },
    {
      address: "320 EASTERN PKWY",
      city: "brooklyn"
    },
    {
      address: "AVENIDA PRESTES MAIA, 844",
      city: "Sao Paulo"
    },
    {
      address: "7 169 ST",
      city: "Lusail"
    },
    {
      address: "18 skyview way san fran",
      city: "san fransisco"
    },
    {
      address: "2-CHOME-14-13 SHIBUYA",
      city: "Tokyo"
    },
    {
      address: "3610 BAYVIEW RD   ",
      city: "Miami"
    }
  ];

  // Find the user's current city or default to the first entry in the list
  const currentUserCity =
    allCities.find(
      (city) => city.city.toLowerCase() === user?.currentCity?.toLowerCase()
    ) || allCities[0];

  // Concatenate the city and address for display
  const currentUserCityDisplay = `${currentUserCity.address}, ${currentUserCity.city}`;

  const { sendRequest: continueDepositApi, isLoading: isLoadingDepositApi } =
    useHttp();

  const { sendRequest: continueVerifyApi, isLoading: isLoadingVerifyApi } =
    useHttp();

  // Call calculateTaxesAndFinalAmount whenever the amount changes
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && validateAmount(amount)) {
      calculateTaxesAndFinalAmount(parsedAmount);
    }
  }, [amount]);

  // Call calculateTaxes whenever the amount changes
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && validateAmount(amount)) {
      calculateTaxes(parsedAmount);
    }
  }, [amount]);

  // Function to format numbers to remove unnecessary decimal zeros
  const formatNumber = (num: number): string => {
    return num.toFixed(2);
  };
  const validateAmount = (amountValue: any) => {
    const stringValue = String(amountValue).trim(); // Convert to string and trim
    if (stringValue === "") {
      return false; // Return false if the amount is empty
    }
    const regex = /^\d+(\.\d{0,2})?$/;
    return regex.test(stringValue);
  };
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsVerified(e.target.checked);
  };

  // Function to safely parse environment variables
  const safeParseFloat = (
    value: string | undefined,
    defaultValue: number
  ): number => {
    if (value === undefined) {
      return defaultValue;
    }
    const parsed = parseFloat(value);
    return isNaN(parsed) ? defaultValue : parsed; // Fallback to default if parsed value is NaN
  };

  // Function to calculate taxes
  const calculateTaxes = (amount: number) => {
    const uplandTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPLAND_TAX,
      0
    );
    const upxplorerTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPXLORER_TAX,
      0
    );

    const calculatedUplandTax = (uplandTaxPercentage / 100) * amount;
    const calculatedUpxplorerTax = (upxplorerTaxPercentage / 100) * amount;

    setUplandTax(calculatedUplandTax);
    setUpxplorerTax(calculatedUpxplorerTax);
  };

  const calculateTaxesAndFinalAmount = (amount: number) => {
    const uplandTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPLAND_TAX,
      0
    );
    const upxplorerTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPXLORER_TAX,
      0
    );

    const calculatedUplandTax = (uplandTaxPercentage / 100) * amount;
    const calculatedUpxplorerTax = (upxplorerTaxPercentage / 100) * amount;

    setUplandTax(calculatedUplandTax);
    setUpxplorerTax(calculatedUpxplorerTax);

    // const totalTax = calculatedUplandTax + calculatedUpxplorerTax;
    const totalTax = calculatedUplandTax;
    const calculatedFinalAmount = amount - totalTax;

    // Round the final amount to remove unnecessary decimal zeros
    setFinalAmount(Math.round(calculatedFinalAmount * 100) / 100);
  };

  // Update your continueDepositHandler to handle empty inputs
  const continueDepositHandler = () => {
    if (!validateAmount(amount)) {
      setAmountError("Invalid amount");
      return;
    }
    if (parseFloat(amount) < 20) {
      setAmountError("Amount must be greater than 20");
      return;
    }
    setAmountError(""); // Clear any previous error messages
    setShowConfirmDeposit(true);
  };

  const confirmDepositHandler = () => {
    setShowDepositModal(false);

    if (!validateAmount(amount)) {
      setAmountError("Invalid amount");
      return;
    }
    continueDepositApi(
      {
        url: "api/deposit",
        method: "POST",
        payload: JSON.stringify({
          amount: amount,
          email: user?.email_address,
          type: "deposit"
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error?.message, "error");
          console.log("Message", data?.error);
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
          setContainerId(data?.containerId);
        }
        setShowConfirmDeposit(false);
        setShowVerifyDeposit(true);
      }
    );
  };

  const verifyDepositHandler = () => {
    if (!isVerified) return;
    continueVerifyApi(
      {
        url: "api/verify",
        method: "POST",
        payload: JSON.stringify({
          containerId: containerId,
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error?.message, "error");
          console.log("Message", data?.error);
          return;
        }
        // if (data?.message) {
        //   notify(data?.message, "success");
        //   console.log("Message", data?.message);
        // }
        if (data?.verified) {
          setShowConfirmDeposit(false);
          setShowVerifyDeposit(false);
          setShowSuccessDeposit(true);
          notify(data?.message, "success");
          getUser(authToken);
        } else {
          notify(data?.message, "error");
        }
      }
    );
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Handle successful copy
        notify("Copied", "success");
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        // Handle error case
        console.error("Error in copying text: ", err);
      });
  };

  return (
    <>
      <DepositModal
        isOpen={showDepositModal}
        onAction={confirmDepositHandler}
        onCancel={() => setShowDepositModal(false)}
      />
      <CityListModal
        isOpen={showCityModal}
        onCancel={() => setShowCityModal(false)}
      />
      <TellMeHow
        isOpen={showTellMeModal}
        onCancel={() => setShowTellMeModal(false)}
      />
      <div className="flex flex-col justify-between h-full">
        <Header />
        <div className="2xl:px-14 xl:px-12 lg:px-10 md:px-0 px-5 h-full">
          <div className="flex flex-col items-center justify-center h-full">
            <MainHeading title="Deposit UPX" />
            {!showConfirmDeposit &&
            !showVerifyDeposit &&
            !showSuccessDeposit ? (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  Enter the amount
                </p>
                <div className="lg:w-auto w-full">
                  <div className="flex items-center justify-between">
                    <div className="flex items-center space-x-2 mb-1">
                      <p className="font-ano font-normal text-lg text-white">
                        UPXPLORER UPX
                      </p>

                      <img src={UpxIcon} className="" />
                    </div>
                    {amountError && (
                      <div className="font-ano lg:text-lg text-base font-bold uppercase text-[#FF6F6F] mt-2">
                        {amountError}
                      </div>
                    )}
                  </div>

                  <div className="relative">
                    <input
                      type="text"
                      className="bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 lg:w-[510px] w-full focus:border-[#3E7EFF] font-ano text-white placeholder:text-[#676767] font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                      placeholder="1000 UPX"
                      value={amount}
                      onChange={handleAmountChange}
                    />
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-5">
                  <div
                    onClick={continueDepositHandler}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Continue
                  </div>
                </div>
              </div>
            ) : null}

            {showConfirmDeposit && !showVerifyDeposit && !showSuccessDeposit ? (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  Confirm Deposit
                </p>
                <div className="w-full px-12">
                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-normal text-white text-2xl">
                      Amount Requested
                    </p>
                    <p className="font-ano font-normal text-white text-2xl">
                      {amount} Upx
                    </p>
                  </div>

                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-normal text-white text-2xl">
                      UPLAND TAX
                    </p>
                    <p className="font-ano font-normal text-white text-2xl">
                      -{formatNumber(uplandTax)} UPX
                    </p>
                  </div>

                  <div className="bg-[#676767] h-px my-6"></div>

                  <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-bold text-white text-2xl">
                      TOTAL CREDIT AMOUNT
                    </p>
                    <p className="font-ano font-normal text-[#F9F41F] text-2xl">
                      {formatNumber(finalAmount)} UPX
                    </p>
                  </div>

                  {/* <div className="flex items-center justify-between mb-2">
                    <p className="font-ano font-bold text-white text-2xl">
                      UPLAND AMOUNT REQUESTED
                    </p>
                    <p className="font-ano font-normal text-[#F9F41F] text-2xl">
                      {amount} UPX
                    </p>
                  </div> */}
                </div>

                <div className="flex items-center justify-between space-x-5">
                  <div
                    onClick={() => setShowConfirmDeposit(false)}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#676767] border-[#676767] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Back
                  </div>

                  <div
                    onClick={() => setShowDepositModal(true)}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    {!isLoadingDepositApi && <>Continue</>}
                    {isLoadingDepositApi && (
                      <ThreeDots
                        height={20}
                        width={20}
                        color="#ffffff"
                        wrapperStyle={{}}
                        wrapperClass="justify-center py-1"
                        visible={true}
                        ariaLabel="oval-loading"
                      />
                    )}
                  </div>
                </div>

                <div
                  className="group flex space-x-2 mt-6 uppercase cursor-pointer"
                  onClick={() => navigate("/direct-deposit")}
                >
                  <svg
                    className="cursor-pointer stroke-white group-hover:stroke-[#F9F41F] w-4 h-full"
                    viewBox="0 0 14 14"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M6.06813 7.93187L12.6197 1.38033M6.14773 8.13657L7.78756 12.3533C7.93202 12.7247 8.00425 12.9105 8.10833 12.9647C8.19855 13.0117 8.30603 13.0118 8.39631 12.9649C8.50045 12.9108 8.5729 12.7251 8.71779 12.3538L12.8299 1.8166C12.9607 1.48142 13.0261 1.31383 12.9903 1.20675C12.9592 1.11375 12.8863 1.04076 12.7933 1.00969C12.6862 0.97392 12.5186 1.03932 12.1834 1.17012L1.64618 5.2822C1.27488 5.4271 1.08923 5.49955 1.03513 5.60369C0.98823 5.69397 0.988293 5.80145 1.0353 5.89167C1.08953 5.99575 1.27526 6.06798 1.64673 6.21244L5.86343 7.85227C5.93883 7.88159 5.97654 7.89625 6.00828 7.9189C6.03642 7.93897 6.06103 7.96358 6.0811 7.99172C6.10375 8.02346 6.11841 8.06117 6.14773 8.13657Z"
                      stroke-linecap="round"
                      stroke-linejoin="round"
                    />
                  </svg>

                  <p className="font-ano font-bold text-base text-white group-hover:text-[#F9F41F]">
                    Try Direct Deposit
                  </p>
                </div>
              </div>
            ) : null}

            {!showConfirmDeposit && showVerifyDeposit && !showSuccessDeposit ? (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  Verify Deposit
                </p>
                <div className="w-full px-12">
                  <p className="font-ano font-normal text-[22px] text-white mb-3 text-center">
                    Visit your nearest devshop address
                  </p>
                  <div className="flex items-center justify-center space-x-4 mb-3">
                    <p className="font-ano font-bold text-[34px] text-[#F9F41F] uppercase text-center">
                      {currentUserCityDisplay}
                    </p>
                    <img
                      onClick={() => copyToClipboard(currentUserCityDisplay)}
                      src={CopyIcon}
                      className="cursor-pointer"
                    />
                  </div>
                  <div
                    className="flex items-center justify-center space-x-2 mb-4 cursor-pointer"
                    onClick={() => setShowCityModal(true)}
                  >
                    <img src={SearchIcon} />
                    <p className="font-ano font-normal text-lg text-[#999999]">
                      Locate a devshop
                    </p>
                  </div>

                  <div className="flex items-center justify-center space-x-2 mb-3">
                    <input
                      type="checkbox"
                      name="verfiy"
                      checked={isVerified}
                      onChange={handleCheckboxChange}
                    />
                    <p className="font-ano font-normal text-lg text-white">
                      I accept Upxplorer's{" "}
                      <span
                        className="cursor-pointer font-bold"
                        onClick={() => navigate("/terms-and-condition")}
                      >
                        terms and conditions
                      </span>
                      .
                    </p>
                  </div>
                </div>

                <div className="flex items-center justify-between space-x-5 mb-5">
                  <div
                    onClick={() => {
                      setShowConfirmDeposit(true);
                      setShowVerifyDeposit(false);
                      setShowConfirmDeposit(false);
                    }}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#676767] border-[#676767] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Back
                  </div>

                  <button
                    type="button"
                    onClick={verifyDepositHandler}
                    className={`w-52 mt-8 cursor-pointer border-2 rounded-[50px] ${
                      isVerified
                        ? "hover:bg-[#3E7EFF] border-[#3E7EFF] text-white"
                        : "cursor-not-allowed border-[#999999] text-[#999999]"
                    } font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase outline-none`}
                    disabled={!isVerified} // Disable the button if not verified
                  >
                    Verify
                  </button>
                </div>
                <div className="flex items-center justify-center space-x-3">
                  <img src={InfoIcon} className="w-4 h-4" />
                  <p
                    onClick={() => setShowTellMeModal(true)}
                    className="font-ano font-bold text-base text-[#979797] text-center cursor-pointer"
                  >
                    Show me how
                  </p>
                </div>
              </div>
            ) : null}

            {showSuccessDeposit &&
              !showVerifyDeposit &&
              !showConfirmDeposit && (
                <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                  <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                    THANK YOU FOR VERIFICATION
                  </p>
                  <p className="font-ano font-medium lg:text-4xl text-3xl text-[#1EA067] text-center mb-6">
                    Deposit awaiting to resolve
                  </p>

                  <div
                    onClick={() => navigate("/profile")}
                    className="flex items-center justify-between space-x-5"
                  >
                    <div className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none">
                      View profile
                    </div>
                  </div>
                </div>
              )}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
