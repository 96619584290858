import { MagnifyingGlassIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ThreeDots } from "react-loader-spinner";

interface HourlyReward {
  reward_amount: string;
  reward_id: number;
  rewarded_time: string;
  spark_contract_id: number;
  spark_hours: number;
  unix_time: string | null;
  updated_user_balance: number;
  upland_username: string;
  user_balance: string;
  user_email: string;
  upx_balance: number;
}

export default function AdminEditHourlyReward() {
  const navigate = useNavigate();
  const { authToken, user } = useAuth();
  const { reward_id } = useParams<string>();
  const notify = useNotification();

  const [selectedReward, setSelectedReward] = useState<HourlyReward | null>(
    null
  );
  const { sendRequest: getRewardByIdApi, isLoading: isLoadingRewardApi } =
    useHttp();

  const { sendRequest: saveRewardApi, isLoading: isLoadingSaveRewardApi } =
    useHttp();

  const getRewardById = () => {
    getRewardByIdApi(
      {
        url: `api/hourly-reward/edit/${reward_id}`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        console.log("data", data);

        setSelectedReward(data.hourlyReward || null);
      }
    );
  };

  useEffect(() => {
    console.log("reward_id", reward_id);
    if (reward_id) getRewardById();
  }, [reward_id]);


  return (
    <div className="bg-white w-full px-14 pt-10">
      <div className="flex items-center space-x-3">
        <svg
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="5" fill="#1C1C1C" />
          <path
            d="M19.2002 22.4004L12.8002 16.0004L19.2002 9.60039"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>

        <p className="font-ano font-bold text-3xl text-black">
          Edit Hourly Reward
        </p>
      </div>

      {/* <div className="relative rounded-md mt-5">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          className="rounded-full w- border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
          placeholder="Search"
        />
      </div> */}
      <div className="w-full mt-10">
        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="username"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Username
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="username"
                id="username"
                value={selectedReward?.upland_username}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="spark_contract_id"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Spark Contract ID
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="spark_contract_id"
                id="spark_contract_id"
                value={selectedReward?.spark_contract_id}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="sparkHours"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Spark Hours
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="sparkHours"
                id="sparkHours"
                value={selectedReward?.spark_hours}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="rewardAmount"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Reward Amount
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="rewardAmount"
                id="rewardAmount"
                value={Number(selectedReward?.reward_amount)}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="stakedSparkHours"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Staked Spark Hours
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="stakedSparkHours"
                id="stakedSparkHours"
                // value={selectedReward?.}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="walletBalance"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              User Balance Before
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="walletBalance"
                id="walletBalance"
                value={selectedReward?.upx_balance}
                disabled
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="updatedBalance"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Updated User Balance
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="updatedBalance"
                id="updatedBalance"
                disabled
                value={selectedReward?.updated_user_balance}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="rewardTime"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Rewarded Time
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="rewardTime"
                id="rewardTime"
                disabled
                value={selectedReward?.rewarded_time}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        {/* <div className="mt-10">
          <button
            type="button"
            className="lg:w-[150px] w-full cursor-pointer border-2 rounded-[50px] bg-[#2D6BE3] border-[#2D6BE3] font-ano font-bold lg:text-lg text-sm text-center py-1 uppercase text-white outline-none"
          >
            {!isLoadingSaveRewardApi && <>Save</>}
            {isLoadingSaveRewardApi && (
              <ThreeDots
                height={20}
                width={20}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass="justify-center py-1"
                visible={true}
                ariaLabel="oval-loading"
              />
            )}
          </button>
        </div> */}
      </div>
    </div>
  );
}
