import { Fragment, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Dialog, Transition } from "@headlessui/react";
import { useAuth } from "../context";

import CrossIcon from "../assets/images/cross.svg";
import User from "../assets/images/user.svg";
import LockIcon from "../assets/images/lock.svg";
import Logout from "../assets/images/logout.svg";
import MobileLogoutBtn from "../assets/images/mobileLogoutBtn.svg";

import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";
import Header from "./Header";
import DisconnectModal from "./DisconnectModal";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface NavigationItem {
  name: string;
  href?: string;
  // icon?: any;
  lock?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  current?: boolean;
  children?: NavigationItem[];
}

// const LockIcon = () => (
//   <svg
//     width="15"
//     height="18"
//     viewBox="0 0 15 18"
//     fill="none"
//     xmlns="http://www.w3.org/2000/svg"
//   >
//     <path
//       d="M11.5625 8.11111V5.0625C11.5625 2.81884 9.74366 1 7.5 1V1V1C5.25634 1 3.4375 2.81884 3.4375 5.0625V8.11111M4.9 17H10.1C11.4651 17 12.1477 17 12.6691 16.7094C13.1277 16.4537 13.5006 16.0457 13.7343 15.544C14 14.9735 14 14.2268 14 12.7333V12.3778C14 10.8843 14 10.1376 13.7343 9.56714C13.5006 9.06537 13.1277 8.65742 12.6691 8.40176C12.1477 8.11111 11.4651 8.11111 10.1 8.11111H4.9C3.53487 8.11111 2.85231 8.11111 2.3309 8.40176C1.87225 8.65742 1.49936 9.06537 1.26567 9.56714C1 10.1376 1 10.8843 1 12.3778V12.7333C1 14.2268 1 14.9735 1.26567 15.544C1.49936 16.0457 1.87225 16.4537 2.3309 16.7094C2.85231 17 3.53487 17 4.9 17Z"
//       stroke="#B5B5B5"
//       stroke-width="1.5"
//       stroke-linecap="round"
//       stroke-linejoin="round"
//     />
//     <path
//       d="M11.5625 8.11111V5.0625C11.5625 2.81884 9.74366 1 7.5 1V1V1C5.25634 1 3.4375 2.81884 3.4375 5.0625V8.11111M4.9 17H10.1C11.4651 17 12.1477 17 12.6691 16.7094C13.1277 16.4537 13.5006 16.0457 13.7343 15.544C14 14.9735 14 14.2268 14 12.7333V12.3778C14 10.8843 14 10.1376 13.7343 9.56714C13.5006 9.06537 13.1277 8.65742 12.6691 8.40176C12.1477 8.11111 11.4651 8.11111 10.1 8.11111H4.9C3.53487 8.11111 2.85231 8.11111 2.3309 8.40176C1.87225 8.65742 1.49936 9.06537 1.26567 9.56714C1 10.1376 1 10.8843 1 12.3778V12.7333C1 14.2268 1 14.9735 1.26567 15.544C1.49936 16.0457 1.87225 16.4537 2.3309 16.7094C2.85231 17 3.53487 17 4.9 17Z"
//       stroke="#B5B5B5"
//       stroke-width="1.5"
//       stroke-linecap="round"
//       stroke-linejoin="round"
//     />
//   </svg>
// );

const DashboardIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="27"
    height="24"
    viewBox="0 0 27 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.5 23.1154H18.5M13.5 18.2009V23.1154M7 18.2009H20C22.1002 18.2009 23.1503 18.2009 23.9525 17.7991C24.6581 17.4457 25.2317 16.8819 25.5913 16.1883C26 15.3999 26 14.3677 26 12.3034V6.89744C26 4.83314 26 3.80099 25.5913 3.01253C25.2317 2.31899 24.6581 1.75512 23.9525 1.40174C23.1503 1 22.1002 1 20 1H7C4.8998 1 3.8497 1 3.04754 1.40174C2.34193 1.75512 1.76825 2.31899 1.40873 3.01253C1 3.80099 1 4.83314 1 6.89744V12.3034C1 14.3677 1 15.3999 1.40873 16.1883C1.76825 16.8819 2.34193 17.4457 3.04754 17.7991C3.8497 18.2009 4.8998 18.2009 7 18.2009Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const WalletIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="27"
    height="24"
    viewBox="0 0 36 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M30.8 10.9375V7.2C30.8 5.37983 30.8 4.46975 30.4512 3.77453C30.1444 3.16301 29.6549 2.66582 29.0528 2.35423C28.3683 2 27.4722 2 25.68 2H7.12C5.32783 2 4.43175 2 3.74723 2.35423C3.14511 2.66582 2.65557 3.163 2.34878 3.77453C2 4.46974 2 5.37983 2 7.2V22.8C2 24.6202 2 25.5303 2.34878 26.2255C2.65557 26.837 3.14511 27.3342 3.74723 27.6458C4.43175 28 5.32783 28 7.12 28L25.68 28C27.4722 28 28.3683 28 29.0528 27.6458C29.6549 27.3342 30.1444 26.837 30.4512 26.2255C30.8 25.5303 30.8 24.6202 30.8 22.8V19.0625M22.8 15C22.8 14.2449 22.8 13.8674 22.8615 13.5535C23.114 12.2642 24.1063 11.2564 25.3757 10.9999C25.6848 10.9375 26.0566 10.9375 26.8 10.9375H30C30.7434 10.9375 31.1152 10.9375 31.4243 10.9999C32.6937 11.2564 33.686 12.2642 33.9385 13.5535C34 13.8674 34 14.2449 34 15C34 15.7551 34 16.1326 33.9385 16.4465C33.686 17.7358 32.6937 18.7436 31.4243 19.0001C31.1152 19.0625 30.7434 19.0625 30 19.0625H26.8C26.0566 19.0625 25.6848 19.0625 25.3757 19.0001C24.1063 18.7436 23.114 17.7358 22.8615 16.4465C22.8 16.1326 22.8 15.7551 22.8 15Z"
      strokeWidth="2.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const DepositIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4172 12.4172C13.1994 12.7908 14.0753 13 15 13C18.3137 13 21 10.3137 21 7C21 3.68629 18.3137 1 15 1C11.6863 1 9 3.68629 9 7C9 7.92472 9.20919 8.80057 9.58283 9.58283M15 19L18 22M18 22L21 19M18 22V15M5 13V17H9V13M13 15C13 18.3137 10.3137 21 7 21C3.68629 21 1 18.3137 1 15C1 11.6863 3.68629 9 7 9C10.3137 9 13 11.6863 13 15Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const WithdrawalIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="22"
    height="23"
    viewBox="0 0 22 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12.4172 12.4172C13.1994 12.7908 14.0753 13 15 13C18.3137 13 21 10.3137 21 7C21 3.68629 18.3137 1 15 1C11.6863 1 9 3.68629 9 7C9 7.92472 9.20919 8.80057 9.58283 9.58283M21 18L18 15M18 15L15 18M18 15V22M5 13.5V17.5H9V13.5M13 15C13 18.3137 10.3137 21 7 21C3.68629 21 1 18.3137 1 15C1 11.6863 3.68629 9 7 9C10.3137 9 13 11.6863 13 15Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const TransactionsIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M5 5L7 3M7 3L5 1M7 3H5C2.79086 3 1 4.79086 1 7M17 17L15 19M15 19L17 21M15 19H17C19.2091 19 21 17.2091 21 15M12.4172 12.4172C13.1994 12.7908 14.0753 13 15 13C18.3137 13 21 10.3137 21 7C21 3.68629 18.3137 1 15 1C11.6863 1 9 3.68629 9 7C9 7.92472 9.20919 8.80057 9.58283 9.58283M5 12.5V16.5H9V12.5M13 5V9H17V5M13 15C13 18.3137 10.3137 21 7 21C3.68629 21 1 18.3137 1 15C1 11.6863 3.68629 9 7 9C10.3137 9 13 11.6863 13 15Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ExchangeIcon = () => (
  <svg
    className="cursor-pointer stroke-white  hover:stroke-[#3E7EFF]"
    width="27"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9377 15.9377C19.3603 15.4795 22 12.548 22 9C22 5.13401 18.866 2 15 2C11.452 2 8.52049 4.63967 8.06227 8.06227M7.5 13L9 12V17.5M7.5 17.5H10.5M16 15C16 18.866 12.866 22 9 22C5.13401 22 2 18.866 2 15C2 11.134 5.13401 8 9 8C12.866 8 16 11.134 16 15Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ActiveContractsIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="22"
    height="20"
    viewBox="0 0 22 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M12 9H16.8C17.9201 9 18.4802 9 18.908 9.21799C19.2843 9.40973 19.5903 9.71569 19.782 10.092C20 10.5198 20 11.0799 20 12.2V19M12 19V4.2C12 3.0799 12 2.51984 11.782 2.09202C11.5903 1.71569 11.2843 1.40973 10.908 1.21799C10.4802 1 9.9201 1 8.8 1H5.2C4.0799 1 3.51984 1 3.09202 1.21799C2.71569 1.40973 2.40973 1.71569 2.21799 2.09202C2 2.51984 2 3.0799 2 4.2V19M21 19H1M5.5 5H8.5M5.5 9H8.5M5.5 13H8.5"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const SparkDashboardIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="18"
    height="20"
    viewBox="0 0 18 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.5 5H11.5M6.5 9H11.5M6.5 13H11.5M15 19V4.2C15 3.0799 15 2.51984 14.782 2.09202C14.5903 1.71569 14.2843 1.40973 13.908 1.21799C13.4802 1 12.9201 1 11.8 1H6.2C5.0799 1 4.51984 1 4.09202 1.21799C3.71569 1.40973 3.40973 1.71569 3.21799 2.09202C3 2.51984 3 3.0799 3 4.2V19M17 19H1"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const UpxDashboardIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="19"
    height="16"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M14.6944 4.26667V11.7333M1 3.98667L1 12.0133C1 13.0588 1 13.5815 1.20588 13.9808C1.38697 14.332 1.67593 14.6176 2.03135 14.7965C2.43541 15 2.96435 15 4.02222 15L14.9778 15C16.0357 15 16.5646 15 16.9686 14.7965C17.3241 14.6176 17.613 14.332 17.7941 13.9808C18 13.5815 18 13.0588 18 12.0133V3.98667C18 2.94124 18 2.41852 17.7941 2.01922C17.613 1.66798 17.3241 1.38242 16.9686 1.20346C16.5646 1 16.0357 1 14.9778 1L4.02222 1C2.96435 1 2.43541 1 2.03135 1.20345C1.67593 1.38242 1.38697 1.66798 1.20588 2.01922C1 2.41852 1 2.94124 1 3.98667ZM9.02778 8C9.02778 9.28866 7.97067 10.3333 6.66667 10.3333C5.36266 10.3333 4.30556 9.28866 4.30556 8C4.30556 6.71134 5.36266 5.66667 6.66667 5.66667C7.97067 5.66667 9.02778 6.71134 9.02778 8Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ProfileIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="27"
    height="24"
    viewBox="0 0 21 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.5 14.3333C6.89646 14.3333 3.69187 16.034 1.65165 18.6733C1.21254 19.2413 0.992989 19.5254 1.00017 19.9092C1.00572 20.2058 1.19624 20.5799 1.43496 20.763C1.74395 21 2.17213 21 3.02851 21H17.9715C18.8279 21 19.2561 21 19.565 20.763C19.8038 20.5799 19.9943 20.2058 19.9998 19.9092C20.007 19.5254 19.7875 19.2413 19.3483 18.6733C17.3081 16.034 14.1035 14.3333 10.5 14.3333Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M10.5 11C13.3251 11 15.6153 8.76142 15.6153 6C15.6153 3.23858 13.3251 1 10.5 1C7.67489 1 5.38469 3.23858 5.38469 6C5.38469 8.76142 7.67489 11 10.5 11Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const BankIcon = () => (
  <svg
    className="cursor-pointer stroke-[#B5B5B5] hover:stroke-[#B5B5B5]"
    width="27"
    height="24"
    viewBox="0 0 24 25"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 24H23M4.66667 20.3512V10.6211M9.55556 20.3512V10.6211M14.4444 20.3512V10.6211M19.3333 20.3512V10.6211M21.7778 6.97231L12.5182 1.2133C12.3302 1.09638 12.2362 1.03791 12.1354 1.01512C12.0463 0.994961 11.9537 0.994961 11.8646 1.01512C11.7638 1.03791 11.6698 1.09638 11.4818 1.2133L2.22222 6.97231H21.7778Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const AuctionIcon = () => (
  <svg
    className="cursor-pointer stroke-[#B5B5B5] hover:stroke-[#B5B5B5]"
    width="27"
    height="24"
    viewBox="0 0 22 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect
      x="1.20208"
      y="12.1731"
      width="15.5155"
      height="10.2184"
      rx="1.51237"
      transform="rotate(-45 1.20208 12.1731)"
      strokeWidth="1.7"
    />
    <path
      d="M11.7109 15.4453L16.5953 20.3296C17.5178 21.2522 19.0136 21.2522 19.9362 20.3296L20.3299 19.9359C21.2525 19.0133 21.2525 17.5176 20.3299 16.595L15.4456 11.7107"
      strokeWidth="1.7"
    />
    <path
      d="M1.32422 22.5125H6.62129"
      strokeWidth="1.7"
      strokeLinecap="round"
    />
  </svg>
);
const SparkIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="27"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9377 15.9377C19.3603 15.4795 22 12.548 22 9C22 5.13401 18.866 2 15 2C11.452 2 8.52049 4.63967 8.06227 8.06227M7.5 13L9 12V17.5M7.5 17.5H10.5M16 15C16 18.866 12.866 22 9 22C5.13401 22 2 18.866 2 15C2 11.134 5.13401 8 9 8C12.866 8 16 11.134 16 15Z"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const ContactIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="27"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2 7L10.1649 12.7154C10.8261 13.1783 11.1567 13.4097 11.5163 13.4993C11.8339 13.5785 12.1661 13.5785 12.4837 13.4993C12.8433 13.4097 13.1739 13.1783 13.8351 12.7154L22 7M6.8 20H17.2C18.8802 20 19.7202 20 20.362 19.673C20.9265 19.3854 21.3854 18.9265 21.673 18.362C22 17.7202 22 16.8802 22 15.2V8.8C22 7.11984 22 6.27976 21.673 5.63803C21.3854 5.07354 20.9265 4.6146 20.362 4.32698C19.7202 4 18.8802 4 17.2 4H6.8C5.11984 4 4.27976 4 3.63803 4.32698C3.07354 4.6146 2.6146 5.07354 2.32698 5.63803C2 6.27976 2 7.11984 2 8.8V15.2C2 16.8802 2 17.7202 2.32698 18.362C2.6146 18.9265 3.07354 19.3854 3.63803 19.673C4.27976 20 5.11984 20 6.8 20Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
const FaqIcon = () => (
  <svg
    className="cursor-pointer stroke-white hover:stroke-[#3E7EFF]"
    width="27"
    height="24"
    viewBox="0 0 26 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.3745 9.75242C11.5654 9.20981 11.9422 8.75225 12.4381 8.46081C12.934 8.16936 13.517 8.06282 14.0839 8.16006C14.6509 8.25731 15.1651 8.55206 15.5355 8.99211C15.906 9.43216 16.1087 9.98911 16.1079 10.5643C16.1079 12.1881 13.6722 13 13.6722 13M13.7036 16.25H13.7144M13.5412 21.6667C18.6268 21.6667 22.7495 17.544 22.7495 12.4583C22.7495 7.37271 18.6268 3.25 13.5412 3.25C8.45556 3.25 4.33285 7.37271 4.33285 12.4583C4.33285 13.4875 4.50168 14.4772 4.81316 15.4013C4.93038 15.7491 4.98899 15.923 4.99956 16.0565C5.01 16.1885 5.00211 16.281 4.96947 16.4092C4.93642 16.5391 4.86346 16.6741 4.71753 16.9442L2.94557 20.224C2.69281 20.6919 2.56644 20.9258 2.59472 21.1063C2.61936 21.2636 2.71191 21.402 2.84778 21.4849C3.00379 21.5801 3.26825 21.5527 3.79718 21.4981L9.34496 20.9246C9.51296 20.9072 9.59696 20.8985 9.67353 20.9015C9.74883 20.9044 9.80199 20.9114 9.87543 20.9284C9.9501 20.9456 10.044 20.9817 10.2318 21.0541C11.2588 21.4498 12.3747 21.6667 13.5412 21.6667Z"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

const navigation: NavigationItem[] = [
  // {
  //   name: "Dashboard",
  //   icon: DashboardIcon,
  //   href: "/home",
  //   current: false,
  //   lock: false
  // },
  {
    name: "Wallet",
    current: false,
    icon: WalletIcon,
    lock: false,
    children: [
      { name: "Deposit", href: "/deposit", icon: DepositIcon },
      { name: "Withdrawal", href: "/withdraw", icon: WithdrawalIcon },
      { name: "Transactions", href: "/transactions", icon: TransactionsIcon },
      { name: "Transfer", href: "/transfer", icon: TransactionsIcon }
    ]
  },
  // { name: "Spark Listing", icon: DashboardIcon, href: "/spark-listing", current: true },
  {
    name: "Spark Market",
    icon: ExchangeIcon,
    href: "/dashboard",
    current: false,
    lock: false,
    children: [
      {
        name: "ACTIVE CONTRACTS",
        href: "/home",
        icon: ActiveContractsIcon
      },
      { name: "SPARK DASHBOARD", href: "/dashboard", icon: SparkDashboardIcon },
      { name: "UPX DASHBOARD", href: "/upx-dashboard", icon: UpxDashboardIcon }
    ]
  },
  // {
  //   name: "Profile",
  //   icon: ProfileIcon,
  //   href: "/profile",
  //   current: false,
  //   lock: false
  // },
  { name: "Bank", icon: BankIcon, href: "#", current: false, lock: true },
  { name: "Auction", icon: AuctionIcon, href: "#", current: false, lock: true }
];

const navigation2: NavigationItem[] = [
  // { name: "Advertise", icon: SparkIcon, href: "/advertisement", current: true },
  { name: "Contact Us", icon: ContactIcon, href: "/contact-us", current: true },
  { name: "Faq", icon: FaqIcon, href: "/faq", current: true }
];

export default function Sidebar() {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    user,
    setUser,
    authToken,
    updateAuthToken
  } = useAuth();
  const navigate = useNavigate();

  const [showDisconnectModal, setShowDisconnectModal] = useState(false);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const formatEmail = (email: string) => {
    if (!email) return;
    const [localPart] = email.split("@");
    return `${localPart}`;
  };

  const logout = () => {
    localStorage.removeItem("authToken");
    updateAuthToken("");
    setUser(null);
    navigate("/home");
    setIsDrawerOpen(false);
  };

  return (
    <>
    <DisconnectModal
        isLogout={true}
        isOpen={showDisconnectModal}
        onAction={logout}
        onCancel={() => setShowDisconnectModal(false)}
      />
    <Transition.Root show={isDrawerOpen} as={Fragment}>
      <Dialog
        as="div"
        className="relative z-10"
        onClose={() => setIsDrawerOpen(false)}
      >
        {windowWidth >= 500 ? (
          <div className="fixed inset-0" />
        ) : (
          <div className="fixed bg-black inset-0">
            <Header />
          </div>
        )}

        <div className="fixed inset-0 overflow-hidden">
          <div className="absolute inset-0 overflow-hidden">
            <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full ">
              <Transition.Child
                as={Fragment}
                enter="transform transition ease-in-out duration-500 sm:duration-700"
                enterFrom={
                  windowWidth >= 500 ? "translate-x-full" : "translate-y-full"
                }
                enterTo={
                  windowWidth >= 500 ? "translate-x-0" : "translate-y-[20%]"
                }
                leave="transform transition ease-in-out duration-500 sm:duration-700"
                leaveFrom={
                  windowWidth >= 500 ? "translate-x-0" : "translate-y-0"
                }
                leaveTo={
                  windowWidth >= 500 ? "translate-x-full" : "translate-y-full"
                }
              >
                {windowWidth >= 500 ? (
                  <Dialog.Panel className="pointer-events-auto border-l border-[#676767] max-w-md">
                    <div className="flex h-full flex-col bg-black text-white shadow-xl">
                      <div className="w-full px-10 py-6 border-b border-[rgb(103,103,103)]">
                        <div className="flex items-center justify-between h-full">
                          {authToken && user && (
                            <div className="flex items-center relative ml-5">
                              <div className="rounded-full lg:h-10 lg:w-10 h-16 w-16 absolute lg:-left-4 z-10 bg-[#676767] border-2 border-white flex items-center justify-center">
                                <img
                                  src={
                                    user?.avatar_url ? user?.avatar_url : User
                                  }
                                  className="lg:w-7 h-10"
                                />
                              </div>
                              <div className="lg:h-7 rounded-[30px] flex items-center rounded-l-none border-2 border-white lg:pl-8 lg:pr-10  pl-10 pr-16 relative">
                                <p
                                  onClick={() => navigate("/profile")}
                                  className="text-white font-ano font-bold lg:text-sm text-base cursor-pointer"
                                >
                                  {user?.upland_username
                                    ? user?.upland_username
                                    : formatEmail(
                                        user && user?.userEmail
                                          ? user?.userEmail
                                          : ""
                                      )}
                                </p>
                                <img
                                  src={Logout}
                                  onClick={() => setShowDisconnectModal(true)}
                                  className="absolute right-3 cursor-pointer"
                                />
                              </div>
                            </div>
                          )}
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              onClick={() => setIsDrawerOpen(false)}
                              type="button"
                              className="relative outline-none"
                            >
                              <img className="ml-1" src={CrossIcon} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative mt-8 flex-1 flex flex-col">
                        <nav className="flex flex-1 flex-col">
                          <ul role="list" className="flex flex-1 flex-col">
                            {navigation.map((item, key) => (
                              <li key={key} className="">
                                {!item.children ? (
                                  <div
                                    className={`w-full ${
                                      item.lock ? "" : "hover-effect text-white"
                                    }`}
                                  >
                                    <div className="flex items-center justify-between px-12">
                                      <div className="flex items-center space-x-8">
                                        {item.icon && <item.icon />}
                                        <a
                                          href={item.href}
                                          className={classNames(
                                            `block py-2  text-2xl font-normal uppercase tracking-wider font-ano ${
                                              item.lock ? "text-[#B5B5B5]" : ""
                                            }`
                                          )}
                                        >
                                          {item.name}
                                        </a>
                                      </div>
                                      {item?.lock && (
                                        <div className="flex items-center space-x-4">
                                          <span className="font-normal text-xs text-[#B5B5B5] uppercase">
                                            &nbsp;&nbsp; coming soon
                                          </span>
                                          <img
                                            src={LockIcon}
                                            className="h-6 w-6"
                                          />
                                        </div>
                                      )}
                                    </div>
                                  </div>
                                ) : (
                                  <Disclosure as="div">
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          className={`flex items-center justify-between space-x-12 w-full px-12 py-2  hover-effect text-white`}
                                        >
                                          <div
                                            className={classNames(
                                              "flex items-center space-x-8 cursor-pointer w-full text-2xl font-normal uppercase tracking-wider font-ano"
                                            )}
                                          >
                                            {/* <img src={item?.icon} /> */}
                                            {item.icon && <item.icon />}

                                            <p>{item.name}</p>
                                          </div>
                                          <ChevronDownIcon
                                            className={classNames(
                                              open ? "rotate-180  " : "",
                                              "h-8 w-8 shrink-0 text-right"
                                            )}
                                            aria-hidden="true"
                                          />
                                        </Disclosure.Button>
                                        <Disclosure.Panel
                                          as="ul"
                                          className={`px-12 ${
                                            item?.current
                                              ? "bg-[#101010]"
                                              : "bg-[#101010]"
                                          }`}
                                        >
                                          {item?.children?.map(
                                            (subItem, subItemKey) => (
                                              <li
                                                key={subItemKey}
                                                className="flex cursor-pointer hover-effect items-center space-x-8"
                                              >
                                                {/* <img src={subItem?.icon} /> */}
                                                {subItem.icon && (
                                                  <subItem.icon />
                                                )}

                                                <a
                                                  href={subItem?.href}
                                                  className={classNames(
                                                    "block py-2 text-2xl font-normal  uppercase tracking-wider font-ano"
                                                  )}
                                                >
                                                  {subItem.name}
                                                </a>
                                              </li>
                                            )
                                          )}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                                )}
                              </li>
                            ))}
                          </ul>
                        </nav>

                        <div>
                          <ul className="mb-6">
                            {navigation2.map((item, key) => (
                              <li key={key} className="">
                                {!item.children ? (
                                  <div className="w-full text-white hover-effect">
                                    <div className="flex items-center space-x-8 px-12">
                                      {/* <img src={item?.icon} /> */}
                                      {item.icon && <item.icon />}

                                      <a
                                        href={item.href}
                                        className={classNames(
                                          "block py-2 text-2xl font-normal uppercase tracking-wider font-ano"
                                        )}
                                      >
                                        {item.name}
                                      </a>
                                    </div>
                                  </div>
                                ) : (
                                  <Disclosure as="div">
                                    {({ open }) => (
                                      <>
                                        <Disclosure.Button
                                          className={`flex items-center justify-between w-full px-12 py-2 ${
                                            item.current
                                              ? "bg-[#101010]"
                                              : "hover:bg-[#101010]"
                                          }`}
                                        >
                                          <div
                                            className={classNames(
                                              "flex items-center space-x-8 cursor-pointer w-full text-2xl font-normal text-white uppercase tracking-wider font-ano"
                                            )}
                                          >
                                            {/* <img src={item?.icon} /> */}
                                            {item.icon && <item.icon />}

                                            <p>{item.name}</p>
                                          </div>
                                          <ChevronDownIcon
                                            className={classNames(
                                              open
                                                ? "rotate-180 text-gray-500 "
                                                : "text-gray-400",
                                              "h-10 w-10 shrink-0 text-right"
                                            )}
                                            aria-hidden="true"
                                          />
                                        </Disclosure.Button>
                                        <Disclosure.Panel
                                          as="ul"
                                          className={`px-12 ${
                                            item?.current
                                              ? "bg-[#101010]"
                                              : "bg-[#101010]"
                                          }`}
                                        >
                                          {item?.children?.map(
                                            (subItem, subItemKey) => (
                                              <li
                                                key={subItemKey}
                                                className="flex items-center space-x-8"
                                              >
                                                {/* <img src={item?.icon} /> */}
                                                {item.icon && <item.icon />}

                                                <div
                                                  className={classNames(
                                                    subItem.current
                                                      ? "bg-[#101010]"
                                                      : "hover:bg-[#101010]",
                                                    "block py-2 text-2xl font-normal text-white uppercase tracking-wider font-ano"
                                                  )}
                                                >
                                                  {subItem.name}
                                                </div>
                                              </li>
                                            )
                                          )}
                                        </Disclosure.Panel>
                                      </>
                                    )}
                                  </Disclosure>
                                )}
                              </li>
                            ))}
                          </ul>
                          <div className="text-white w-full border-t border-[#676767] py-6">
                            <div className="flex items-center justify-center space-x-6">
                              <a
                                href="https://twitter.com/_houseofspark"
                                target="_blank"
                              >
                                <svg
                                  className="cursor-pointer fill-[#6D7078] hover:fill-[#FCF619]"
                                  width="15"
                                  height="16"
                                  viewBox="0 0 15 16"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M14.0478 0H12.0759L8.26682 5.27551L4.93525 0.0377474H0L5.72528 8.55372L0.151605 16H2.39309L6.73483 10.0554L10.6381 15.8612H15L9.34602 6.97219L14.0478 0ZM2.17243 1.27074L11.1526 14.6282H12.8993L4.40312 1.27074H2.17243Z" />
                                </svg>
                              </a>

                              <a
                                href="https://www.youtube.com/channel/UCsNf9E_Tc5cCYVDn_12sQlw"
                                target="_blank"
                              >
                                <svg
                                  className="cursor-pointer fill-[#6D7078] hover:fill-[#F9F41F]"
                                  width="25"
                                  height="17"
                                  viewBox="0 0 25 17"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M24.4782 2.65557C24.3359 2.14135 24.0569 1.6724 23.6691 1.29567C23.2813 0.91894 22.7983 0.647638 22.2684 0.508917C20.3181 1.03264e-07 12.5 0 12.5 0C12.5 0 4.68192 -1.03264e-07 2.73158 0.50621C2.20151 0.644481 1.71826 0.915632 1.33037 1.29243C0.942486 1.66923 0.663614 2.13842 0.521763 2.65287C-1.06437e-07 4.54777 0 8.5 0 8.5C0 8.5 -1.06437e-07 12.4522 0.521763 14.3444C0.809152 15.3893 1.65737 16.2123 2.73158 16.4911C4.68192 17 12.5 17 12.5 17C12.5 17 20.3181 17 22.2684 16.4911C23.3454 16.2123 24.1908 15.3893 24.4782 14.3444C25 12.4522 25 8.5 25 8.5C25 8.5 25 4.54777 24.4782 2.65557ZM10.0167 12.1274V4.87261L16.49 8.47293L10.0167 12.1274Z" />
                                </svg>
                              </a>

                              <a
                                href="https://www.facebook.com/groups/houseofsparkupland"
                                target="_blank"
                              >
                                <svg
                                  className="cursor-pointer fill-[#6D7078] hover:fill-[#F9F41F]"
                                  width="23"
                                  height="23"
                                  viewBox="0 0 23 23"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M23 11.5703C23 5.18019 17.8513 0 11.5 0C5.14872 0 0 5.18019 0 11.5703C0 17.3453 4.20536 22.132 9.70312 23V14.9148H6.7832V11.5703H9.70312V9.0212C9.70312 6.1214 11.42 4.51964 14.0468 4.51964C15.3047 4.51964 16.6211 4.74562 16.6211 4.74562V7.593H15.171C13.7425 7.593 13.2969 8.48495 13.2969 9.40085V11.5703H16.4863L15.9765 14.9148H13.2969V23C18.7946 22.132 23 17.3453 23 11.5703Z" />
                                </svg>
                              </a>

                              <a
                                href="https://discord.gg/FAmAK63GYB"
                                target="_blank"
                              >
                                <svg
                                  className="cursor-pointer fill-[#6D7078] hover:fill-[#FCF619]"
                                  width="24"
                                  height="18"
                                  viewBox="0 0 24 18"
                                  fill="none"
                                  xmlns="http://www.w3.org/2000/svg"
                                >
                                  <path d="M15.2749 0.000199633L14.986 0.343292C18.4776 1.45625 20.099 3.06293 20.099 3.06293C15.2187 0.276346 9.48763 0.276347 4.75184 2.44369C3.9893 2.81188 3.5398 3.06293 3.5398 3.06293C3.5398 3.06293 5.24147 1.37257 8.94181 0.259611L8.73311 0.000199633C8.73311 0.000199633 5.91572 -0.0667458 2.96187 2.25122C2.96187 2.25122 0 7.84112 0 14.7364C0 14.7364 1.72575 17.841 6.2689 17.9916C6.2689 17.9916 7.03144 17.0293 7.6495 16.2176C5.0408 15.4059 4.05351 13.6904 4.05351 13.6904C4.35853 13.908 4.85619 14.2009 4.89632 14.226C8.36388 16.2511 13.2923 16.9121 17.715 14.9791C18.4375 14.703 19.2321 14.2929 20.0749 13.7155C20.0749 13.7155 19.0475 15.4728 16.3585 16.2678C16.9766 17.0795 17.715 18 17.715 18C22.2582 17.8494 24 14.7448 24 14.7448C24 7.84948 21.0381 2.25959 21.0381 2.25959C18.0923 -0.0667451 15.2749 0.000199633 15.2749 0.000199633ZM8.17124 12.7448C7.02341 12.7448 6.07625 11.6737 6.07625 10.3683C6.07625 9.06286 6.99933 7.99174 8.17124 7.99174C9.34314 7.99174 10.2903 9.06286 10.2662 10.3683C10.2662 11.6737 9.34314 12.7448 8.17124 12.7448ZM15.6682 12.7448C14.5204 12.7448 13.5732 11.6737 13.5732 10.3683C13.5732 9.06286 14.4963 7.99174 15.6682 7.99174C16.8401 7.99174 17.7632 9.06286 17.7632 10.3683C17.7632 11.6737 16.8401 12.7448 15.6682 12.7448Z" />
                                </svg>
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Dialog.Panel>
                ) : (
                  <Dialog.Panel className="pointer-events-auto w-full">
                    <div className="flex overflow-hidden flex-col w-screen h-full bg-[#1C1C1C]  text-white shadow-xl rounded-3xl">
                      <div className="w-full px-10 py-8">
                        <div
                          className={`flex items-center ${
                            authToken && user
                              ? "justify-between"
                              : "justify-between"
                          }`}
                        >
                          {authToken && user && (
                            <div className="flex items-center relative ml-10">
                              <div className="rounded-full h-16 w-16 absolute -left-10 z-10 bg-[#676767] border-2 border-white flex items-center justify-center">
                                <img
                                  src={
                                    user?.avatar_url ? user?.avatar_url : User
                                  }
                                  className="w-72 h-10"
                                />
                              </div>
                              <div className="h-10 w-[200px] rounded-[30px] flex items-center rounded-l-none border-2 border-white px-10 relative">
                                <p
                                  onClick={() => navigate("/profile")}
                                  className="text-white font-ano font-bold lg:text-[22px] text-base"
                                >
                                  {user?.upland_username
                                    ? user?.upland_username
                                    : formatEmail(
                                        user && user?.userEmail
                                          ? user?.userEmail
                                          : ""
                                      )}
                                </p>
                              </div>
                            </div>
                          )}
                          {!authToken && (
                            <li className="flex items-center justify-center">
                              <div
                                onClick={() => {
                                  navigate("/login");
                                  setIsDrawerOpen(false);
                                }}
                                className="cursor-pointer border-2  w-auto rounded-[50px] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-20 bg-[#1C1C1C] text-white uppercase outline-none"
                              >
                                Login
                              </div>
                            </li>
                          )}
                          <div className="ml-3 flex h-7 items-center">
                            <button
                              onClick={() => setIsDrawerOpen(false)}
                              type="button"
                              className="relative outline-none"
                            >
                              <img className="ml-1" src={CrossIcon} />
                            </button>
                          </div>
                        </div>
                      </div>
                      <div className="relative flex-1">
                        <nav className="flex flex-1 flex-col">
                          <ul role="list" className="flex flex-1 flex-col">
                            <li>
                              <ul role="list" className="">
                                {navigation.map((item, itemKey) => (
                                  <>
                                    <li key={itemKey} className="">
                                      {!item.children ? (
                                        <div className="w-full">
                                          <div className="flex items-center space-x-5 px-10">
                                            {/* <img src={item?.icon} /> */}
                                            {item.icon && <item.icon />}

                                            <a
                                              href={item.href}
                                              className={classNames(
                                                item.current
                                                  ? "text-[#2C58C5]"
                                                  : "text-[#2C58C5]",
                                                "block py-2 text-2xl font-normal text-white uppercase tracking-wider font-ano"
                                              )}
                                            >
                                              {item.name}
                                            </a>
                                          </div>
                                        </div>
                                      ) : (
                                        <Disclosure as="div">
                                          {({ open }) => (
                                            <>
                                              <Disclosure.Button
                                                className={`flex items-center justify-between w-full px-10 py-2  ${
                                                  item.current ? "" : ""
                                                }`}
                                              >
                                                <div
                                                  className={classNames(
                                                    "flex items-center space-x-5 cursor-pointer w-full text-2xl font-normal text-white uppercase tracking-wider font-ano"
                                                  )}
                                                >
                                                  {/* <img src={item?.icon} /> */}
                                                  {item.icon && <item.icon />}

                                                  <p>{item.name}</p>
                                                </div>
                                                <ChevronDownIcon
                                                  className={classNames(
                                                    open
                                                      ? "rotate-180 text-gray-500"
                                                      : "text-gray-400",
                                                    "h-10 w-10 shrink-0 text-right"
                                                  )}
                                                  aria-hidden="true"
                                                />
                                              </Disclosure.Button>
                                              <Disclosure.Panel
                                                as="ul"
                                                className={`px-10 ${
                                                  item?.current
                                                    ? "bg-[#101010]"
                                                    : "bg-[#101010]"
                                                }`}
                                              >
                                                {item?.children?.map(
                                                  (subItem) => (
                                                    <li
                                                      key={subItem.name}
                                                      className="flex items-center space-x-5"
                                                    >
                                                      {/* <img
                                                        src={subItem?.icon}
                                                      /> */}
                                                      {subItem.icon && (
                                                        <subItem.icon />
                                                      )}

                                                      <a
                                                        href={subItem.href}
                                                        className={classNames(
                                                          subItem.current
                                                            ? "bg-[#101010]"
                                                            : "hover:bg-[#101010]",
                                                          "block py-2 text-2xl font-normal text-white uppercase tracking-wider font-ano"
                                                        )}
                                                      >
                                                        {subItem.name}
                                                      </a>
                                                    </li>
                                                  )
                                                )}
                                              </Disclosure.Panel>
                                            </>
                                          )}
                                        </Disclosure>
                                      )}
                                    </li>
                                  </>
                                ))}

                                <ul className="mb-3">
                                  {navigation2.map((item) => (
                                    <li key={item.name} className="">
                                      {!item.children ? (
                                        <div className="w-full">
                                          <div className="flex items-center space-x-5 px-10">
                                            {/* <img src={item?.icon} /> */}
                                            {item.icon && <item.icon />}

                                            <a
                                              href={item.href}
                                              className={classNames(
                                                item.current
                                                  ? "text-[#2C58C5]"
                                                  : "text-[#2C58C5]",
                                                "block py-2 text-2xl font-normal text-white uppercase tracking-wider font-ano"
                                              )}
                                            >
                                              {item.name}
                                            </a>
                                          </div>
                                        </div>
                                      ) : (
                                        <Disclosure as="div">
                                          {({ open }) => (
                                            <>
                                              <Disclosure.Button
                                                className={`flex items-center justify-between w-full px-10 py-2 ${
                                                  item.current
                                                    ? "bg-[#101010]"
                                                    : "hover:bg-[#101010]"
                                                }`}
                                              >
                                                <div
                                                  className={classNames(
                                                    "flex items-center space-x-8 cursor-pointer w-full text-2xl font-normal text-white uppercase tracking-wider font-ano"
                                                  )}
                                                >
                                                  {/* <img src={item?.icon} /> */}
                                                  {item.icon && <item.icon />}

                                                  <p>{item.name}</p>
                                                </div>
                                                <ChevronDownIcon
                                                  className={classNames(
                                                    open
                                                      ? "rotate-180 text-gray-500 "
                                                      : "text-gray-400",
                                                    "h-10 w-10 shrink-0 text-right"
                                                  )}
                                                  aria-hidden="true"
                                                />
                                              </Disclosure.Button>
                                              <Disclosure.Panel
                                                as="ul"
                                                className={`px-12 ${
                                                  item?.current
                                                    ? "bg-[#101010]"
                                                    : "bg-[#101010]"
                                                }`}
                                              >
                                                {item?.children?.map(
                                                  (subItem, subItemKey) => (
                                                    <li
                                                      key={subItemKey}
                                                      className="flex items-center space-x-8"
                                                    >
                                                      {/* <img src={item?.icon} /> */}
                                                      {item.icon && (
                                                        <item.icon />
                                                      )}

                                                      <a
                                                        href={item.href}
                                                        className={classNames(
                                                          subItem.current
                                                            ? "bg-[#101010]"
                                                            : "hover:bg-[#101010]",
                                                          "block py-2 text-2xl font-normal text-white uppercase tracking-wider font-ano"
                                                        )}
                                                      >
                                                        {subItem.name}
                                                      </a>
                                                    </li>
                                                  )
                                                )}
                                              </Disclosure.Panel>
                                            </>
                                          )}
                                        </Disclosure>
                                      )}
                                    </li>
                                  ))}
                                </ul>

                                {authToken && user && (
                                  <li className="">
                                    <div className="w-full">
                                      <div
                                        onClick={() => setShowDisconnectModal(true)}
                                        className="flex items-center space-x-8 px-12 mb-5"
                                      >
                                        <img
                                          className=" w-10  cursor-pointer"
                                          src={MobileLogoutBtn}
                                        />
                                        <p className="text-[#db1d1d] block py-2 text-2xl font-normal uppercase tracking-wider font-ano">
                                          logout
                                        </p>
                                      </div>
                                    </div>
                                  </li>
                                )}
                              </ul>
                            </li>
                          </ul>
                        </nav>
                      </div>
                    </div>
                  </Dialog.Panel>
                )}
              </Transition.Child>
            </div>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
    </>
  );
}
