import React from 'react';
import { useNavigate, useLocation } from 'react-router-dom';

const SparkNavigationSelect: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    navigate(event.target.value);
  };

  return (
    <select
      onChange={handleChange}
      value={location.pathname}
      className="lg:hidden block bg-[#3E7EFF] py-4 px-3 text-xl text-white font-ano font-semibold rounded-[150px] text-center mb-6 outline-none form-select appearance-none pr-8 pl-2 bg-no-repeat"
    >
      <option className="bg-[#1C1C1C]" value="/exchange">SHAREHOLDER EXCHANGE</option>
      <option className="bg-[#1C1C1C]" value="/share-history">SHARE HISTORY</option>
    </select>
  );
};

export default SparkNavigationSelect;
