import { Fragment, useEffect, useState } from "react";
import { Dialog, Disclosure, Menu, Transition } from "@headlessui/react";
import { Cog6ToothIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useAuth } from "../context";
import { useNavigate } from "react-router-dom";
import CrossIcon from "../assets/images/cross.svg";
import LockIcon from "../assets/images/lock.svg";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

interface NavigationItem {
  name: string;
  href?: string;
  // icon?: any;
  lock?: boolean;
  icon?: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
  current?: boolean;
  children?: NavigationItem[];
}

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const DashboardIcon = () => (
  <svg
    className="cursor-pointer stroke-white"
    width="15"
    height="14"
    viewBox="0 0 15 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.62168 13C5.4893 12.5732 6.46648 12.3333 7.5 12.3333C8.53352 12.3333 9.5107 12.5732 10.3783 13M4.12 10.3333H10.88C11.9721 10.3333 12.5182 10.3333 12.9353 10.1153C13.3022 9.9236 13.6005 9.61764 13.7875 9.24131C14 8.81349 14 8.25344 14 7.13333V4.2C14 3.0799 14 2.51984 13.7875 2.09202C13.6005 1.71569 13.3022 1.40973 12.9353 1.21799C12.5182 1 11.9721 1 10.88 1H4.12C3.0279 1 2.48185 1 2.06472 1.21799C1.6978 1.40973 1.39949 1.71569 1.21254 2.09202C1 2.51984 1 3.0799 1 4.2V7.13333C1 8.25344 1 8.81349 1.21254 9.24131C1.39949 9.61764 1.6978 9.9236 2.06472 10.1153C2.48185 10.3333 3.0279 10.3333 4.12 10.3333Z"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default function AdminSidebar() {
  const navigation: NavigationItem[] = [
    // {
    //   name: "Dashboard",
    //   icon: DashboardIcon,
    //   href: "/admin",
    //   current: false,
    //   lock: false
    // },
    {
      name: "Database",
      current: false,
      icon: DashboardIcon,
      lock: false,
      children: [
        { name: "Users", href: "/admin/users", icon: DashboardIcon },
        { name: "Spark contracts", href: "/admin/spark-contracts", icon: DashboardIcon },
        { name: "Hourly rewards", href: "/admin/hourly-rewards", icon: DashboardIcon },
        { name: "Builds under construction", href: "/admin/builds-under-construction", icon: DashboardIcon },
        { name: "Transactions", href: "/admin/transactions", icon: DashboardIcon }
      ]
    },

    {
      name: "Settings",
      current: false,
      icon: DashboardIcon,
      lock: false,
      children: [{ name: "Change fee", href: "/admin/change-fee", icon: DashboardIcon }]
    },

    {
      name: "Bank",
      current: false,
      icon: DashboardIcon,
      lock: false,
      children: [
        { name: "Reward upx", href: "/admin/reward-upx", icon: DashboardIcon },
        { name: "Withdraw upx", href: "/admin/debit-upx", icon: DashboardIcon }
      ]
    },

    {
      name: "Shareholders",
      current: false,
      icon: DashboardIcon,
      lock: false,
      children: [
        { name: "Shareholders", href: "/admin/shareholders", icon: DashboardIcon },
        { name: "Shares Trade", href: "/admin/share-market", icon: DashboardIcon },
        { name: "Shares Offers", href: "/admin/share-offers", icon: DashboardIcon },
        { name: "Shares Transactions", href: "/admin/share-transactions", icon: DashboardIcon },
      ]
    }
  ];

  return (
    <>
      <div className="pointer-events-auto h-full lg:w-96">
        <div className="flex h-full flex-col bg-[#171717] text-white shadow-xl">
          <div className="relative mt-8 flex-1 flex flex-col">
            <nav className="flex flex-1 flex-col">
              <ul role="list" className="flex flex-1 flex-col">
                {navigation.map((item, key) => (
                  <li key={key} className="">
                    {!item.children ? (
                      <div
                        className={`w-full ${item.lock ? "" : " text-white"}`}
                      >
                        <div className="flex items-center justify-between px-10">
                          <div className="flex items-center space-x-4">
                            {item.icon && <item.icon />}
                            <a
                              href={item.href}
                              className={classNames(
                                `block py-2 text-base font-bold tracking-wider font-ano hover:text-[#7F7F7F] ${
                                  item.lock ? "text-[#B5B5B5]" : ""
                                }`
                              )}
                            >
                              {item.name}
                            </a>
                          </div>
                          {item?.lock && (
                            <div className="flex items-center space-x-4">
                              <span className="font-normal text-xs text-[#B5B5B5] uppercase">
                                &nbsp;&nbsp; coming soon
                              </span>
                              <img src={LockIcon} className="h-6 w-6" />
                            </div>
                          )}
                        </div>
                      </div>
                    ) : (
                      <div className="mt-5">
                        <>
                          <div
                            className={`flex items-center justify-between w-full px-10  text-white mb-3`}
                          >
                            <div
                              className={classNames(
                                "flex items-center space-x-4 cursor-pointer w-full text-base font-bold tracking-wider font-ano text-[#7F7F7F]"
                              )}
                            >
                              {item.icon && <item.icon />}
                              <p>{item.name}</p>
                            </div>
                          </div>
                          <div className={`pl-10 ${item?.current ? "" : ""}`}>
                            {item?.children?.map((subItem, subItemKey) => (
                              <div
                                key={subItemKey}
                                className="flex cursor-pointer  items-center space-x-8 px-8"
                              >
                                <a
                                  href={subItem?.href}
                                  className={classNames(
                                    "block pb-1 text-sm text-white font-normal tracking-wider font-ano hover:text-[#7F7F7F]"
                                  )}
                                >
                                  {subItem.name}
                                </a>
                              </div>
                            ))}
                          </div>
                        </>
                      </div>
                    )}
                  </li>
                ))}
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
}
