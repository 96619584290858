import React, { Fragment, useEffect, useMemo, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";

import SearchIcon from "../assets/images/search_yellow_icon.svg";
import CopyIcon from "../assets/images/copy_icon.svg";
import CloseIcon from "../assets/images/close.svg";

interface Props {
  isOpen: boolean;
  onCancel: () => void;
}

interface City {
  address: string;
  city: string;
}

export default function CityListModal({ isOpen, onCancel }: Props) {
  const [searchText, setSearchText] = useState("");
  const [filteredCities, setFilteredCities] = useState<City[]>([]);

  const allCities = useMemo(
    () => [
      {
        address: "631 S AKARD ST",
        city: "Dallas",
      },
      {
        address: "31 MITTELSTRASSE",
        city: "Berlin",
      },
      {
        address: "45B LOWER THAMES ST",
        city: "London",
      },
      {
        address: "RUA FIGUEIREDO DE MAGALHAES, 263",
        city: "Rio de Janeiro",
      },
      {
        address: "7405 N FRESNO ST",
        city: "Fresno",
      },
      {
        address: "8744 APPERSON ST",
        city: "Los Angeles",
      },
      {
        address: "103 PORTALES REAL",
        city: "Bakersfield",
      },
      {
        address: "801 2ND AVE",
        city: "Manhattan",
      },
      {
        address: "AVENIDA DO PARQUE 162",
        city: "Porto",
      },
      {
        address: "4219 E Pinecrest cir",
        city: "Las Vegas",
      },
      {
        address: "2160 wallace ave bronx NYC",
        city: "bronx",
      },
      {
        address: "8515 w catalpa ave",
        city: "chicago",
      },
      {
        address: "1776 E 40 ST",
        city: "cleveland",
      },
      {
        address: "1700 scovel st",
        city: "nashville",
      },
      {
        address: "3800 eagle st",
        city: "new orleans",
      },
      {
        address: "4718 GILLMOR ST",
        city: "santa clara",
      },
      {
        address: "40 aviva ct",
        city: "staaten island",
      },
      {
        address: "89 mozart st",
        city: "rutherford",
      },
      {
        address: "59 john bright st",
        city: "birmingham",
      },
      {
        address: "204 willis ave",
        city: "arlington",
      },
      {
        address: "13000 schoolcraft st",
        city: "detroit",
      },
      {
        address: "caboto 300",
        city: "buenos aires",
      },
      {
        address: "7105 E 69th ter kansas city",
        city: "kansas city",
      },
      {
        address: "3220 Fruitvale Ave oakland",
        city: "Alameda/oakland",
      },
      {
        address: "8234 243RD ST",
        city: "queens",
      },
      {
        address: "320 EASTERN PKWY",
        city: "brooklyn",
      },
      {
        address: "AVENIDA PRESTES MAIA, 844",
        city: "Sao Paulo",
      },
      {
        address: "7 169 ST",
        city: "Lusail",
      },
      {
        address: "18 skyview way san fran",
        city: "san fransisco",
      },
      {
        address: "2-CHOME-14-13 SHIBUYA",
        city: "Tokyo",
      },
      {
        address: "3610 BAYVIEW RD   ",
        city: "Miami",
      },
    ],
    []
  );

  useEffect(() => {
    const filtered = searchText
      ? allCities.filter(
          (city) =>
            city.city.toLowerCase().includes(searchText.toLowerCase()) ||
            city.address.toLowerCase().includes(searchText.toLowerCase())
        )
      : allCities;

    setFilteredCities(filtered);
  }, [searchText, allCities]);

  const handleCopy = (text: string) => {
    navigator.clipboard.writeText(text);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <div className="fixed inset-0 bg-[#000000DE] transition-opacity" />

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-[#1C1C1C] text-left shadow-xl transition-all w-96 h-auto">
                <div className="flex items-center justify-between py-5 px-4 border-b border-[#979797] text-white font-ano font-bold text-[22px]">
                  <div></div>
                  <p>Locate a devshop</p>
                  <img
                    className="cursor-pointer"
                    src={CloseIcon}
                    onClick={onCancel}
                  />
                </div>

                <div className="pt-5 pb-10 px-8">
                  <div className="relative w-full">
                    <img
                      src={SearchIcon}
                      className="absolute right-4 top-2.5 cursor-pointer"
                    />
                    <input
                      type="text"
                      className="bg-[#1C1C1C] focus:border-[#3E7EFF] pl-4 w-full font-ano text-[#676767] font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                      placeholder="City"
                      value={searchText}
                      onChange={(e) => setSearchText(e.target.value)}
                    />
                  </div>
                  <div className="flex flex-col overflow-y-scroll custom-scrollbar h-80 mt-3 scroll-padding">
                    {filteredCities.map((city, index) => (
                      <div
                        key={index}
                        className="mt-3 mb-2 flex bg-[#232323] rounded-md px-4 py-2"
                      >
                        <p className="font-ano text-xl text-white">
                          {city.address}, {city.city}
                        </p>
                        <img
                          src={CopyIcon}
                          className="ml-3 cursor-pointer"
                          onClick={() =>
                            handleCopy(`${city.address}, ${city.city}`)
                          }
                        />
                      </div>
                    ))}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
}
