import { Accordion, AccordionItem as Item } from "@szhsin/react-accordion";

import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAuth } from "../context";
import ArrowDown from "../assets/images/arrow-down.svg";

const AccordionItem = ({ header, ...rest }) => (
  <Item
    {...rest}
    header={({ state: { isEnter } }) => (
      <>
        {header}
        <img
          className={`ml-auto transition-transform duration-200 ease-out ${
            isEnter && "rotate-180"
          }`}
          src={ArrowDown}
          alt="Chevron"
        />
      </>
    )}
    className="border-b border-[#2C2C2C]"
    buttonProps={{
      className: ({ isEnter }) =>
        `flex w-full p-4 text-left font-bold font-ano text-xl ${isEnter ? 'text-[#4F99F0]':'text-white'}`
    }}
    contentProps={{
      className: "transition-height duration-200 ease-out"
    }}
    panelProps={{ className: "p-4 pt-0 text-white font-ano font-normal text-lg" }}
  />
);

export function Faq() {
  const { authToken, user } = useAuth();

  return (
    <>
      <div className="h-full">
        <Header />
        <div className="container mx-auto lg:px-28 md:px-0 px-5">
          <div className="bg-[#1C1C1C] rounded-[15px] py-6 px-10 my-10">
            <p className="font-ano text-white font-bold text-3xl text-center py-2 border-b border-[#676767]">
              FAQs
            </p>
            <div>
              <div className="mx-2 my-4">
                <Accordion transition transitionTimeout={200}>
                  <AccordionItem header="What is UXPLORER.ME?">
                    UPXPLORER is a website that offers a number of services
                    related to Upland Metaverse registered users. Services
                    include Spark Market, Banking, Auctions and more.
                  </AccordionItem>

                  <AccordionItem header="What is the House of Spark - Spark Market?">
                    The House of Spark is a division of Upxplorer. Its function
                    is to provide a trusted platform to lend or rent spark from
                    registered users of Upland.
                  </AccordionItem>

                  <AccordionItem header="What is Spark?">
                    Spark is one of Uplands currency tokens. Spark is required
                    in order to construct inanimate objects in the Upland
                    metaverse. To begin construction of a structure, a player
                    must own property in Upland and have the minimum amount of
                    Spark that is required for the structure type.
                  </AccordionItem>

                  <AccordionItem header="What are Spark Hours?">
                    Spark hours refers to the number of hours it requires to
                    build an inanimate object in the Upland metaverse such as a
                    townhouse, apartment building, showroom, factory, etc.
                  </AccordionItem>

                  <AccordionItem header="How do I connect my Upxplorer.me account to my Upland account?">
                    Click on the Navigation icon top right corner. Click Spark
                    Exchange, Click Connect to Upland. Follow the step by step
                    instructions.
                  </AccordionItem>

                  <AccordionItem header="How is the Spark contribution rate determined?">
                    Structure builders are able to choose from a range of 5-80
                    UPX Per Spark hour to pay registered Uplanders that
                    contribute to structure builds. The higher the Spark hour
                    listed offered to contributors the more likely those builds
                    are to be fully contributed to.
                  </AccordionItem>

                  <AccordionItem header="How can an Uplander build a structure if they own no Spark?">
                    Services can be arranged to help Uplanders without Spark.
                    Use the Contact page to inquire about building with no Spark
                    or join the Upxplorer Discord and DM a Moderator to inquire
                    how.
                  </AccordionItem>

                  <AccordionItem header="Can I use my UPX balance as funds to pay Spark contributors?">
                    Yes. You can use UPX you earn from contributing to structure
                    builds for your own structure builds..
                  </AccordionItem>

                  <AccordionItem header="How do I transfer UPX into my Upxplorer.me account to pay for Spark rental?">
                    Log into upxplorer.me click profile, click UPX Deposit.
                    Follow the provided step by step instructions.
                  </AccordionItem>

                  <AccordionItem header="How do I contribute my Spark to build contracts on the Spark Market?">
                    Log into upxplorer.me and click on Spark Market. A list of
                    available build contracts are listed there. You can
                    contribute to any open contracts.
                  </AccordionItem>

                  <AccordionItem header="How much UPX can I earn daily?">
                    Spark Contributors can earn from 120 to 1920 UPX or more
                    daily.
                  </AccordionItem>
                  <AccordionItem header="What is the minimum amount of Spark I can contribute?">
                    The minimum is 0.01 Spark
                  </AccordionItem>
                  <AccordionItem header="What is the minimum amount of time I can contribute Spark to a build?">
                    There is no minimum. Contributors are paid UPX based upon
                    the time their Spark is contributed to a structure that is
                    in the process of it being built.
                  </AccordionItem>
                  <AccordionItem header="How can I monitor my Spark contributions on the Spark Market?">
                    Once logged into upxplorer.me click on Profile, click on
                    Spark Dashboard you will see your Stake Overview.
                  </AccordionItem>
                  <AccordionItem header="How do I check my UPX balance?">
                    Once logged into upxplorer.me your UPX balance is shown at
                    the top of the website.
                  </AccordionItem>

                  <AccordionItem header="When do I get paid my UPX for contributing my Spark to contracted builds?">
                    UPX is paid out to Spark contributors upon the completion of
                    a build or if the builder deconstructs a structure being
                    built prior to the finish of construction.
                  </AccordionItem>

                  <AccordionItem header="How do I request a withdrawal of my UPX?">
                    Log into https://upxplorer.me/ <br />
                    Click the navigation icon top right.
                    <br />
                    Click Profile
                    <br />
                    Click Request UPX Withdraw.
                    <br />
                    If KYC'd in Upland there is a 3000 UPX minimum UPX
                    withdrawal request.
                    <br />
                    If not KYC'd there is a 5000 UPX minimum withdrawal request.{" "}
                    <br />
                    For KYC information log into the Upland website/app, click
                    the 3 dots, click settings, click verify my identity.
                  </AccordionItem>

                  <AccordionItem header="Will I get a notification once my UPX withdrawal has been processed?">
                    Yes. After you request an UPX withdrawal you will receive a
                    notification.
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
