import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";
import Header from "../components/Header";
import Footer from "../components/Footer";
import { useAuth } from "../context";
import InfoIcon from "../assets/images/info.svg";

import CopyIcon from "../assets/images/copy.svg";
import ConnectUplandSteps from "../components/ConnectUplandSteps";
import { useNavigate } from "react-router-dom";

interface UplandAuthData {
  code: string;
  expireAt: string;
}

export function ConnectUpland() {
  const [isConnected, setIsConnected] = useState(false);
  const navigate = useNavigate();
  const notify = useNotification();
  const [showUplandConnectStepsModal, setShowUplandConnectStepsModal] =
    useState(false);

  const [showUplandConnectBox, setShowUplandConnectBox] = useState(true);

  const [uplandAuthData, setUplandAuthData] = useState<UplandAuthData | null>(
    null
  );
  const { authToken, user, setUser, updateAuthToken } = useAuth();
  const { sendRequest: continueSignupApi, isLoading } = useHttp();
  const { sendRequest: getUserApi, isLoading: isLoadingGetUserApi } = useHttp();

  const {
    sendRequest: continueVerifyUplandOtpApi,
    isLoading: isLoadingVerifyUplandOtpApi
  } = useHttp();

  // useEffect(() => {
  //   if (user?.upland_auth_token) {
  //     navigate("/home");
  //   }
  // }, [user?.upland_auth_token]);

  const connectUplandHandler = () => {
    continueSignupApi(
      {
        url: "api/connect-upland",
        method: "POST",
        payload: JSON.stringify({ email: user?.userEmail }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }
        setShowUplandConnectBox(false);
        setUplandAuthData(data);
      }
    );
  };

  const verifyUplandOtpHandler = () => {
    // setIsConnected(true);

    continueVerifyUplandOtpApi(
      {
        url: "api/verify-upland-otp",
        method: "POST",
        payload: JSON.stringify({
          email: user?.userEmail,
          uplandCode: uplandAuthData?.code
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error, "error");
          console.log("Message", data?.error);
          return;
        }
        setShowUplandConnectBox(false);
        // setIsConnected(data?.isVerified);
        if (data?.isVerified) {
          getUserApi(
            {
              url: "api/user",
              method: "GET",
              payload: {},
              headers: {
                "Content-Type": "application/json",
                Authorization: authToken || ""
              }
            },
            (data: any) => {
              if (data?.error) {
                notify(data?.error, "error");
                console.log("Message", data?.error);
                localStorage.removeItem("authToken");
                updateAuthToken("");
                setUser(null);
                return;
              }
              navigate("/home");
              setUser(data);
            }
          );
        }
      }
    );
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Handle successful copy
        notify("Copied", "success");
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        // Handle error case
        console.error("Error in copying text: ", err);
      });
  };

  return (
    <>
      <ConnectUplandSteps
        isOpen={showUplandConnectStepsModal}
        onCancel={() => setShowUplandConnectStepsModal(false)}
      />
      <div className="flex flex-col justify-between h-full">
        <Header />
        <div className="my-10 px-5 h-full">
          <div className="flex flex-col items-center justify-center h-full">
            <p className="font-ano lg:text-4xl text-2xl font-extrabold text-white lg:mt-0 mt-12">
              Welcome to UPXPLORER.ME
            </p>
            <p className="lg:block hidden font-ano lg:text-2xl text-xl font-normal mt-4 text-[#979797]">
              Spark Market - Auction - Banking
            </p>
            <p className="lg:hidden block text-center font-ano text-xl lg:mt-0 mt-4 font-normal text-[#979797]">
              Spark Market <br /> Auction <br /> Banking
            </p>
            {}
            {(user?.upland_auth_token === "" ||
              user?.upland_auth_token === null) &&
              uplandAuthData === null && (
                <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10 lg:mb-0 mb-10">
                  {/* <p className="font-ano font-bold lg:text-2xl text-xl text-[#1EA067] text-center mb-6">
                  THANK YOU FOR REGISTRATION
                </p> */}
                  <p className="mb-1 font-ano font-normal lg:text-lg overflow-y-scroll custom-scrollbar h-80 scroll-padding text-base text-white">
                    Conditions of use <br />
                    <br />
                    By using this website, you have read and reviewed this
                    Agreement and you agree to comply with its terms. If you do
                    not want to be bound by the terms of this Agreement, you are
                    advised to leave the website accordingly. Upxplorer only
                    grants use of this website, its products, and its services
                    to those who have accepted its terms.
                    <br />
                    Privacy Policy
                    <br />
                    <br />
                    Before you continue using our website, we advise you to read
                    our privacy policy regarding our user data collection.
                    <br />
                    Age restriction
                    <br />
                    <br />
                    You must be at least 18 (eighteen) years of age or be
                    accompanied by a legal parent or guardian while using this
                    website. By using this website you warrant that you are at
                    least 18 years of age or are accessing it while accompanied
                    by a legal parent or guardian. Upxplorer assumes no
                    responsibility for liabilities related to the age of
                    misrepresentation.
                    <br />
                    Terms of service
                    <br />
                    <br />
                    The use of the services offered by Upxplorer is the sole
                    responsibility of the acting user. Registration and use of
                    basic services of Upxplorer for the user are available
                    without a fee for an undetermined amount of time and subject
                    to change. Premium services are marked accordingly.
                    Upxplorer may offer new services that require a subscription
                    fee and may discontinue any services at any time without
                    providing reasons and without prior notice. If the User does
                    not agree with any website services or functionality
                    including future changes to the services, the User may stop
                    using the services without any further obligations.
                    <br />
                    Intellectual Property
                    <br />
                    <br />
                    You agree that all materials, products, and services
                    provided on this website are property of Upxplorer, its
                    affiliates, directors, officers, employees, agents,
                    suppliers, advertisers, or licensors including all
                    copyrights, trade secrets, trademarks, patents, and other
                    intellectual property in any way, including electronic,
                    digital or new trademark registrations.
                    <br />
                    User Accounts
                    <br />
                    <br />
                    As a user of this website, you may be asked to register with
                    Upxplorer and provide personal or private information. You
                    are responsible for ensuring the accuracy of this
                    information and you are responsible for maintaining the
                    safety and security of your identifying information. You are
                    also responsible for all activities that occur under your
                    account or password. Limitation of liability
                    <br />
                    <br />
                    Upxplorer is not liable for any damages that may occur to
                    you as a result of your misuse of this website.
                    <br />
                    Privacy Policy
                    <br />
                    <br />
                    Upxplorer collects information to provide better services to
                    all our users. Information collected by Upxplorer is used to
                    offer the best user experience. User activity while logged
                    into Upxplorer is utilized to optimize the information and
                    services that Upxplorer provides. User data is not shared
                    with any third party.
                    <br />
                    Upxplorer reserves the right to edit, modify, and change
                    this agreement at any time. Upxplorer shall make users aware
                    of these changes via Discord/ electronic email and on the
                    website itself. Agreement is an understanding between
                    Upxplorer and the user and this supersedes and replaces all
                    prior agreements regarding the use of this website.
                    <br />© 2019 - 2023 Upxplorer
                  </p>

                  <div
                    onClick={connectUplandHandler}
                    className="w-auto mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center py-2 px-12 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Connect{" "}
                    <span className="text-[#f8ff1f] font-extrabold">
                      Upland
                    </span>
                  </div>
                </div>
              )}

            {uplandAuthData !== null && !showUplandConnectBox && (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold lg:text-2xl text-xl text-white text-center mb-6">
                  UPLAND VERIFICATION CODE
                </p>
                <div className="flex items-center justify-between space-x-5">
                  <p onClick={() =>
                      uplandAuthData?.code &&
                      copyToClipboard(uplandAuthData.code)
                    } className="mb-1 cursor-pointer font-ano font-normal lg:text-5xl text-3xl text-[#F9F41F]">
                    {uplandAuthData?.code}
                  </p>
                  <img
                    onClick={() =>
                      uplandAuthData?.code &&
                      copyToClipboard(uplandAuthData.code)
                    }
                    src={CopyIcon}
                    className="cursor-pointer"
                  />
                </div>

                <div className="flex lg:flex-row flex-col items-center justify-between lg:space-x-5 space-x-0 lg:w-auto w-full">
                  <div
                    onClick={connectUplandHandler}
                    className="lg:w-52 w-full mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold text-[22px] text-center lg:py-2 py-1 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Regenerate
                  </div>

                  <div
                    onClick={verifyUplandOtpHandler}
                    className="lg:w-52 w-full mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#F9F41F] border-[#F9F41F] font-ano font-bold text-[22px] text-center lg:py-2 py-1 px-6 bg-[#1C1C1C] uppercase text-white hover:text-black outline-none"
                  >
                    Verify
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-3 mt-8 mb-2">
                  <img src={InfoIcon} className="w-4 h-4" />
                  <p
                    onClick={() => setShowUplandConnectStepsModal(true)}
                    className="font-ano font-bold text-base text-[#979797] text-center cursor-pointer"
                  >
                    Show me how
                  </p>
                </div>
              </div>
            )}

            {/* {(isConnected || user?.upland_auth_token) && (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  THANK YOU FOR VERIFICATION
                </p>
                <p className="font-ano font-medium lg:text-5xl text-3xl text-[#1EA067] text-center mb-6">
                  Connection Successful
                </p>

                <div className="flex items-center justify-between space-x-5">
                  <div
                    onClick={() => navigate("/deposit")}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#F9F41F] border-[#F9F41F] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Deposit UPX
                  </div>
                </div>
              </div>
            )} */}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
