import { MagnifyingGlassIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../../context";
import _ from "lodash";
import SearchDropdown from "../../common/components/SearchDropdown";

interface Transactions {
  container_id: number | null;
  created_at: string | null;
  destination_username: string;
  destination_wallet: string;
  initiated_by: string;
  memo: string;
  source_username: string;
  source_wallet: string;
  status: string;
  transaction_id: string;
  type: string;
  updated_at: string;
  upland_username: string;
  upx_amount: string;
}

export interface DropdownItem {
  id: string;
  label: string;
  value: string;
  href?: string;
  onClick?: () => void;
}

export default function AdminTransactions() {

  const [selectedItem, setSelectedItem] = useState<DropdownItem | null>(null);

  const menuItems: DropdownItem[] = [
    { id: "1", label: "Username", value: "upland_username", href: "#" },
    { id: "2", label: "From", value: "source_wallet", href: "#" },
    { id: "3", label: "To", value: "destination_wallet", href: "#" }
  ];
  const handleItemSelect = (item: DropdownItem) => {
    setSelectedItem(item);
    // setSortField(item.value)
    console.log("Selected item:", item.value);
  };

  const { authToken, user } = useAuth();
  const [transactions, setTransactions] = useState<Transactions[]>([]);
  const navigate = useNavigate();

  const [sortField, setSortField] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("desc");
  const [searchQuery, setSearchQuery] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [totalPages, setTotalPages] = useState(0);

  const {
    sendRequest: getTransactionsApi,
    isLoading: isLoadingTransactionsApi
  } = useHttp();

  const getTransactions = () => {
    const queryParams = `page=${currentPage}&pageSize=${pageSize}&sortField=${sortField}&sortDirection=${sortDirection}&searchQuery=${searchQuery}`;

    getTransactionsApi(
      {
        url: `api/txn-list?${queryParams}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        console.log("data", data);

        setTransactions(data.transactions || []);
        setTotalPages(data?.pagination?.totalPages);
      }
    );
  };

  useEffect(() => {
    const debouncedGetTransactions = _.debounce(() => {
      getTransactions();
    }, 500);

    debouncedGetTransactions();

    return () => {
      debouncedGetTransactions.cancel();
    };
  }, [sortField, sortDirection, currentPage, searchQuery]);

  const handleSort = (field) => {
    if (sortField === field) {
      setSortDirection(sortDirection === "asc" ? "desc" : "asc");
    } else {
      setSortField(field);
      setSortDirection("asc");
    }
  };
  const renderPagination = () => {
    if (totalPages <= 1) {
      return null;
    }

    let pages: JSX.Element[] = [];
    let startPage: number, endPage: number;

    const paginationCutOff = 5;

    if (totalPages <= paginationCutOff) {
      startPage = 1;
      endPage = totalPages;
    } else {
      if (currentPage <= 3) {
        startPage = 1;
        endPage = paginationCutOff;
      } else if (currentPage + 2 >= totalPages) {
        startPage = totalPages - (paginationCutOff - 1);
        endPage = totalPages;
      } else {
        startPage = currentPage - 2;
        endPage = currentPage + 2;
      }
    }

    // First page
    if (startPage > 1) {
      pages.push(
        <button
          key={1}
          className="text-black"
          onClick={() => setCurrentPage(1)}
        >
          [1]
        </button>
      );
      pages.push(
        <span key="start-ellipsis" className="text-black">
          ...
        </span>
      );
    }

    // Middle pages
    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <button
          key={i}
          className={`px-1 ${
            i === currentPage ? "text-[#4F99F0]" : "text-black"
          }`}
          onClick={() => setCurrentPage(i)}
        >
          [{i}]
        </button>
      );
    }

    // Last page
    if (endPage < totalPages) {
      pages.push(
        <span key="end-ellipsis" className="text-black">
          ...
        </span>
      );
      pages.push(
        <button
          key={totalPages}
          className="text-black"
          onClick={() => setCurrentPage(totalPages)}
        >
          [{totalPages}]
        </button>
      );
    }

    return <div className="flex space-x-2">{pages}</div>;
  };

  const formatDateWithTime = (dateString: string | null): string => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  function formatNumberToTwoDecimals(value: any) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    return Number(value).toFixed(2);
  }

  return (
    <div className="bg-white w-full px-14 pt-10">
      <p className="font-ano font-bold text-3xl text-black">Transactions</p>

      <div className="flex items-center space-x-4 mt-5">
        <div className="relative rounded-md">
          <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
            <MagnifyingGlassIcon
              className="h-5 w-5 text-gray-400"
              aria-hidden="true"
            />
          </div>
          <input
            type="text"
            name="abc"
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            className="rounded-full w- border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
            placeholder="Search"
          />
        </div>

        <SearchDropdown
          items={menuItems}
          buttonLabel={selectedItem?.label || 'Field'}
          onItemSelect={handleItemSelect}
        />
      </div>

      <div className="overflow-x-auto">
        <div className="inline-block min-w-full py-2">
          <table className="min-w-full divide-y divide-gray-300">
            <thead>
              <tr>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868] table-cell"
                >
                  ID
                </th>
                <th
                  onClick={() => handleSort("upland_username")}
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868] table-cell hover:text-black cursor-pointer"
                >
                  Username
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868] table-cell"
                >
                  Upx amount
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868] table-cell"
                >
                  Upxplorer Tax
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868]"
                >
                  Status
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868]"
                >
                  Type
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868]"
                >
                  Memo
                </th>

                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868]"
                >
                  Transaction time
                </th>
                <th
                  scope="col"
                  className="px-3 py-3.5 text-left text-base font-semibold text-[#686868]"
                >
                  Action
                </th>
              </tr>
            </thead>
            <tbody className="divide-y divide-gray-200 bg-white">
              {isLoadingTransactionsApi ? (
                <tr>
                  <td
                    colSpan={9}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-black sm:pl-0"
                  >
                    <div className="text-center py-10">Loading...</div>
                  </td>
                </tr>
              ) : transactions.length > 0 ? (
                transactions.map((txn, key) => (
                  <tr key={key}>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-black sm:pl-0">
                      {txn.transaction_id}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-4 pr-3 text-base text-black sm:pl-0">
                      {txn.upland_username}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-base text-black table-cell">
                      {formatNumberToTwoDecimals(txn.upx_amount)}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-base text-black table-cell">
                      ---
                    </td>
                    <td className="whitespace-nowrap capitalize px-3 py-4 text-base text-black table-cell">
                      {txn.status === "final"
                        ? "Pending"
                        : txn.status === "signed"
                        ? "In Progress"
                        : txn.status === "created"
                        ? "Processing"
                        : txn.status}
                    </td>
                    <td className="whitespace-nowrap capitalize px-3 py-4 text-base text-black table-cell">
                      {txn.type}
                    </td>
                    <td className="whitespace-nowrap capitalize px-3 py-4 text-base text-black">
                      {txn.memo}
                    </td>
                    <td className="whitespace-nowrap px-3 py-4 text-base text-black">
                      {formatDateWithTime(txn.created_at)}
                    </td>
                    <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-base font-medium sm:pr-0">
                      <svg
                        className="cursor-pointer"
                        onClick={() =>
                          navigate(`/admin/transaction/${txn?.transaction_id}`)
                        }
                        width="17"
                        height="17"
                        viewBox="0 0 17 17"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M13 1L16 4M1 16L1.9573 12.4899C2.01976 12.2609 2.05098 12.1464 2.09892 12.0396C2.14149 11.9448 2.19379 11.8547 2.25499 11.7707C2.32391 11.6761 2.40783 11.5922 2.57568 11.4243L10.3257 3.67426C10.4742 3.52576 10.5485 3.4515 10.6341 3.42368C10.7094 3.39921 10.7906 3.39921 10.8659 3.42368C10.9515 3.4515 11.0258 3.52576 11.1743 3.67426L13.3257 5.82574C13.4742 5.97424 13.5485 6.0485 13.5763 6.13412C13.6008 6.20943 13.6008 6.29057 13.5763 6.36588C13.5485 6.4515 13.4742 6.52576 13.3257 6.67426L5.57568 14.4243C5.40783 14.5922 5.32391 14.6761 5.22931 14.745C5.14532 14.8062 5.05519 14.8585 4.96039 14.9011C4.85361 14.949 4.73911 14.9802 4.5101 15.0427L1 16Z"
                          stroke="black"
                          stroke-width="1.5"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td
                    colSpan={9}
                    className="whitespace-nowrap py-4 pl-4 pr-3 text-base font-medium text-black sm:pl-0"
                  >
                    <div className="text-center py-10">
                      No transactions found
                    </div>
                  </td>
                </tr>
              )}
            </tbody>
          </table>
        </div>
      </div>

      {transactions.length > 0 && (
        <div className="flex justify-center items-center my-4 text-black">
          {renderPagination()}
        </div>
      )}
    </div>
  );
}
