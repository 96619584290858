import React from "react";
import Footer from "../components/Footer";
import Header from "../components/Header";
import BackIcon from "../assets/images/back.svg";
import { useNavigate } from "react-router-dom";

export function TermsAndCondition() {
  const navigate = useNavigate();
  return (
    <div className="h-full">
      <Header />
      <div className="px-5 my-10 container mx-auto">
        <div className="flex items-center justify-between lg:mt-0 mt-12 mb-6">
          <img
            src={BackIcon}
            className="w-8 cursor-pointer"
            onClick={() => navigate(-1)}
          />
        <p className="uppercase font-ano lg:text-4xl text-2xl text-center font-extrabold text-white">
          Terms & Conditions
        </p>
          <div></div>
        </div>
        <p className="font-ano font-normal text-white bg-[#1C1C1C] p-4 rounded-lg">
          <span className="font-semibold text-lg">Conditions of use</span>{" "}
          <br />
          By using this website, you have read and reviewed this Agreement and
          you agree to comply with its terms. If you do not want to be bound by
          the terms of this Agreement, you are advised to leave the website
          accordingly. Upxplorer only grants use of this website, its products,
          and its services to those who have accepted its terms.
          <br />
          <br />
          <span className="font-semibold text-lg">Privacy Policy</span>
          <br />
          Before you continue using our website, we advise you to read our
          privacy policy regarding our user data collection.
          <br />
          <br />
          <span className="font-semibold text-lg">Age restriction</span>
          <br />
          You must be at least 18 (eighteen) years of age or be accompanied by a
          legal parent or guardian while using this website. By using this
          website you warrant that you are at least 18 years of age or are
          accessing it while accompanied by a legal parent or guardian.
          Upxplorer assumes no responsibility for liabilities related to the age
          of misrepresentation.
          <br />
          <br />
          <span className="font-semibold text-lg">Terms of service</span>
          <br />
          The use of the services offered by Upxplorer is the sole
          responsibility of the acting user. Registration and use of basic
          services of Upxplorer for the user are available without a fee for an
          undetermined amount of time and subject to change. Premium services
          are marked accordingly. Upxplorer may offer new services that require
          a subscription fee and may discontinue any services at any time
          without providing reasons and without prior notice. If the User does
          not agree with any website services or functionality including future
          changes to the services, the User may stop using the services without
          any further obligations.
          <br />
          <br />
          <span className="font-semibold text-lg">Intellectual Property</span>
          <br />
          You agree that all materials, products, and services provided on this
          website are property of Upxplorer, its affiliates, directors,
          officers, employees, agents, suppliers, advertisers, or licensors
          including all copyrights, trade secrets, trademarks, patents, and
          other intellectual property in any way, including electronic, digital
          or new trademark registrations.
          <br />
          <br />
          <span className="font-semibold text-lg">User Accounts</span>
          <br />
          As a user of this website, you may be asked to register with Upxplorer
          and provide personal or private information. You are responsible for
          ensuring the accuracy of this information and you are responsible for
          maintaining the safety and security of your identifying information.
          You are also responsible for all activities that occur under your
          account or password. Limitation of liability
          <br />
          <br />
          Upxplorer is not liable for any damages that may occur to you as a
          result of your misuse of this website.
          <br />
          <br />
          <span className="font-semibold text-lg">Privacy Policy</span>
          <br />
          Upxplorer collects information to provide better services to all our
          users. Information collected by Upxplorer is used to offer the best
          user experience. User activity while logged into Upxplorer is utilized
          to optimize the information and services that Upxplorer provides. User
          data is not shared with any third party.
          <br />
          Upxplorer reserves the right to edit, modify, and change this
          agreement at any time. Upxplorer shall make users aware of these
          changes via Discord/ electronic email and on the website itself.
          Agreement is an understanding between Upxplorer and the user and this
          supersedes and replaces all prior agreements regarding the use of this
          website.
          <br />© 2019 - 2023 Upxplorer
        </p>
      </div>
      <Footer />
    </div>
  );
}
