import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp, useNotification } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import {
  parseISO,
  differenceInDays,
  differenceInHours,
  differenceInSeconds,
  differenceInCalendarDays
} from "date-fns";

import { useParams } from "react-router-dom";
import BlueRedirectIcon from "../assets/images/redirect_blue.svg";

interface PropertyDetail {
  property_id: number;
  email_address: string;
  current_status: string;
  property_type: string;
  address: string;
  city: string;
  price: string;
  eos_id: string;
  buildId: number;
  build_status: string;
  start_time: string | undefined;
  complete_time: string | undefined;
  max_stacked_spark: number;
  current_stacked_spark: number;
}

export function SparkCalculate() {
  const { authToken, user } = useAuth();
  const notify = useNotification();
  const [error, setError] = useState("");
  const [userUpxBalance, setUserUpxBalance] = useState("");
  const [property, setPropertyById] = useState<PropertyDetail | null>(null);
  const { sparkContractId } = useParams<string>();

  const [upxHr, setUpxHr] = useState("");
  const [totalUpxPool, setTotalUpxPool] = useState<Number>(0);
  const [daysDifference, setDaysDifference] = useState<Number>(0);
  const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

  const {
    sendRequest: getPropertyDetailApi,
    isLoading: isLoadingPropertyDetailApi
  } = useHttp();

  const {
    sendRequest: fetchUserUpxBalanceApi,
    isLoading: isLoadingFetchUserUpxBalanceApi
  } = useHttp();

  const {
    sendRequest: createListingApi,
    isLoading: isLoadingCreateListingApi
  } = useHttp();

  useEffect(() => {
    if (authToken && user && sparkContractId) {
      fetchPropertyDetailById(sparkContractId);
      fetchUserUpxBalance();
    }
  }, [authToken, user?.email_address, sparkContractId]); // Make sure the dependency array is empty to avoid repeated calls

  const fetchPropertyDetailById = (sparkContractId: string) => {
    setError("");

    getPropertyDetailApi(
      {
        url: `api/contract/${sparkContractId}`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }

        setPropertyById(data.contract || []); // Set the transactions or an empty array if undefined
      }
    );
  };

  const fetchUserUpxBalance = () => {
    fetchUserUpxBalanceApi(
      {
        url: `api/balance`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }

        setUserUpxBalance(data?.results?.upx_balance);
      }
    );
  };

  const handleUpxHrChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUpxHr(event.target.value);

    if (!event.target.value || isNaN(Number(event.target.value))) {
      setError("Please enter a valid UPX/hr value.");
      return;
    }
  };

  const handleCheckboxChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setIsCheckboxChecked(event.currentTarget.checked);
  };

  const createListingHandler = () => {
    if (!isCheckboxChecked) {
      setError("Please check the agreement checkbox.");
      return;
    }

    if (!upxHr || isNaN(Number(upxHr))) {
      setError("Please enter a valid UPX/hr value.");
      return;
    }

    console.log("property: ", property);
    console.log("email: ", user?.email_address);
    console.log("propertyId: ", property?.property_id);
    console.log("totalUpxPool: ", totalUpxPool);
    console.log("buildId: ", property?.buildId);
    console.log("status: ", "In Progress");
    console.log("upxHr: ", upxHr);
    console.log("remainingUpxAmount: ", totalUpxPool);

    setError(""); // Reset previous errors
    createListingApi(
      {
        url: "api/create-listing",
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address,
          propertyId: property?.property_id,
          totalUpxPool: totalUpxPool,
          buildId: property?.buildId,
          status: "In Progress",
          upxHr: upxHr,
          remainingUpxAmount: totalUpxPool
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          // handle error scenario
          setError(data.error);
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
        }
      }
    );
  };

  const calculateRemainingTime = (
    startTime: string | undefined,
    completeTime: string | undefined
  ): string => {
    if (!startTime || !completeTime) {
      return "N/A"; // Return 'N/A' or any appropriate placeholder when dates are null
    }

    const startDate = parseISO(startTime);
    const completeDate = parseISO(completeTime);
    const currentDate = new Date();

    if (currentDate > completeDate) {
      return "Time expired"; // Return this or a similar message if the current date is past the complete date
    }

    const days = differenceInDays(completeDate, currentDate);
    const hours = differenceInHours(completeDate, currentDate) % 24;
    const seconds = differenceInSeconds(completeDate, currentDate) % 60;

    return `${days} day${days !== 1 ? "s" : ""} ${hours} hour${
      hours !== 1 ? "s" : ""
    } ${seconds} second${seconds !== 1 ? "s" : ""}`;
  };

  return (
    <>
      <div className="h-full flex flex-col">
        <Header />
        <div className="container mx-auto flex-1">
          <div className="flex flex-col space-y-8">
            <div className="mt-10 px-5">
              <div className="grid grid-cols-6 gap-10">
                <div className="col-span-2">
                  <p className="uppercase font-ano font-bold text-2xl text-white mb-5">
                    PROPERTY DETAILS
                  </p>
                  <div className="flex flex-col bg-[#1C1C1C] rounded-2xl px-6 py-4">
                    <div>
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Address
                      </p>

                      <div className="flex items-start justify-between space-x-10 mt-3">
                        <p className="font-ano text-lg text-white font-bold">
                          {property?.address} {property?.city}
                        </p>
                        <p className="bg-[#F9F41F] capitalize font-ano font-bold text-xs text-white p-1 w-28 text-center">
                          {property?.build_status}
                        </p>
                      </div>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Type
                      </p>

                      <p className="font-ano capitalize text-xl text-white font-normal">
                        {property?.property_type}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Staked Spark
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {property?.current_stacked_spark}/
                        {property?.max_stacked_spark}{" "}
                        <span className="text-[#4F99F0]">SPARK</span>
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Remaining UPX Pool
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        ---{" "}
                        <span className="font-bold text-[#F9F41F]">UPX</span>
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        UPX/hr
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {upxHr ? upxHr : ""}
                        {upxHr ? (
                          <span className="font-bold text-[#F9F41F]"> UPX</span>
                        ) : (
                          "---"
                        )}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Owner
                      </p>

                      <p className="font-ano text-lg text-white font-bold">
                        {property?.eos_id}
                      </p>
                    </div>

                    <div className="mt-4">
                      <p className="font-ano text-[#999999] font-normal text-xl border-b border-[#2C2C2C]">
                        Build Progress
                      </p>

                      <div className="mt-2">
                        {/* <ProgressBar
                          startedAt={property?.start_time}
                          finishedAt={property?.complete_time}
                        /> */}
                      </div>
                      <p className="font-ano text-sm text-[#999999] float-right mt-4 font-bold">
                        {/* Remaining Time :{" "}
                        {calculateRemainingTime(
                          property?.start_time,
                          property?.complete_time
                        )} */}
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-span-4 flex flex-col space-y-4">
                  <div>
                    <p className="uppercase font-ano font-bold text-2xl text-white mb-5">
                      Spark Calculator
                    </p>

                    <div className="rounded-2xl grid grid-cols-2">
                      <div className="bg-[#1C1C1C] rounded-tl-2xl rounded-bl-2xl">
                        <div className="px-4 py-6 flex flex-col">
                          <div className="flex flex-col">
                            <div className="lg:w-auto w-full px-2 pt-4">
                              <div className="flex items-center justify-between">
                                <p className="font-ano font-normal text-base mb-1 text-white">
                                  Spark Amount
                                </p>
                                <div className="font-ano text-base font-normal text-[#999999]">
                                  Spark Balance:{" "}
                                  {new Intl.NumberFormat().format(
                                    Number(userUpxBalance) || 0
                                  )}
                                </div>
                              </div>
                              <div className="relative">
                                <input
                                  type="text"
                                  className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                  placeholder=""
                                  value={upxHr}
                                  onChange={handleUpxHrChange}
                                />
                                <p className="absolute cursor-pointer lg:top-2.5 top-1.5 right-5 font-ano text-[#F9F41F] font-bold lg:text-2xl text-lg">
                                  MAX
                                </p>
                              </div>
                            </div>

                            <div className="lg:w-auto w-full mt-4 px-2 pt-4">
                              <p className="font-ano font-normal text-base mb-1 text-white">
                                Duration
                              </p>
                              <div className="relative">
                                <input
                                  type="text"
                                  className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                                  placeholder=""
                                  value={upxHr}
                                  onChange={handleUpxHrChange}
                                />
                                <p className="absolute cursor-pointer lg:top-2.5 top-1.5 right-5 font-ano text-[#F9F41F] font-bold lg:text-2xl text-lg">
                                  MAX
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="bg-[#272727] rounded-tr-2xl h-full rounded-br-2xl">
                        <div className="flex flex-col px-8 py-3 h-full">
                          <p className="text-[#999999] font-ano font-normal text-center text-lg border-b border-[#676767] mb-2">
                            EARNINGS ESTIMATIONS
                          </p>

                          <div className="flex items-center justify-between space-x-5 mt-4">
                            <p className="font-ano font-normal text-lg text-[#999999]">
                              Staked Spark
                            </p>
                            <p className="font-ano font-semibold text-xl text-white">
                              10{" "}
                              <span className="text-[#4F99F0] uppercase">
                                Spark
                              </span>
                            </p>
                          </div>

                          <div className="flex items-center justify-between space-x-5 mt-3">
                            <p className="font-ano font-bold text-lg text-white">
                              Current Daily Earnings
                            </p>
                            <p className="font-ano font-semibold text-xl text-white">
                              <span>{totalUpxPool.toString()}</span>{" "}
                              <span className="text-[#F9F41F]">UPX</span>
                            </p>
                          </div>
                          <div className="flex items-center justify-between space-x-5 mt-3">
                            <p className="font-ano font-bold text-lg text-white">
                              New Daily Earnings
                            </p>
                            <p className="font-ano font-semibold text-xl text-white">
                              <span>{totalUpxPool.toString()}</span>{" "}
                              <span className="text-[#F9F41F]">UPX</span>
                            </p>
                          </div>

                          <div className="flex items-center justify-end mt-10">
                            <a
                              href={`https://play.upland.me/?prop_id=${property?.property_id}`}
                              target="_blank"
                              onClick={(e) => {
                                e.stopPropagation();
                              }}
                            >
                              <p className="font-ano text-[#4F99F0] text-base cursor-pointer flex font-normal">
                                <span>Stake in upland</span>
                                <img src={BlueRedirectIcon} className="ml-2" />
                              </p>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div>
                    <div className="flex items-center mb-2">
                      <div className="flex items-center space-x-5">
                        <p className="uppercase font-ano font-bold text-2xl text-white">
                          STAKE HISTORY
                        </p>

                        <div className="flex items-center space-x-3">
                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            SPARK LOGS
                          </p>

                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            COMPLETED CONTRACTS
                          </p>
                        </div>
                      </div>
                    </div>

                    <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6 mb-10">
                      <div className="flow-root">
                        <div className="overflow-x-auto">
                          <div className="inline-block min-w-full py-2">
                            <table className="min-w-full divide-y divide-[#2C2C2C]">
                              <thead>
                                <tr>
                                  <th
                                    scope="col"
                                    className="py-3.5 text-center font-ano font-bold text-xl text-white"
                                  >
                                    Username
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Staked Spark
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Stake Time
                                  </th>
                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Unstaked time
                                  </th>

                                  <th
                                    scope="col"
                                    className="text-center font-ano font-bold text-xl text-white"
                                  >
                                    Rewards
                                  </th>
                                </tr>
                              </thead>
                              <tbody className="divide-y divide-[#2C2C2C]">
                                <tr>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    afsdf
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    affasdf
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                    12/23/2023 07:12
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                    12/23/2023 07:12
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    sfaffasff
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
