import { MagnifyingGlassIcon, PencilIcon } from "@heroicons/react/20/solid";
import React, { useEffect, useState } from "react";
import { useHttp, useNotification } from "../../common/hooks";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useAuth } from "../../context";
import { LockClosedIcon } from "@heroicons/react/24/outline";
import { ThreeDots } from "react-loader-spinner";

interface Users {
  userEmail: string;
  email_address: string | null;
  otp: string;
  full_name: string | null;
  wax_address: string | null;
  eth_address: string | null;
  discord_id: string | null;
  twitter_account: string | null;
  auth_token: string;
  type: string | null;
  created_at: string | null;
  updated_at: string | null;
  upland_user_id: string | null;
  upland_auth_token: string | null;
  upland_eos_id: string | null;
  upland_username: string | null;
  code: string | null;
  avatar_url: string | null;
  networth: number | null;
  upland_level: number | null;
  upx_balance: number;
}

export default function AdminRewardUpx() {
  const navigate = useNavigate();
  const { authToken, user } = useAuth();
  const { upland_user_id } = useParams<string>();
  const notify = useNotification();
  const [otp, setOtp] = useState("");
  const [twitterId, setTwitterId] = useState("");
  const [discordId, setDiscordId] = useState("");

  const [selectedUser, setSelectedUser] = useState<Users | null>(null);
  const { sendRequest: getUserByIdApi, isLoading: isLoadingGetUserApi } =
    useHttp();

  const { sendRequest: saveUserApi, isLoading: isLoadingSaveUserApi } =
    useHttp();

  const getUserById = () => {
    getUserByIdApi(
      {
        url: `api/user/edit/${upland_user_id}`,
        method: "POST",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        console.log("data", data);

        setSelectedUser(data.user || null);
      }
    );
  };

  useEffect(() => {
    console.log("upland_user_id", upland_user_id);
    if (upland_user_id) getUserById();
  }, [upland_user_id]);

  useEffect(() => {
    if (selectedUser) {
      setOtp(selectedUser?.otp || "");
      setTwitterId(selectedUser?.twitter_account || "");
      setDiscordId(selectedUser?.discord_id || "");
    }
  }, [selectedUser]);

  const updateUserDetails = async () => {
    if (isLoadingSaveUserApi) return;
    // API call to update user details
    try {
      saveUserApi(
        {
          url: `api/user/update/${upland_user_id}`,
          method: "POST",
          payload: JSON.stringify({
            otp,
            twitter_account: twitterId,
            discord_id: discordId
          }),
          headers: {
            "Content-Type": "application/json",
            Authorization: authToken || ""
          }
        },
        (data: any) => {
          if (data?.error) {
            notify(data?.error, "error");
            return;
          }
          if (data?.message) {
            notify(data?.message, "success");
          }
          console.log("data", data);

          setSelectedUser(data.user || null);
        }
      );
    } catch (error) {
      console.error("Update error:", error);
    }
  };

  return (
    <div className="bg-white w-full px-14 pt-10">
      <div className="flex items-center space-x-3">
        <svg
          onClick={() => navigate(-1)}
          className="cursor-pointer"
          width="32"
          height="32"
          viewBox="0 0 32 32"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <rect width="32" height="32" rx="5" fill="#1C1C1C" />
          <path
            d="M19.2002 22.4004L12.8002 16.0004L19.2002 9.60039"
            stroke="white"
            stroke-linecap="round"
          />
        </svg>

        <p className="font-ano font-bold text-3xl text-black">Reward Upx</p>
      </div>

      {/* <div className="relative rounded-md mt-5">
        <div className="pointer-events-none absolute inset-y-0 left-0 flex items-center pl-3">
          <MagnifyingGlassIcon
            className="h-5 w-5 text-gray-400"
            aria-hidden="true"
          />
        </div>
        <input
          type="text"
          name="search"
          className="rounded-full w- border-0 py-1.5 pl-10 text-gray-900 ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 outline-none sm:text-sm sm:leading-6"
          placeholder="Search"
        />
      </div> */}
      <div className="w-full mt-10">
        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="username"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Username
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="username"
                id="username"
                // value={selectedUser?.upland_username || ""}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="amount"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Amount
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="amount"
                id="amount"
                // value={selectedUser?.email_address || ""}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="upxplorerTax"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Upxplorer Tax
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="upxplorerTax"
                id="upxplorerTax"
                // value={selectedUser?.upland_eos_id || ""}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full">
            <label
              htmlFor="type"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Type
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="type"
                id="type"
                // value={Number(selectedUser?.networth)}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>
        </div>

        <div className="flex items-center justify-between space-x-10 mb-4">
          <div className="w-full">
            <label
              htmlFor="memo"
              className="block text-sm font-normal font-ano leading-6 text-[#494949]"
            >
              Memo
            </label>
            <div className="relative mt-1 rounded-md shadow-sm">
              <input
                type="text"
                name="memo"
                id="memo"
                // value={selectedUser?.upland_auth_token ? "Connected" : "Not Connected"}
                className="block w-full rounded-md border-0 py-1.5 px-3 text-[#8E979F] ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
                placeholder=""
              />
              <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center pr-3">
                <LockClosedIcon
                  className="h-5 w-5 text-gray-400"
                  aria-hidden="true"
                />
              </div>
            </div>
          </div>

          <div className="w-full"></div>

        </div>

        <div className="mt-10">
          <button
            onClick={updateUserDetails}
            type="button"
            className="lg:w-[150px] w-full cursor-pointer border-2 rounded-[50px] bg-[#2D6BE3] border-[#2D6BE3] font-ano font-bold lg:text-lg text-sm text-center py-1 uppercase text-white outline-none"
          >
            {!isLoadingSaveUserApi && <>Save</>}
            {isLoadingSaveUserApi && (
              <ThreeDots
                height={20}
                width={20}
                color="#ffffff"
                wrapperStyle={{}}
                wrapperClass="justify-center py-1"
                visible={true}
                ariaLabel="oval-loading"
              />
            )}
          </button>
        </div>
      </div>
    </div>
  );
}
