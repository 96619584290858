import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp, useNotification } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ThreeDots } from "react-loader-spinner";
import BuyShareModal from "../components/BuyShareModal";
import OfferShareModal from "../components/OfferShareModal";
import MixedChart from "../components/MixedChart";
import SparkNavigationSelect from "../common/components/SparkNavigationSelect";



interface ShareStats {
  totalShares: number;
  totalShareSale: number;
  cheapestShare: number;
}

interface TopOffer {
  offer_id: number;
  shares_amount: number;
  upx_amount: number;
}

interface BuyShares {
  id: number;
  remaining_shares: number;
  upx_share: number;
  total_shares: number;
  sale_id: string;
}

interface SaleOrder {
  created_on: string;
  initiated_by: string;
  remaining_shares: number;
  sale_id: string;
  status: string;
  total_shares: number;
  updated_on: string;
  upx_share: number;
}

interface UserOffers {
  created_on: string;
  initiated_by: string;
  offer_id: number;
  sale_id: string;
  shares_amount: number;
  status: string;
  updated_on: string;
  upx_amount: number;
}

interface DataItem {
  shares_amount: number;
  upx_amount: number;
  created_at: string;
}


export function ShareholdersExchange() {
  const { authToken, user } = useAuth();
  const notify = useNotification();
  const navigate = useNavigate();
  const location = useLocation();

  const [stats, setStats] = useState<ShareStats | null>(null);
  const [topOffers, setTopOffers] = useState<TopOffer[]>([]);
  const [buyShares, setBuyShares] = useState<BuyShares[]>([]);
  const [saleOrder, setSaleOrder] = useState<SaleOrder[]>([]);
  const [userOffers, setUserOffers] = useState<UserOffers[]>([]);
  const [chartData, setChartData] = useState<DataItem[]>([]);
  const [currentShares, setUserCurrentShares] = useState("");
  const [noOfShares, setNoOfShares] = useState("");
  const [upxAmountPerShare, setUpxAmountPerShare] = useState("");

  const [numberOfShares, setNumberOfShares] = useState(0);
  const [maxShares, setMaxShares] = useState<number | null>(null);
  const [sharePrice, setSharePrice] = useState(12000);
  const [totalUpx, setTotalUpx] = useState("");
  const [selectedSaleId, setSelectedSaleId] = useState("");

  const [showBuyOfferModal, setShowBuyOfferModal] = useState(false);
  const [showOfferModal, setShowOfferModal] = useState(false);

  const { sendRequest: getStatsApi, isLoading: isLoadingStatsApi } = useHttp();

  const { sendRequest: getTopOffersApi, isLoading: isLoadingTopOffersApi } =
    useHttp();

  const { sendRequest: getBuySharesApi, isLoading: isLoadingBuySharesApi } =
    useHttp();

  const { sendRequest: getSaleOrderApi, isLoading: isLoadingSaleOrderApi } =
    useHttp();

  const { sendRequest: sellSharesApi, isLoading: isLoadingSellSharesApi } =
    useHttp();

  const { sendRequest: getUserOffersApi, isLoading: isLoadingUserOffersApi } =
    useHttp();
  
    const { sendRequest: getGraphStatsApi, isLoading: isLoadingGraphStatsApi } =
    useHttp();

  const {
    sendRequest: replyShareOfferApi,
    isLoading: isLoadingReplyShareOfferApi
  } = useHttp();

  const {
    sendRequest: cancelShareSaleApi,
    isLoading: isLoadingCancelShareSaleApi
  } = useHttp();

  const {
    sendRequest: cancelUserOfferApi,
    isLoading: isLoadingCancelUserOfferApi
  } = useHttp();

  const {
    sendRequest: fetchUserCurrentSharesApi,
    isLoading: isLoadingFetchUserCurrentSharesApi
  } = useHttp();

  useEffect(() => {
    if (authToken && user) {
      getStatsHandler();
      getTopOffersHandler();
      getBuySharesHandler();
      getSaleOrderHandler();
      getUserCurrentShares();
      getUserOffers();
      getGraphStats();
    }
  }, [authToken, user?.email_address]);

  const getStatsHandler = () => {
    getStatsApi(
      {
        url: "api/getSharesStats",
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }

        setStats(data.stats);
      }
    );
  };

  const getTopOffersHandler = () => {
    getTopOffersApi(
      {
        url: "api/getShareOffer",
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        setTopOffers(data.sales);
      }
    );
  };

  const getBuySharesHandler = () => {
    getBuySharesApi(
      {
        url: "api/getAllShareSales",
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        setBuyShares(data.sales);
      }
    );
  };

  const getSaleOrderHandler = () => {
    getSaleOrderApi(
      {
        url: "api/getUserSharesOnSale",
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        setSaleOrder(data);
      }
    );
  };

  const getUserCurrentShares = () => {
    fetchUserCurrentSharesApi(
      {
        url: `api/shares`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }

        setUserCurrentShares(data?.balance);
      }
    );
  };

  const getUserOffers = () => {
    getUserOffersApi(
      {
        url: `api/getUserOffers`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }
        setUserOffers(data);
      }
    );
  };

  const getGraphStats = () => {
    getGraphStatsApi(
      {
        url: `api/getGraphStats`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          return;
        }

        console.log('data',data)
        setChartData(data)
        // setUserOffers(data);
      }
    );
  };

  const handleNoOfSharesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNoOfShares(e.target.value);
  };

  const handleUpxAmountPerShareChange = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUpxAmountPerShare(e.target.value);
  };

  const sellSharesHandler = async () => {
    sellSharesApi(
      {
        url: "api/createShareSale",
        method: "POST",
        payload: JSON.stringify({
          totalShares: noOfShares,
          upxShare: upxAmountPerShare
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data.error, "error");
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
        }

        setNoOfShares("");
        setUpxAmountPerShare("");
        getUserCurrentShares();
      }
    );
  };

  const replyShareOfferHandler = async (
    offer_id: number,
    userResponse: string
  ) => {
    if (isLoadingReplyShareOfferApi) return;
    replyShareOfferApi(
      {
        url: "api/replyShareOffer",
        method: "POST",
        payload: JSON.stringify({
          offer_id,
          userResponse
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data.error, "error");
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
        }

        getTopOffersHandler();
      }
    );
  };

  const cancelShareSaleHandler = async (sale_id: string) => {
    if (isLoadingCancelShareSaleApi) return;
    cancelShareSaleApi(
      {
        url: "api/cancelShareSale",
        method: "POST",
        payload: JSON.stringify({
          sale_id
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data.error, "error");
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
        }

        getSaleOrderHandler();
      }
    );
  };

  const cancelUserOffer = async (offer_id: number) => {
    if (isLoadingCancelUserOfferApi) return;
    cancelUserOfferApi(
      {
        url: "api/cancelUserOffer",
        method: "POST",
        payload: JSON.stringify({
          offer_id
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data.error, "error");
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
        }

        getUserOffers();
      }
    );
  };

  const buyOfferHandler = async () => {
    sellSharesApi(
      {
        url: "api/buyShareSale",
        method: "POST",
        payload: JSON.stringify({
          totalShares: numberOfShares,
          sale_id: selectedSaleId
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data.error, "error");
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
        }

        setShowBuyOfferModal(false);
      }
    );
  };

  const handleOpenModal = (sale_id: string, maxShares: number) => {
    setNumberOfShares(0);
    setSelectedSaleId(sale_id);
    setMaxShares(maxShares);
    setShowBuyOfferModal(true);
  };

  const handleOpenModalOffer = (sale_id: string, maxShares: number) => {
    setNumberOfShares(0);
    setSelectedSaleId(sale_id);
    setMaxShares(maxShares);
    setShowOfferModal(true);
  };

  const offerHandler = async () => {
    sellSharesApi(
      {
        url: "api/createShareBuyOffer",
        method: "POST",
        payload: JSON.stringify({
          totalShares: numberOfShares,
          upxShare: totalUpx,
          sale_id: selectedSaleId
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data.error, "error");
          return;
        }
        if (data?.message) {
          notify(data.message, "success");
        }

        setShowOfferModal(false);
      }
    );
  };

  const isPathActive = (path: string) => {
    return location.pathname === path;
  };

  const formatDateWithTime = (dateString: string | null): string => {
    if (!dateString) {
      return "";
    }

    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();
    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  return (
    <>
      <BuyShareModal
        isOpen={showBuyOfferModal}
        onAction={buyOfferHandler}
        onCancel={() => setShowBuyOfferModal(false)}
        numberOfShares={numberOfShares}
        setNumberOfShares={setNumberOfShares}
        maxShares={maxShares}
        upxPerShare={sharePrice}
        fee={0}
      />

      <OfferShareModal
        isOpen={showOfferModal}
        onAction={offerHandler}
        onCancel={() => setShowOfferModal(false)}
        numberOfShares={numberOfShares}
        setNumberOfShares={setNumberOfShares}
        totalUpx={totalUpx}
        setTotalUpx={setTotalUpx}
        maxShares={maxShares}
        upxPerShare={sharePrice}
        fee={0}
      />
      <div className="h-full flex flex-col">
        <Header />
        <div className="flex flex-col lg:items-center justify-center space-y-3 border-b border-[#676767] mt-6 pb-6 lg:px-0 px-6">
          <div className="hidden lg:grid grid-cols-2 gap-6">
            <p
              onClick={() => navigate("/exchange")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/exchange")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              SHAREHOLDER EXCHANGE
            </p>
            <p
              onClick={() => navigate("/share-history")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/share-history")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              SHARE HISTORY
            </p>
          </div>
          <SparkNavigationSelect />
        </div>
        <div className="lg:px-20 px-5 flex-1 my-10">
          <div className="bg-[#272727] rounded-2xl w-full">
            <div className="grid lg:grid-cols-3 grid-cols-1 gap-8 lg:px-8 py-10 h-full">
              <div className="lg:col-span-2 col-span-1">
                <MixedChart data={chartData} />
              </div>
              <div className="lg:px-0 px-8">
                <div className="flex flex-col mb-2">
                  <p className="font-ano text-base font-normal text-[#999999]">
                    Total Shares
                  </p>
                  <p className="font-ano text-xl font-bold text-[#FFFFFF]">
                    {stats?.totalShares}
                  </p>
                </div>
                <div className="flex flex-col mb-2">
                  <p className="font-ano text-base font-normal text-[#999999]">
                    SHARES AVAILABLE ON THE MARKET
                  </p>
                  <p className="font-ano text-xl font-bold text-[#FFFFFF]">
                    {stats?.totalShareSale}
                  </p>
                </div>
                <div className="flex flex-col mb-2">
                  <p className="font-ano text-base font-normal text-[#999999]">
                    FLOOR PRICE
                  </p>
                  <p className="font-ano text-xl font-bold text-[#FFFFFF]">
                    {stats?.cheapestShare}
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="mt-10">
            <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-10">
              <div>
                <p className="uppercase font-ano font-bold text-2xl text-white mb-5">
                  SELL SHARES
                </p>

                <div className="rounded-2xl grid lg:grid-cols-2 grid-cols-1">
                  <div className="bg-[#1C1C1C] rounded-tl-2xl lg:rounded-tr-none rounded-tr-2xl lg:rounded-bl-2xl">
                    <div className="px-4 py-6 flex flex-col">
                      <div className="flex flex-col">
                        <div className="lg:w-auto w-full px-2 pt-4">
                          <div className="flex items-center justify-between">
                            <p className="font-ano uppercase font-normal text-base mb-1 text-white">
                              NO# of Shares
                            </p>
                            <div className="font-ano text-base font-normal text-[#999999]">
                              Current Shares: {currentShares}
                            </div>
                          </div>
                          <input
                            type="text"
                            className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                            placeholder=""
                            value={noOfShares}
                            onChange={handleNoOfSharesChange}
                          />
                        </div>

                        <div className="lg:w-auto w-full px-2 pt-4">
                          <p className="font-ano uppercase font-normal text-base mb-1 text-white">
                            UPX Amount/Share
                          </p>
                          <input
                            type="text"
                            className="w-full bg-[#1C1C1C] lg:py-2 py-1 lg:px-5 px-4 focus:border-[#3E7EFF] font-ano text-white font-bold lg:text-2xl text-lg border-2 border-white rounded-[50px] outline-none"
                            placeholder=""
                            value={upxAmountPerShare}
                            onChange={handleUpxAmountPerShareChange}
                          />
                        </div>

                        <div className="flex items-center justify-between mt-5">
                          <div
                            onClick={() =>
                              noOfShares !== "" &&
                              upxAmountPerShare !== "" &&
                              sellSharesHandler()
                            }
                            className={`w-40 cursor-pointer border-2 rounded-[50px] font-ano font-bold text-base text-center py-1 bg-[#1C1C1C] uppercase outline-none ${
                              noOfShares !== "" && upxAmountPerShare !== ""
                                ? "hover:bg-[#3E7EFF] border-white text-white cursor-pointer"
                                : "border-[#272727] text-[#272727] cursor-not-allowed"
                            }`}
                          >
                            {!isLoadingSellSharesApi && <>SELL SHARES</>}
                            {isLoadingSellSharesApi && (
                              <ThreeDots
                                height={20}
                                width={20}
                                color="#ffffff"
                                wrapperStyle={{}}
                                wrapperClass="justify-center py-1"
                                visible={true}
                                ariaLabel="oval-loading"
                              />
                            )}
                          </div>

                          {/* {errorCreateListing && (
                            <p className="font-ano text-sm font-bold uppercase text-[#FF6F6F]">
                              {errorCreateListing}
                            </p>
                          )}
                          {errorForUpxHr && (
                            <p className="font-ano text-sm font-bold uppercase text-[#FF6F6F]">
                              {errorForUpxHr}
                            </p>
                          )} */}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="bg-[#272727] lg:rounded-tr-2xl h-full rounded-br-2xl lg:rounded-bl-none rounded-bl-2xl">
                    <div className="flex flex-col px-8 py-3 h-full">
                      <p className="text-[#999999] font-ano text-center font-normal text-lg mb-2">
                        SELL ORDER DETAILS
                      </p>

                      <div className="flex items-center justify-between space-x-5 mt-2">
                        <p className="font-ano font-normal text-lg text-white">
                          UPX/Share amount
                        </p>
                        <p className="font-ano font-semibold text-xl text-white">
                          {upxAmountPerShare}
                        </p>
                      </div>

                      <div className="flex items-center justify-between space-x-5 mt-2">
                        <p className="font-ano font-normal text-lg text-white">
                          Total UPX after sale
                        </p>
                        <p className="font-ano font-semibold text-xl text-white">
                          {Number(noOfShares) * Number(upxAmountPerShare)}
                        </p>
                      </div>

                      <div className="flex items-center justify-between space-x-5 mt-2">
                        <p className="font-ano font-normal text-lg text-white">
                          Total Share to Sell
                        </p>
                        <p className="font-ano font-semibold text-xl text-white">
                          {noOfShares}
                        </p>
                      </div>

                      {/* <div className="border-b border-[#676767] my-4"></div>

                      <div className="flex items-center justify-between space-x-5">
                        <p className="font-ano font-bold text-lg text-white">
                          Fee
                        </p>
                        <p className="font-ano font-semibold text-xl text-white">
                          0%
                        </p>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>

              <div>
                <div className="flex items-center mb-2">
                  <div className="flex items-center space-x-5">
                    <p className="uppercase font-ano font-bold text-2xl text-white">
                      Top Offers
                    </p>

                    {/* <div className="flex items-center space-x-3">
                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            SPARK LOGS
                          </p>

                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            COMPLETED CONTRACTS
                          </p>
                        </div> */}
                  </div>
                </div>

                <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6 mb-10">
                  <div className="flow-root">
                    <div className="overflow-x-auto">
                      <div className="inline-block min-w-full py-2">
                        <table className="min-w-full divide-y divide-[#2C2C2C]">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 text-center font-ano font-bold text-xl text-white"
                              >
                                ID
                              </th>
                              <th
                                scope="col"
                                className="text-center font-ano font-bold text-xl text-white"
                              >
                                Shares
                              </th>
                              <th
                                scope="col"
                                className="text-center font-ano font-bold text-xl text-white"
                              >
                                Total UPX
                              </th>
                              <th
                                scope="col"
                                className="text-center font-ano font-bold text-xl text-white"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-[#2C2C2C]">
                            {topOffers?.length > 0 ? (
                              topOffers?.map((item) => (
                                <tr key={item?.offer_id}>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    {item?.offer_id}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    {item.shares_amount}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                    {item.upx_amount}
                                  </td>
                                  <td className="py-4 capitalize font-ano font-normal text-xl text-center text-white">
                                    <div className="flex items-center space-x-3 justify-center">
                                      <p
                                        onClick={() =>
                                          replyShareOfferHandler(
                                            item?.offer_id,
                                            "accepted"
                                          )
                                        }
                                        className="cursor-pointer border border-[#3E7EFF] rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1"
                                      >
                                        Accept
                                      </p>
                                      <p
                                        onClick={() =>
                                          replyShareOfferHandler(
                                            item?.offer_id,
                                            "rejected"
                                          )
                                        }
                                        className="cursor-pointer border border-[#FF0000] rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1"
                                      >
                                        Reject
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={5}
                                  className="py-4 font-ano font-normal text-xl text-center text-white"
                                >
                                  No offer available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="mt-10">
            <div className="grid lg:grid-cols-2 grid-cols-1 lg:gap-10">
              <div>
                <p className="uppercase font-ano font-bold text-2xl text-white mb-5">
                  BUY SHARES
                </p>

                <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6 mb-10">
                  <div className="flow-root">
                    <div className="overflow-x-auto">
                      <div className="inline-block min-w-full py-2">
                        <table className="min-w-full divide-y divide-[#2C2C2C]">
                          <thead>
                            <tr>
                              <th
                                scope="col"
                                className="py-3.5 text-center font-ano font-bold text-xl text-white"
                              >
                                ID
                              </th>
                              <th
                                scope="col"
                                className="text-center font-ano font-bold text-xl text-white"
                              >
                                Shares
                              </th>
                              <th
                                scope="col"
                                className="text-center font-ano font-bold text-xl text-white"
                              >
                                UPX/Share
                              </th>

                              <th
                                scope="col"
                                className="text-center font-ano font-bold text-xl text-white"
                              >
                                Total UPX
                              </th>
                              <th
                                scope="col"
                                className="text-center font-ano font-bold text-xl text-white"
                              >
                                Action
                              </th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-[#2C2C2C]">
                            {buyShares?.length > 0 ? (
                              buyShares?.map((item) => (
                                <tr key={item?.id}>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    {item?.sale_id}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                    {item.total_shares}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                    {item.upx_share}
                                  </td>
                                  <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                    {item.remaining_shares}
                                  </td>
                                  <td className="py-4 capitalize font-ano font-normal text-xl text-center text-white">
                                    <div className="flex items-center space-x-3 justify-center">
                                      <p
                                        onClick={() =>
                                          handleOpenModal(
                                            item?.sale_id,
                                            item.remaining_shares
                                          )
                                        }
                                        className="cursor-pointer border border-[#3E7EFF] rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1"
                                      >
                                        Buy
                                      </p>
                                      <p
                                        onClick={() =>
                                          handleOpenModalOffer(
                                            item?.sale_id,
                                            item.remaining_shares
                                          )
                                        }
                                        // onClick={() => setShowOfferModal(true)}
                                        className="cursor-pointer border border-[#FF0000] rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1"
                                      >
                                        Offer
                                      </p>
                                    </div>
                                  </td>
                                </tr>
                              ))
                            ) : (
                              <tr>
                                <td
                                  colSpan={5}
                                  className="py-4 font-ano font-normal text-xl text-center text-white"
                                >
                                  No offer available
                                </td>
                              </tr>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="flex flex-col space-y-10">
                <div>
                  <div className="flex items-center mb-2">
                    <div className="flex items-center space-x-5">
                      <p className="uppercase font-ano font-bold text-2xl text-white">
                        SALE ORDER
                      </p>

                      {/* <div className="flex items-center space-x-3">
                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            SPARK LOGS
                          </p>

                          <p className="bg-[#1C1C1C] hover:bg-[#3E7EFF] cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1">
                            COMPLETED CONTRACTS
                          </p>
                        </div> */}
                    </div>
                  </div>

                  <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6 mb-10">
                    <div className="flow-root">
                      <div className="overflow-x-auto">
                        <div className="inline-block min-w-full py-2">
                          <table className="min-w-full divide-y divide-[#2C2C2C]">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 text-center font-ano font-bold text-xl text-white"
                                >
                                  ID
                                </th>
                                <th
                                  scope="col"
                                  className="text-center font-ano font-bold text-xl text-white"
                                >
                                  Shares
                                </th>
                                <th
                                  scope="col"
                                  className="text-center font-ano font-bold text-xl text-white"
                                >
                                  Total UPX
                                </th>
                                <th
                                  scope="col"
                                  className="text-center font-ano font-bold text-xl text-white"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-[#2C2C2C]">
                              {saleOrder?.length > 0 ? (
                                saleOrder?.map((item) => (
                                  <tr key={item?.sale_id}>
                                    <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                      {item?.sale_id}
                                    </td>
                                    <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                      {item?.upx_share}
                                    </td>
                                    <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                      {item.total_shares}
                                    </td>

                                    <td className="py-4 capitalize font-ano font-normal text-xl text-center text-white">
                                      <div className="flex items-center space-x-3 justify-center">
                                        <p
                                          onClick={() =>
                                            cancelShareSaleHandler(
                                              item?.sale_id
                                            )
                                          }
                                          className="cursor-pointer border border-[#FF0000] rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1"
                                        >
                                          Remove
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan={5}
                                    className="py-4 font-ano font-normal text-xl text-center text-white"
                                  >
                                    No offer available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="flex items-center mb-2">
                    <div className="flex items-center space-x-5">
                      <p className="uppercase font-ano font-bold text-2xl text-white">
                        My OFfers
                      </p>
                    </div>
                  </div>

                  <div className=" bg-[#1C1C1C] rounded-2xl py-6 px-3 mt-6">
                    <div className="flow-root">
                      <div className="overflow-x-auto">
                        <div className="inline-block min-w-full py-2">
                          <table className="min-w-full divide-y divide-[#2C2C2C]">
                            <thead>
                              <tr>
                                <th
                                  scope="col"
                                  className="py-3.5 text-center font-ano font-bold text-xl text-white"
                                >
                                  ID
                                </th>
                                <th
                                  scope="col"
                                  className="text-center font-ano font-bold text-xl text-white"
                                >
                                  Shares
                                </th>
                                <th
                                  scope="col"
                                  className="text-center font-ano font-bold text-xl text-white"
                                >
                                  Total UPX
                                </th>
                                <th
                                  scope="col"
                                  className="text-center font-ano font-bold text-xl text-white"
                                >
                                  Action
                                </th>
                              </tr>
                            </thead>
                            <tbody className="divide-y divide-[#2C2C2C]">
                              {userOffers?.length > 0 ? (
                                userOffers?.map((item) => (
                                  <tr key={item?.sale_id}>
                                    <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                      {item?.sale_id}
                                    </td>
                                    <td className="py-4 font-ano font-normal text-xl text-center text-white">
                                      {item?.shares_amount}
                                    </td>
                                    <td className="py-4 font-ano font-normal text-xl text-center text-white capitalize">
                                      {item.upx_amount}
                                    </td>

                                    <td className="py-4 capitalize font-ano font-normal text-xl text-center text-white">
                                      <div className="flex items-center space-x-3 justify-center">
                                        <p
                                          onClick={() =>
                                            cancelUserOffer(
                                              item?.offer_id
                                            )
                                          }
                                          className="cursor-pointer border border-[#FF0000] rounded-[50px] uppercase font-ano text-white font-bold text-lg px-5 py-1"
                                        >
                                          Remove
                                        </p>
                                      </div>
                                    </td>
                                  </tr>
                                ))
                              ) : (
                                <tr>
                                  <td
                                    colSpan={5}
                                    className="py-4 font-ano font-normal text-xl text-center text-white"
                                  >
                                    No offer available
                                  </td>
                                </tr>
                              )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
