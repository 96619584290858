import React, { Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

interface FilterValues {
  startDate: string;
  endDate: string;
  type: string[];
  status: string[];
  source: string;
  destination: string;
}

interface Props {
  isOpen: boolean;
  onApplyFilters: (filters: FilterValues) => void;
  onCancel: () => void;
}

interface Props {
  isOpen: boolean;
  onApplyFilters: (filters: FilterValues) => void;
  onCancel: () => void;
}

const FilterModal: React.FC<Props> = ({ isOpen, onApplyFilters, onCancel }) => {
  const [isOpenStartDate, setIsOpenStartDate] = useState(false);
  const [isOpenEndDate, setIsOpenEndDate] = useState(false);

  const [startDate, setStartDate] = useState<Date | null>(new Date());
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [filters, setFilters] = useState<FilterValues>({
    startDate: startDate?.toISOString() || "",
    endDate: endDate?.toISOString() || "",
    type: [],
    status: [],
    source: "",
    destination: ""
  });

  const initialFilters: FilterValues = {
    startDate: startDate?.toISOString() || "",
    endDate: endDate?.toISOString() || "",
    type: [],
    status: [],
    source: "",
    destination: ""
  };

  useEffect(() => {
    if (isOpen) {
      // Reset filters and date pickers when the modal is opened
      setStartDate(new Date()); // Reset or set to a specific default
      setEndDate(null); // Reset or set to a specific default
      setFilters(initialFilters); // Reset filters to initial state
    }
  }, [isOpen]);

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value, checked } = e.target;

    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: checked
        ? [...prevFilters[name], value]
        : prevFilters[name].filter((item) => item !== value)
    }));
  };

  const handleSubmit = () => {
    onApplyFilters({
      ...filters,
      startDate: startDate ? startDate.toISOString() : "",
      endDate: endDate ? endDate.toISOString() : ""
    });
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={onCancel}>
        <div className="fixed inset-0 bg-[#000000DE] transition-opacity" />

        <div className="fixed inset-0 z-10">
          <div className="flex min-h-full items-center justify-center">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-2xl bg-[#1C1C1C] shadow-xl lg:w-[550px] w-auto transition-allh-auto">
                <div className="px-10 py-10">
                  <p className="font-ano text-white font-bold text-2xl">
                    Add Filters
                  </p>
                  <div className="mt-5 flex items-center space-x-4">
                    <p className="font-ano font-semibold text-white text-lg">
                      Start date
                    </p>
                    <DatePicker
                      open={isOpenStartDate}
                      onInputClick={() => setIsOpenStartDate(true)}
                      selected={startDate}
                      onChange={(date: Date | null) => {setStartDate(date); setIsOpenStartDate(false)}}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Select start date"
                      />
                  </div>
                  <div className="mt-5 flex items-center space-x-4">
                    <p className="font-ano font-semibold text-white text-lg">
                      End date
                    </p>
                    <DatePicker
                      selected={endDate}
                      open={isOpenEndDate}
                      onInputClick={() => setIsOpenEndDate(true)}
                      onChange={(date: Date | null) => {
                        setEndDate(date);
                        setFilters({
                          ...filters,
                          endDate: date ? date.toISOString() : ""
                        });
                        setIsOpenEndDate(false);
                      }}
                      dateFormat="MMMM d, yyyy"
                      placeholderText="Select end date"
                    />
                  </div>

                  <div className="mt-5">
                    <p className="font-ano text-white font-bold text-lg mb-3">
                      Type of transaction
                    </p>

                    {[
                      "Deposit",
                      "Withdraw",
                      "Transfer",
                      "Reward",
                      "Refund"
                    ].map((type) => (
                      <div
                        key={type}
                        className="flex items-center space-x-3 mb-3"
                      >
                        <input
                          type="checkbox"
                          value={type}
                          name="type"
                          onChange={handleCheckboxChange}
                          checked={filters.type.includes(type)}
                        />
                        <p className="font-ano font-semibold text-white text-lg">
                          {type}
                        </p>
                      </div>
                    ))}
                  </div>

                  <div className="mt-5">
                    <p className="font-ano text-white font-bold text-lg mb-3">
                      Status
                    </p>

                    {["Pending", "In Progress", "Processing", "Completed"].map(
                      (status) => (
                        <div
                          key={status}
                          className="flex items-center space-x-3 mb-3"
                        >
                          <input
                            type="checkbox"
                            value={status}
                            name="status"
                            onChange={handleCheckboxChange}
                            checked={filters.status.includes(status)}
                          />
                          <p className="font-ano font-semibold text-white text-lg">
                            {status}
                          </p>
                        </div>
                      )
                    )}
                  </div>
                  <div className="flex items-center space-x-3 mt-8">
                    <button
                      onClick={handleSubmit}
                      type="button"
                      className="w-[180px] lg:mt-8 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#FF6F6F] border-[#FF6F6F] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                    >
                      Apply Filters
                    </button>

                    <button
                      onClick={onCancel}
                      type="button"
                      className="w-[180px] lg:mt-8 mt-6 cursor-pointer border-2 rounded-[50px] hover:bg-[#3E7EFF] border-[#3E7EFF] font-ano font-bold lg:text-lg text-base text-center lg:py-2 py-1 bg-[#1C1C1C] uppercase text-white outline-none"
                    >
                      Cancel
                    </button>
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default FilterModal;
