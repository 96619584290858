import Header from "../components/Header";
import Footer from "../components/Footer";
import { useHttp, useNotification } from "../common/hooks";
import { useAuth } from "../context";
import { useEffect, useState } from "react";
import FilterModal from "../components/FilterModal";
import { useLocation, useNavigate } from "react-router-dom";
import SparkNavigationSelect from "../common/components/SparkNavigationSelect";

interface History {
  transaction_id: string;
  initiated_by: string;
  type: string;
  upx_amount: number;
  share_amount: number;
  source: string;
  destination: string;
  memo: string | null;
  upxplorer_tax: string;
  shares_amount: number;
  created_at: string;
  updated_at: string;
}

interface FilterValues {
  startDate: string;
  endDate: string;
  type: string[]; // Assuming type is an array of strings
  status: string[]; // Assuming status is an array of strings
  source: string;
  destination: string;
}

export function ShareHistory() {
  const { authToken, user } = useAuth();
  const navigate = useNavigate();
  const location = useLocation();
  const notify = useNotification();

  const [shareHistory, setShareHistory] = useState<History[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState("");
  const [filterModal, setShowFilterModal] = useState(false);

  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [sortField, setSortField] = useState("created_at");
  const [sortDirection, setSortDirection] = useState("asc");

  const [filters, setFilters] = useState<FilterValues>({
    startDate: "",
    endDate: "",
    type: [],
    status: [],
    source: "",
    destination: ""
  });

  const { sendRequest: getShareHistoryApi } = useHttp();

  useEffect(() => {
    console.log("filterModal", filterModal);
    console.log("authToken", authToken);
    console.log("user?.email_address", user);
    if (!filterModal && authToken && user?.userEmail) {
      getShareHistoryHandler(sortField, sortDirection);
    }
  }, [currentPage, authToken, user?.email_address, sortField, sortDirection]);

  const getShareHistoryHandler = (
    field = sortField,
    direction = sortDirection
  ) => {
    setIsLoading(true);
    setError("");

    console.log("filters", filters);
    const preparedFilters = {
      ...filters,
      type: filters.type.map((type) => type.toLowerCase()).join(","),
      status: filters.status.map((status) => status.toLowerCase()).join(","),
      startDate: filters.startDate
        ? new Date(filters.startDate).toISOString()
        : "",
      endDate: filters.endDate ? new Date(filters.endDate).toISOString() : ""
    };

    getShareHistoryApi(
      {
        url: `api/getUserSharesHistory?page=${currentPage}&pageSize=10&sortField=${field}&sortDirection=${direction}`,
        method: "GET",
        payload: JSON.stringify({
          email: user?.email_address,
          ...preparedFilters
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken || ""
        }
      },
      (data: any) => {
        setIsLoading(false);
        if (data?.error) {
          setError(data.error);
          return;
        }

        if (data?.message) {
          notify(data?.message, "error");
        }
        setShareHistory(data || []);
        setTotalPages(data.pagination.totalPages);
      }
    );
  };

  const renderPagination = () => {
    if (totalPages <= 1) {
      return null;
    }
    let pages: JSX.Element[] = [];
    for (let i = 1; i <= totalPages; i++) {
      pages.push(
        <button
          key={i}
          className={`px-1 ${
            i === currentPage ? "text-[#4F99F0]" : "text-white"
          }`}
          onClick={() => setCurrentPage(i)}
        >
          [ {i} ]
        </button>
      );
    }
    return <div className="flex space-x-2">{pages}</div>;
  };

  const formatDate = (dateString: string | null): string => {
    if (!dateString) {
      return "No Date Provided"; // Or return an empty string or any other placeholder
    }

    const date = new Date(dateString);

    const day = date.getDate().toString().padStart(2, "0");
    const month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-indexed
    const year = date.getFullYear();

    const hours = date.getHours().toString().padStart(2, "0");
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const seconds = date.getSeconds().toString().padStart(2, "0");

    return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };

  function formatNumberToTwoDecimals(value: any, place = 2) {
    if (value === null || value === undefined || isNaN(Number(value))) {
      return ""; // or any default value you prefer
    }
    // Format number to fixed decimal places
    let formattedValue = Number(value).toFixed(place);
    // Remove unnecessary trailing zeros and decimal point if not needed
    return formattedValue.replace(/(\.\d*?[1-9])0+$|\.0*$/, "$1");
  }

  function truncateTransactionId(id, startLength = 6, endLength = 6) {
    // Check if the transaction ID length is greater than the sum of startLength and endLength
    if (id.length > startLength + endLength) {
      // Return the first startLength characters, three dots, and the last endLength characters
      return `${id.slice(0, startLength)}...${id.slice(-endLength)}`;
    }
    // If the ID isn't too long, return it as is
    return id;
  }

  const handleSort = (field) => {
    const direction =
      field === sortField && sortDirection === "asc" ? "desc" : "asc";
    setSortField(field);
    setSortDirection(direction);
    setCurrentPage(1);
    getShareHistoryHandler(field, direction);
  };

  const handleApplyFilters = (newFilters: FilterValues) => {
    console.log("newFilters", newFilters);
    setFilters(newFilters);
    setShowFilterModal(false);
    setCurrentPage(1);

    // const preparedFilters = {
    //   ...newFilters,
    //   type: newFilters.type.map((type) => type.toLowerCase()).join(","),
    //   status: newFilters.status.map((status) => status.toLowerCase()).join(","),
    //   startDate: newFilters.startDate
    //     ? new Date(newFilters.startDate).toISOString()
    //     : "",
    //   endDate: newFilters.endDate
    //     ? new Date(newFilters.endDate).toISOString()
    //     : ""
    // };

    // getShareHistoryApi(
    //   {
    //     url: `api/transactions?page=${currentPage}&pageSize=10&sortField=${sortField}&sortDirection=${sortDirection}`,
    //     method: "POST",
    //     payload: JSON.stringify({
    //       email: user?.email_address,
    //       ...preparedFilters
    //     }),
    //     headers: {
    //       "Content-Type": "application/json",
    //       Authorization: authToken || ""
    //     }
    //   },
    //   (data: any) => {
    //     setIsLoading(false);
    //     if (data?.error) {
    //       setError(data.error);
    //       return;
    //     }
    //     setShareHistory(data.transactions || []);
    //     setTotalPages(data.pagination.totalPages);
    //   }
    // );
  };

  const isPathActive = (path: string) => {
    return location.pathname === path;
  };

  return (
    <>
      <FilterModal
        isOpen={filterModal}
        onApplyFilters={handleApplyFilters}
        onCancel={() => setShowFilterModal(false)}
      />
      <div className="flex flex-col h-full">
        <Header />
        <div className="flex flex-col lg:items-center justify-center space-y-3 border-b border-[#676767] mt-6 pb-6 lg:px-0 px-6">
          <div className="hidden lg:grid grid-cols-2 gap-6">
            <p
              onClick={() => navigate("/exchange")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/exchange")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              SHAREHOLDER EXCHANGE
            </p>
            <p
              onClick={() => navigate("/share-history")}
              className={`text-center bg-[#1C1C1C] ${
                isPathActive("/share-history")
                  ? "bg-[#3E7EFF]"
                  : "hover:bg-[#3E7EFF]"
              } cursor-pointer border border-white rounded-[50px] uppercase font-ano text-white font-semibold text-base px-5 py-1`}
            >
              SHARE HISTORY
            </p>
          </div>
          <SparkNavigationSelect />
        </div>
        <div className="flex-1 lg:px-20 px-5">
          <div className="flex items-center justify-between mt-16">
            <p className="font-ano font-bold text-3xl text-white">HISTORY</p>

            <button
              onClick={() => setShowFilterModal(true)}
              type="button"
              className="lg:w-[150px] w-auto lg:px-0 px-4 cursor-pointer border-2 rounded-[50px] hover:bg-[#1EA067] border-[#1EA067] font-ano font-bold lg:text-lg text-sm text-center py-1 bg-[#1C1C1C] uppercase text-white outline-none"
            >
              Add Filter
            </button>
            {/* <div>
                <select className="px-4 py-2 uppercase rounded-full bg-[#1C1C1C] border border-white text-white">
                  <option>Filters</option>
                  <option>Filters</option>
                  <option>Filters</option>
                  <option>Filters</option>
                </select>
              </div> */}
            {/* <div>
              <div>
                <select className="px-4 py-2 uppercase rounded-full bg-[#1C1C1C] border border-white text-white">
                  <option>Sort by</option>
                  <option>Sort by</option>
                  <option>Sort by</option>
                  <option>Sort by</option>
                </select>
              </div>
            </div> */}
          </div>

          <div className="bg-[#1C1C1C] rounded-2xl py-6 mt-3 mb-10">
            <div className="flow-root">
              <div className="overflow-x-auto">
                <div className="inline-block min-w-full py-2">
                  <table className="min-w-full divide-y divide-[#2C2C2C]">
                    <thead>
                      <tr>
                        <th
                          scope="col"
                          className="py-3.5 text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Transaction ID
                        </th>
                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white hover:text-[#F9F41F] cursor-pointer"
                        >
                          Date
                        </th>
                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white hover:text-[#F9F41F] cursor-pointer"
                        >
                          Type
                        </th>

                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Share Amount
                        </th>
                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Source
                        </th>

                        <th
                          scope="col"
                          className="text-center font-ano font-bold text-lg px-2 text-white"
                        >
                          Destination
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-[#2C2C2C]">
                      {isLoading ? (
                        <tr>
                          <td
                            colSpan={8}
                            className="py-10 font-ano text-white text-lg text-center font-bold"
                          >
                            Loading history...
                          </td>
                        </tr>
                      ) : error ? (
                        <tr>
                          <td
                            colSpan={8}
                            className="py-10 font-ano text-white text-lg text-center font-bold"
                          >
                            Error: {error}
                          </td>
                        </tr>
                      ) : shareHistory.length > 0 ? (
                        shareHistory.map((history) => (
                          <tr key={history.transaction_id}>
                            <td className="py-4 font-ano font-normal text-lg px-4 text-center text-white">
                              {truncateTransactionId(history.transaction_id)}
                            </td>
                            <td className="py-4 font-ano font-normal text-lg px-4 text-center text-white">
                              {formatDate(history.created_at)}
                            </td>
                            <td
                              className={`py-4 capitalize font-ano font-normal text-lg px-4 text-center text-white`}
                            >
                              {history.type}
                            </td>
                            <td
                              className={`py-4 capitalize font-ano font-normal text-lg px-4 text-center text-white`}
                            >
                              {history.shares_amount}
                            </td>
                            <td className="py-4 font-ano font-normal text-lg px-4 text-center text-white">
                              {history.source}
                            </td>
                            <td className="py-4 font-ano font-normal text-lg px-4 text-center text-white">
                              {history.destination}
                            </td>
                    
                          </tr>
                        ))
                      ) : (
                        <tr>
                          <td
                            colSpan={8}
                            className="py-10 font-ano text-white text-lg text-center font-bold"
                          >
                            No Share history available
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
            <div className="flex justify-center items-center my-4">
              {renderPagination()}
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
