import React, { ChangeEvent, useEffect, useState } from "react";
import { useHttp, useNotification } from "../common/hooks";
import Header from "../components/Header";
import Footer from "../components/Footer";

import InfoIcon from "../assets/images/info.svg";
import UpxIcon from "../assets/images/upx_icon.svg";
import SearchIcon from "../assets/images/search_icon.svg";
import CopyIcon from "../assets/images/copy.svg";
import TellMeHow from "../components/TellMeHow";
import { useNavigate } from "react-router-dom";
import { MainHeading } from "../common/components/MainHeading";
import CityListModal from "../components/CityListModal";
import { useAuth } from "../context";
import DepositModal from "../components/DepositModal";
import { ThreeDots } from "react-loader-spinner";
import TellMeHowDirectDeposit from "../components/TellMeHowDirectDeposit";

interface City {
  address: string;
  city: string;
}

export function DirectDeposit() {
  const navigate = useNavigate();
  const notify = useNotification();

  const { user, getUser, authToken } = useAuth();
  const [amount, setAmount] = useState("");
  const [containerId, setContainerId] = useState("");
  const [showCityModal, setShowCityModal] = useState(false);
  const [showDepositModal, setShowDepositModal] = useState(false);
  const [showConfirmDeposit, setShowConfirmDeposit] = useState(false);
  const [showVerifyDeposit, setShowVerifyDeposit] = useState(false);
  const [showSuccessDeposit, setShowSuccessDeposit] = useState(false);
  const [amountError, setAmountError] = useState("");
  const [uplandTax, setUplandTax] = useState<number>(0);
  const [upxplorerTax, setUpxplorerTax] = useState<number>(0);
  const [finalAmount, setFinalAmount] = useState<number>(0);
  const [isVerified, setIsVerified] = useState(false);

  const [showTellMeModal, setShowTellMeModal] = useState(false);

  const { sendRequest: continueDepositApi, isLoading: isLoadingDepositApi } =
    useHttp();

  const { sendRequest: continueVerifyApi, isLoading: isLoadingVerifyApi } =
    useHttp();

  // Call calculateTaxesAndFinalAmount whenever the amount changes
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && validateAmount(amount)) {
      calculateTaxesAndFinalAmount(parsedAmount);
    }
  }, [amount]);

  // Call calculateTaxes whenever the amount changes
  useEffect(() => {
    const parsedAmount = parseFloat(amount);
    if (!isNaN(parsedAmount) && validateAmount(amount)) {
      calculateTaxes(parsedAmount);
    }
  }, [amount]);

  // Function to format numbers to remove unnecessary decimal zeros
  const formatNumber = (num: number): string => {
    return num.toFixed(2);
  };
  const validateAmount = (amountValue: any) => {
    const stringValue = String(amountValue).trim(); // Convert to string and trim
    if (stringValue === "") {
      return false; // Return false if the amount is empty
    }
    const regex = /^\d+(\.\d{0,2})?$/;
    return regex.test(stringValue);
  };
  const handleAmountChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    if (value === "" || /^\d*\.?\d*$/.test(value)) {
      setAmount(value);
    }
  };

  const handleCheckboxChange = (e: ChangeEvent<HTMLInputElement>) => {
    setIsVerified(e.target.checked);
  };

  // Function to safely parse environment variables
  const safeParseFloat = (
    value: string | undefined,
    defaultValue: number
  ): number => {
    if (value === undefined) {
      return defaultValue;
    }
    const parsed = parseFloat(value);
    return isNaN(parsed) ? defaultValue : parsed; // Fallback to default if parsed value is NaN
  };

  // Function to calculate taxes
  const calculateTaxes = (amount: number) => {
    const uplandTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPLAND_TAX,
      0
    );
    const upxplorerTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPXLORER_TAX,
      0
    );

    const calculatedUplandTax = (uplandTaxPercentage / 100) * amount;
    const calculatedUpxplorerTax = (upxplorerTaxPercentage / 100) * amount;

    setUplandTax(calculatedUplandTax);
    setUpxplorerTax(calculatedUpxplorerTax);
  };

  const calculateTaxesAndFinalAmount = (amount: number) => {
    const uplandTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPLAND_TAX,
      0
    );
    const upxplorerTaxPercentage = safeParseFloat(
      process.env.REACT_APP_UPXLORER_TAX,
      0
    );

    const calculatedUplandTax = (uplandTaxPercentage / 100) * amount;
    const calculatedUpxplorerTax = (upxplorerTaxPercentage / 100) * amount;

    setUplandTax(calculatedUplandTax);
    setUpxplorerTax(calculatedUpxplorerTax);

    // const totalTax = calculatedUplandTax + calculatedUpxplorerTax;
    const totalTax = calculatedUplandTax;
    const calculatedFinalAmount = amount - totalTax;

    // Round the final amount to remove unnecessary decimal zeros
    setFinalAmount(Math.round(calculatedFinalAmount * 100) / 100);
  };

  // Update your continueDepositHandler to handle empty inputs
  const continueDepositHandler = () => {
    if (!validateAmount(amount)) {
      setAmountError("Invalid amount");
      return;
    }
    if (parseFloat(amount) <= 0) {
      setAmountError("Invalid amount");
      return;
    }
    setAmountError(""); // Clear any previous error messages
    setShowConfirmDeposit(true);
  };

  const confirmDepositHandler = () => {
    setShowDepositModal(false);

    if (!validateAmount(amount)) {
      setAmountError("Invalid amount");
      return;
    }
    continueDepositApi(
      {
        url: "api/deposit",
        method: "POST",
        payload: JSON.stringify({
          amount: amount,
          email: user?.email_address,
          type: "deposit"
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error?.message, "error");
          console.log("Message", data?.error);
          return;
        }
        if (data?.message) {
          notify(data?.message, "success");
          setContainerId(data?.containerId);
        }
        setShowConfirmDeposit(false);
        setShowVerifyDeposit(true);
      }
    );
  };

  const verifyDepositHandler = () => {
    if (!isVerified) return;
    continueVerifyApi(
      {
        url: "api/verify",
        method: "POST",
        payload: JSON.stringify({
          containerId: containerId,
          email: user?.email_address
        }),
        headers: {
          "Content-Type": "application/json",
          Authorization: authToken
        }
      },
      (data: any) => {
        if (data?.error) {
          notify(data?.error?.message, "error");
          console.log("Message", data?.error);
          return;
        }
        // if (data?.message) {
        //   notify(data?.message, "success");
        //   console.log("Message", data?.message);
        // }
        if (data?.verified) {
          setShowConfirmDeposit(false);
          setShowVerifyDeposit(false);
          setShowSuccessDeposit(true);
          notify(data?.message, "success");
          getUser(authToken);
        } else {
          notify(data?.message, "error");
        }
      }
    );
  };

  const copyToClipboard = (text: string) => {
    navigator.clipboard
      .writeText(text)
      .then(() => {
        // Handle successful copy
        notify("Copied", "success");
        console.log("Text copied to clipboard");
      })
      .catch((err) => {
        // Handle error case
        console.error("Error in copying text: ", err);
      });
  };

  return (
    <>
      <DepositModal
        isOpen={showDepositModal}
        onAction={confirmDepositHandler}
        onCancel={() => setShowDepositModal(false)}
      />
      <TellMeHowDirectDeposit
        isOpen={showTellMeModal}
        onCancel={() => setShowTellMeModal(false)}
      />
      <div className="flex flex-col justify-between h-full">
        <Header />
        <div className="2xl:px-14 xl:px-12 lg:px-10 md:px-0 px-5 h-full">
          <div className="flex flex-col items-center justify-center h-full">
            <MainHeading title="Deposit UPX" />
            {!showConfirmDeposit &&
            !showVerifyDeposit &&
            !showSuccessDeposit ? (
              <div className="px-5 py-10 rounded-[30px] bg-[#1C1C1C] md:w-[750px] w-full flex flex-col items-center mt-10">
                <p className="font-ano font-bold text-2xl text-white text-center mb-6">
                  Direct Deposit
                </p>

                <p className="font-ano font-normal text-lg text-white text-center mb-6">
                  Transfer any UPX amount to the username below
                </p>

                <div className="flex items-center justify-between space-x-20">
                  <p className="font-ano font-normal text-2xl text-[#B4B4B4] uppercase">
                    Username:
                  </p>
                  <div className="flex items-center space-x-2">
                    <p
                      onClick={(e) => {
                        e.stopPropagation();
                        copyToClipboard("Kebig");
                      }}
                      className="capitalize cursor-pointer font-ano font-bold text-white hover:text-[#F9F41F] text-2xl"
                    >
                      Kebig
                    </p>
                    <a
                      href={`https://play.upland.me/profile?username=kebig`}
                      target="_blank"
                      onClick={(e) => {
                        e.stopPropagation();
                      }}
                    >
                      {/* <svg
                        className="cursor-pointer inline-block stroke-[#3E7EFF]"
                        width={15}
                        height={15}
                        viewBox="0 0 15 15"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                        >
                        <path
                        d="M14 5.33333L14 1M14 1H9.66667M14 1L8.22222 6.77778M6.05556 2.44444H4.46667C3.25322 2.44444 2.6465 2.44444 2.18302 2.6806C1.77534 2.88832 1.44388 3.21978 1.23615 3.62747C1 4.09094 1 4.69766 1 5.91111V10.5333C1 11.7468 1 12.3535 1.23615 12.817C1.44388 13.2247 1.77534 13.5561 2.18302 13.7638C2.6465 14 3.25322 14 4.46667 14H9.08889C10.3023 14 10.9091 14 11.3725 13.7638C11.7802 13.5561 12.1117 13.2247 12.3194 12.817C12.5556 12.3535 12.5556 11.7468 12.5556 10.5333V8.94444"
                        stroke-linecap="round"
                        stroke-linejoin="round"
                        />
                      </svg> */}
                      <svg
                        className="cursor-pointer inline-block stroke-white hover:stroke-[#3E7EFF]"
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path
                          d="M15 5.66667L15 1M15 1H10.3333M15 1L8.77778 7.22222M6.44444 2.55556H4.73333C3.42654 2.55556 2.77315 2.55556 2.27402 2.80987C1.83498 3.03358 1.47802 3.39053 1.25432 3.82958C1 4.32871 1 4.9821 1 6.28889V11.2667C1 12.5735 1 13.2269 1.25432 13.726C1.47802 14.165 1.83498 14.522 2.27402 14.7457C2.77315 15 3.42654 15 4.73333 15H9.71111C11.0179 15 11.6713 15 12.1704 14.7457C12.6095 14.522 12.9664 14.165 13.1901 13.726C13.4444 13.2269 13.4444 12.5735 13.4444 11.2667V9.55556"
                          stroke-width="2"
                          stroke-linecap="round"
                          stroke-linejoin="round"
                        />
                      </svg>
                    </a>
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-5">
                  <div
                    onClick={() => {
                      navigate(-1);
                    }}
                    className="w-52 mt-8 cursor-pointer border-2 rounded-[50px] hover:bg-[#676767] border-[#676767] font-ano font-bold text-[22px] text-center py-2 px-6 bg-[#1C1C1C] uppercase text-white outline-none"
                  >
                    Back
                  </div>
                </div>

                <div className="flex items-center justify-center space-x-3 mt-5">
                  <img src={InfoIcon} className="w-4 h-4" />
                  <p
                    onClick={() => setShowTellMeModal(true)}
                    className="font-ano font-bold text-base text-[#979797] text-center cursor-pointer"
                  >
                    Show me how
                  </p>
                </div>
              </div>
            ) : null}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
}
