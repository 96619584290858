import React, { ReactNode } from "react";
import { Navigate } from "react-router-dom";
import { useAuth } from "../context";
import { LoadingSpinner } from "../components/LoadingSpinner";

interface ProtectedRouteProps {
  children: ReactNode;
}

export function AdminProtectedRoute({ children }: ProtectedRouteProps): JSX.Element | null {
  const { user, isLoading } = useAuth();
  if (isLoading) {
    return <LoadingSpinner />;
  }
  
  if(!user?.isAdmin) {
    return <Navigate to="/" />;
  }

  return <>{children}</>;
}
