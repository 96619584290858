import React, { useState, useEffect } from 'react';

type ProgressBarProps = {
  startedAt: string | undefined;
  finishedAt: string | undefined;
};

const ProgressBar: React.FC<ProgressBarProps> = ({ startedAt, finishedAt }) => {
  const [progress, setProgress] = useState(0);

  useEffect(() => {
    if (!startedAt || !finishedAt) {
      // If either date is undefined, set progress to 0
      setProgress(0);
      return;
    }

    const startDate = new Date(startedAt).getTime();
    const endDate = new Date(finishedAt).getTime();
    const now = new Date().getTime();

    if (now > endDate) {
      // If the current date is past the finished date, set progress to 100%
      setProgress(100);
      return;
    }

    const totalDuration = endDate - startDate;
    const elapsed = now - startDate;
    const progressPercentage = Math.min(100, (elapsed / totalDuration) * 100);

    setProgress(progressPercentage);
  }, [startedAt, finishedAt]);
  return (
    <div className="w-full h-2 bg-gray-600 rounded-full overflow-hidden">
      <div
        className="bg-blue-500 h-full"
        style={{ width: `${progress}%` }}
        >

        </div>
    </div>
  );
};

export default ProgressBar;
